import { useState } from "react";
import { useEffect } from "react";
import axios from "../components/utils/Interceptors";
// import axios from "axios";

const useUser = () => {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  useEffect(() => {
    setUserLoading(true);
    const _user = JSON.parse(localStorage.getItem("user"));

    if (_user) {
      const checkToken = async () => {
        await axios
          .get(`${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`, {
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${_user?.token}`,
            },
          })
          .then((response) => {
            if (_user?.vendor_email) {
              setUserLoading(false);
              setUser(_user);
            }
            setUserLoading(false);
          })
          .catch((err) => {
            localStorage.removeItem("user");
            // if (err.response.data.status_code === 403) {
            //   setUserLoading(false);
            //   localStorage.removeItem("user");
            // }
          });
      };
      checkToken();
    } else {
      setUserLoading(false);
    }
  }, []);

  return [user, userLoading, setUser];
};

export default useUser;
