import React, { useMemo, useReducer, useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import { AiFillEdit } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import FilterComponent from "../../../components/utils/FilterComponent";
import getOrderStatus from "../../../components/utils/getOrderStatus";
import getAll from "../../../hooks/getAll";
import updateData from "../../../hooks/updateData";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const ReturnOrders = () => {
  const [loading, setLoading] = useState();
  const [fatch, setFatch] = useState(false);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectId, setSelectID] = useState();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const selectLanguage = i18next.language;
  const {t} = useTranslation()

  const getAllOrders = async (status_id) => {
    let url;
    if (status_id >= 0) {
      url = `vendor/getallreturn?status=${status_id}`;
    } else {
      url = `vendor/getallreturn`;
    }

    const response = await getAll(url);
    if (response.status_code === 200) {
      setData(response.data);
    } else {
      setData([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllOrders();
  }, [fatch]);

  const columns = [
    {
      name: "ID",
      selector: (row) => row.order_id,
      sortable: true,
    },
    {
      name: "number",
      selector: (row) => row.order_number,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.customer_email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.return_status,
      cell: (row) => getOrderStatus(row.order_status,"", {t}),
    },
    {
      name: "Date",
      selector: (row) => moment(row.created_on).format("DD-MMM-YYYY"),
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => `${row.order_price} ر.س`,
      sortable: true,
    },
    {
      name: "Action",
      sortable: true,
      cell: (row) => (
        <div className="flex gap-x-1 justify-center">
          <label
            onClick={() => handleAccepted(row.order_id)}
            htmlFor="my-modal"
            className="badge badge-info cursor-pointer"
            title="Accepted"
          >
            <AiFillEdit className="text-white" />
          </label>
          <button
            onClick={() => handleCancel(row.order_id)}
            className="badge badge-error cursor-pointer"
            title="Delete"
          >
            <TiDelete className="text-white" />
          </button>
        </div>
      ),
    },
  ];

  let filteredItems = data;
  if (filterText) {
    filteredItems = data?.filter(
      (item) =>
        item.cust_fullname.toLowerCase().includes(filterText.toLowerCase()) ||
        item.order_number.toLowerCase().includes(filterText.toLowerCase())
    );
  }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center gap-2 flex-wrap my-5">
        <button
          onClick={() => filterByStatus(-1)}
          className="btn btn-sm rounded"
        >
          {t("all")}
        </button>
        <button
          onClick={() => filterByStatus(4)}
          className="btn btn-sm btn-warning text-white rounded"
        >
          {t("Return req")}
        </button>
        <button
          onClick={() => filterByStatus(5)}
          className="btn btn-sm btn-info text-white rounded"
        >
          {t("Request accepted")}
        </button>
        <button
          onClick={() => filterByStatus(8)}
          className="btn btn-sm btn-success text-white rounded"
        >
          {t("Refund successful")}
        </button>
        <button
          onClick={() => filterByStatus(10)}
          className="btn btn-sm btn-error text-white rounded"
        >
          {t("Request Cancelled")}
        </button>

        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
    // <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
  }, [filterText, resetPaginationToggle, selectLanguage]);

  const filterByStatus = (status_id) => {
    setLoading(true);
    if (status_id >= 0) {
      filteredItems = data?.filter((item) => item.order_status === status_id);
    } else {
      filteredItems = data?.filter(
        (item) =>
          item.order_number?.includes(filterText) ||
          item.customer_email?.includes(filterText)
      );
    }
    getAllOrders(status_id);
    setLoading(false);
  };

  const handleAccepted = async (id) => {
    setModal(true);
    setSelectID(id);
  };

  const hendleSubmit = async (event) => {
    event.preventDefault();
    const status = event.target.status_code.value;

    setLoading(true);
    const data = {
      order_id: selectId,
      order_status: status,
    };

    const res = await updateData("vendor/updateorder", data);
    if (res.status_code === 200) {
      toast.success("Updated Status!");
      setFatch(!fatch);
    } else {
      toast.error(res.message || res.err);
    }
    setModal(false);
    setLoading(false);
  };

  const handleCancel = async (id) => {
    setLoading(true);
    const status = 10;
    const data = {
      order_id: id,
      order_status: status,
    };

    const res = await updateData("vendor/updateorder", data);
    if (res.status_code === 200) {
      toast.success("Updated Status!");
      setFatch(!fatch);
    } else {
      toast.error(res.message || res.err);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner />;
    // return <ApiLoadingSpinner />
  }

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">{t("Returns")}</h2>
      <div>
        <DataTable
          columns={columns}
          data={filteredItems}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          pagination
          noDataComponent={
            <h4 className="py-8 text-gray-400 text-center">
              {t("No records")}
            </h4>
          }
        />
      </div>
      {modal && (
        <div className="z-50">
          <input type="checkbox" id="my-modal" className="modal-toggle" />
          <div className="modal ">
            <div className="modal-box relative">
              <h3 className="font-bold text-lg">Action For Return Request</h3>
              <form onSubmit={hendleSubmit} action="">
                <div className="flex flex-col py-2">
                  <span>Actions</span>
                  <select
                    defaultValue={""}
                    className="input text-black input-bordered rounded-none w-full h-[40px]"
                    name="status_code"
                  >
                    <option selected defaultValue={""} disabled>
                      Select Action
                    </option>
                    <option value={"5"}>Request accepted</option>
                    <option value={"10"}>Canceled Request</option>
                  </select>
                </div>
                <input
                  type="submit"
                  className="btn btn-sm mt-3 btn-primary w-full rounded text-white"
                  value="Update"
                />
              </form>

              <label
                htmlFor="my-modal"
                title="Cancel"
                className="absolute top-2 right-3  flex text-center items-center cursor-pointer"
              >
                <span className="w-5 h-5 bg-red-400 rounded-full text-white">
                  X
                </span>
              </label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReturnOrders;
