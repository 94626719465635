import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { FaArrowCircleLeft } from 'react-icons/fa';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';


const CreatePage = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false)
    const { control, register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("admin"))

    const onSubmit = async data => {
        setLoading(true)
        if (data.page_content === undefined) {
            toast.error("Add page content");
            setLoading(false)
            return;
        }

        const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/createpage`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }

        })
        const response = await res.json();
        // const response = undefined
        setLoading(false)
        if (response?.status_code === 200) {
            toast.success(`${t("page_success")}`);
            navigate("/admin/page/all-pages");
        }
        else {
            toast.error(response?.message || response?.err)
        }

    }

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Helmet>
                <title>{t('Admin')} - {t('Add_Page')}</title>
            </Helmet>

            <div className="flex gap-x-4">
                <h2 className="text-title text-lg md:text-2xl pb-2">{t('Add_Page')}</h2>
                <Link to={"/admin/page/all-pages"}>
                    <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
                        <FaArrowCircleLeft size={15} />
                        {t("add_new_product_back")}
                    </button>
                </Link>
            </div>


            <div>
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                        <div className="md:col-span-2">

                            <div className="border bg-white">

                                <div className="py-5 px-5">

                                    <div className="form-group mb-5">

                                        <div className="grid grid-cols-1 gap-y-2">
                                            <div className="mb-2">
                                                <label className="text-[13px]" htmlFor="pageTitle">{t("Page_Title")} <span className="text-error">*</span></label>
                                            </div>
                                            <div>
                                                <input required className="w-full h-[40px] input input-bordered rounded" placeholder={t("Page_Title")} {...register("page_title")} />
                                                {errors.pageTitle?.type === 'required' && `${t("Page_req")}`}
                                            </div>

                                            <div className="mb-2">
                                                <label className="text-[13px]" htmlFor="description">{t("Page_cont")}</label>
                                            </div>
                                            <div>
                                                <div className="form-control h-60">


                                                    <Controller
                                                        name="page_content"
                                                        control={control}

                                                        render={({ field }) =>
                                                            <ReactQuill

                                                                {...field}
                                                                className="h-48"
                                                                theme="snow"
                                                            />
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-span-1">
                            <div className="border bg-white">
                                <div className="py-5 px-5">
                                    <div className="form-group mb-5">
                                        <div className="grid grid-cols-1 gap-y-2">
                                            <div className="mb-2">
                                                <label className="text-[13px]" htmlFor="pageTitle">{t("Status")}</label>
                                            </div>
                                            <div>
                                                <select defaultValue={1} className="w-full h-[40px] input input-bordered rounded" placeholder="0" {...register("published_status")}>
                                                    <option value={1}>{t("Published")}</option>
                                                    <option value={0}>{t("Draft")}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input className="py-1 px-3 bg-primary text-white rounded cursor-pointer mt-5" type="submit" value={t("Save_Page")} />
                        </div>
                    </div>
                </form>
            </div>

        </>
    );
};

export default CreatePage;