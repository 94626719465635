import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const TechnicalSupport = () => {
  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {};
  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
        Technical Support
      </h2>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
          <div className="form-control w-full max-w-lg">
            <label className="label">
              <p className="label-text text-error">
                You must add at least one means of communication (mobile number,
                WhatsApp number). According to the e-commerce system, to avoid
                stopping the electronic payment service of your store
              </p>
            </label>
            <input
              {...register("support")}
              type="number"
              placeholder="123456789"
              className="input input-bordered w-full max-w-lg rounded-none shadow"
              required
            />
          </div>

          <button className="btn btn-primary max-w-lg rounded-none text-white">
            Save
          </button>
        </form>
      </div>
    </>
  );
};

export default TechnicalSupport;
