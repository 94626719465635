import React, { useContext, useState } from "react";
import { storeInformation } from "../../../contexts/StoreInformationContext";
import { StarIcon } from "@heroicons/react/solid";
import StarRatingComponent from "react-star-rating-component";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import paymentImage from "../../../assets/payment.jpg";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "font-awesome/css/font-awesome.min.css";
import Div from "../../../components/utils/Div";
import logo_demo from "../../../assets/demo_logo.png";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../../contexts/DomainContext";
import axios from "../../../components/utils/CustomerInterceptor";
import i18next from "i18next";

const StoreFooter = () => {
  const storeName = window.location.pathname.split("/")[1];
  const { storeDetails } = useContext(storeInformation);
  const { data } = storeDetails;
  const [reviewForm, setReviewForm] = useState(false);
  const customerInfo = JSON.parse(localStorage.getItem("customer"));
  const [rating, setRating] = useState(0);
  const [tempRating, setTempRating] = useState(0);
  const { t } = useTranslation();
  const selectLanguage = i18next.language;

  const { customdomain } = useContext(domainProvider);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleToReview = async (e) => {
    if (!customerInfo?.token) {
      toast.info("Please Login For write A review");
      return;
    }
    const reviewData = {
      ...e,
      rating,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/createreview`,
      JSON.stringify(reviewData),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo.token}`,
        },
      }
    );

    const response = await res.data;
    if (response.err) {
      toast.error(response.message);
      toast.error(response.err);
      return;
    }
    toast.success(response.message);
    setReviewForm(false);
  };

  const handleReview = () => {
    const cust_id = customerInfo?.data?.cust_id;
    const reviews = storeDetails?.data?.reviews;
    const find = reviews.find((review) => review.customer_id === cust_id);
    if (find) {
      return toast.info("You are already reviewed");
    }
    setReviewForm(true);
  };

  const handleLogout = async () => {
    const res2 = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/logout`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${customerInfo.token}`,
        },
      }
    );

    const response2 = await res2.data;
    if (response2.status_code !== 200) {
      return toast.error(response2.message);
    }
    toast.success(response2.message);
    localStorage.clear();
    window.location.reload();
  };

  return (
    <>
      <footer className="bg-[#F8F8F8] pt-2 border-t">
        <Div>
          <div className="grid grid-cols-1 gap-10 md:grid-cols-4 text-base-content py-12 font-domain ">
            <div className="flex flex-col text-center">
              <div className="flex justify-center">
                {data?.storeDetails?.store_logo ? (
                  <img
                    className="w-[100px]"
                    src={data?.storeDetails?.store_logo}
                    alt="company name"
                  />
                ) : (
                  <img
                    className="w-[100px]"
                    src={logo_demo}
                    alt="company name"
                  />
                )}
              </div>
              <p className="my-5 w-full lg:max-w-[445px] text-[#616060] font-[Domine]">
                {(selectLanguage === "ar" &&
                data?.storeDetails?.store_description_ar)
                  ? data?.storeDetails?.store_description_ar
                  : data?.storeDetails?.store_description
                  ? data?.storeDetails?.store_description
                  : "Vendor Not added Store Description"}
              </p>
              <div className="flex gap-x-6 justify-center">
                {data?.socialMedias.map((item, idx) => (
                  <a
                    key={idx}
                    target="_blank"
                    href={item?.url}
                    className="text-2xl text-gray-800 footIcon transition-all hover:scale-110"
                  >
                    <i className={item?.icon}></i>
                    {/* <FontAwesomeIcon icon="fa-brands fa-facebook" /> */}
                  </a>
                ))}
              </div>
            </div>
            <div className="flex flex-col text-center ">
              <span className="footer-title opacity-80 text-black text-base">
                {t("Shop")}
              </span>
              <li className="link link-hover ai in-pri list-none my-1">
                <Link
                  className=" ai in-pri shopFooterLink transition-all"
                  to={`${
                    customdomain === "aitech.com.sa"
                      ? `/${storeName}/user/profile`
                      : `/user/profile`
                  }`}
                >
                  {t("My Account")}
                </Link>
              </li>
              <li className="link link-hover ai in-pri list-none my-1">
                <Link
                  className=" ai in-pri shopFooterLink transition-all"
                  to={`${
                    customdomain === "aitech.com.sa"
                      ? `/${storeName}/shop`
                      : `/shop`
                  }`}
                >
                  {t("Shop")}
                </Link>
              </li>
              {customerInfo?.token &&
              <li
                onClick={handleLogout}
                className="shopFooterLink transition-all cursor-pointer in-pri tooltip tooltip-bottom list-none"
                data-tip="Log Out"
              >
                <p>{t("Logout")}</p>
              </li> }
            </div>
            <div className="flex flex-col text-center">
              <span className="footer-title opacity-80 text-black text-base">
                {t("Support")}
              </span>
              <li className="link link-hover ai in-pri list-none my-1">
                <Link
                  className=" ai in-pri shopFooterLink transition-all"
                  to={`${
                    customdomain === "aitech.com.sa"
                      ? `/${storeName}/contact_us`
                      : `/contact_us`
                  }`}
                >
                  {t("HNav_contact")}
                </Link>
              </li>
              <li className="link link-hover ai in-pri list-none my-1">
                <Link
                  className=" ai in-pri shopFooterLink transition-all"
                  to={`${
                    customdomain === "aitech.com.sa"
                      ? `/${storeName}/track_order`
                      : `/track_order`
                  }`}
                >
                  {t("Track")}
                </Link>
              </li>
            </div>

            <div className="flex flex-col text-center">
              <span className="footer-title text-center opacity-80 text-black text-base">
                {t("HNav_contact")}
              </span>
              <p className="my-1 w-full lg:max-w-[360px] text-[#616060] font-[Domine]">
              {data?.storeDetails?.complete_address}
              </p>
              <p className="my-1 w-full lg:max-w-[445px] text-[#616060] font-[Domine]">
              {data?.storeDetails?.store_phone}
              </p>
              <p className="my-1 w-full lg:max-w-[445px] text-[#616060] font-[Domine]">
              {data?.storeDetails?.store_email}
              </p>
              {/* {!reviewForm && (
                <button
                  onClick={handleReview}
                  className="btn btn-primary text-white my-5 w-6/12 mx-auto"
                >
                  {t("Add Review")}
                </button>
              )}
              {reviewForm && (
                <div className="border border-t-gray-600 pt-2 relative">
                  <div
                    onClick={() => setReviewForm(false)}
                    className="absolute top-3 right-3 cursor-pointer"
                  >
                    <p className="bg-slate-400 text-red-500 font-bold rounded-full w-6 h-6 flex justify-center items-center">
                      <span>X</span>
                    </p>
                  </div>
                  <form onSubmit={handleSubmit(handleToReview)}>
                    <div className="mb-3">
                      <span>
                        {t("Your Rating")}{" "}
                        <span className="text-red-600">*</span>
                      </span>
                      <div>
                        <StarRatingComponent
                          className="text-2xl"
                          name="stars" 
                          value={
                            tempRating ? tempRating : rating
                          } 
                          starCount={
                            5
                          } 
                          onStarClick={(nextValue, prevValue, name) => {
                            setRating(nextValue);
                          }} 
                          onStarHover={(nextValue, prevValue, name) => {
                            setTempRating(nextValue);
                          }} 
                          onStarHoverOut={(nextValue, prevValue, name) => {
                            setTempRating(nextValue);
                          }} 
                          //value={tempRating ? tempRating : rating}
                          renderStarIcon={
                            <StarIcon />
                          } 
                          starColor={
                            "#FBBF24"
                          } 
                          emptyStarColor={
                            "#989a9e"
                          } 
                          editing={
                            true
                          } 
                        />
                      </div>
                    </div>
                    <span>
                      {t("Your Review")} <span className="text-red-600">*</span>
                    </span>
                    <textarea
                      type="text"
                      name="reviewText"
                      className="input input-bordered focus:outline-none bg-gray-100 focus:bg-sky-200 focus:border-gray-300 rounded-none w-full h-[110px] resize-none py-5"
                      {...register("review", { required: true })}
                    />
                    {errors.review?.type === "required" && (
                      <small className="text-[12px] text-error">
                        {t("r_t_r")}
                      </small>
                    )}

                    <span className="mt-4">
                      {t("Your Review")} {t("in Arabic")}
                    </span>
                    <textarea
                      type="text"
                      name="reviewText"
                      className="input input-bordered focus:outline-none bg-gray-100 focus:bg-sky-200 focus:border-gray-300 rounded-none w-full h-[110px] resize-none py-5"
                      {...register("review_ar")}
                    />
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm mt-5 text-white"
                    >
                      {t("Submit")}
                    </button>
                  </form>
                </div>
              )} */}
            </div>
          </div>
        </Div>
      </footer>
      <div className="bg-[#F8F8F8] border-t">
        <Div>
          <div className="px-5 flex items-center justify-between">
            <p className="text-gray-400 font-normal">
              Copyright © 2024{" "}
              <span className="font-bold capitalize">
                {storeName}
              </span>{" "}
              | Made by AITECH
            </p>
            <div>
              {/* <img src="/images/methods.png" className="h-5" alt="methods" /> */}
              <img src={paymentImage} className="rounded" alt="methods" />
            </div>
          </div>
        </Div>
      </div>
    </>
  );
};
export default StoreFooter;
