import React from 'react';
import safety from '../assets/safety_payments.png';
import delivery from '../assets/fast-delivery.png';
import customers from '../assets/customer-service.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const Secure = () => {
    const { t } = useTranslation()
    const selectLanguage = i18next.language;
    return (
        <div className='container mx-auto max-w-[1280px] pt-8 px-5 md:px-10'>
            <div className='grid grid-cols-1 md:grid-cols-3 gap-5'>
                <div className='flex flex-col rounded-md md:py-0 py-3 bg-white md:bg-transparent md:flex-row items-center'>
                    <div className='md:w-32 w-[90px] md:h-32 md:mb-[-45px]'>
                        <img src={safety} className="w-full" alt="safety" />
                    </div>
                    <div className='md:pl-3 px-10 md:px-0'>
                        <p className={`text-[20px] ${selectLanguage === "ar" ? "md:text-right mr-2" : "md:text-left mr-0"} text-center font-semibold`}>
                        {t('Secure_one')}
                        </p>
                        <p className={`${selectLanguage === "ar" ? "md:text-right mr-2" : "md:text-left mr-0"} text-center`}>
                        {t('Secure_two')}
                        </p>
                    </div>
                </div>


                <div className='flex flex-col rounded-md md:py-0 py-3 bg-white md:bg-transparent md:flex-row items-center'>
                    <div className='md:w-32 w-[90px] md:h-32 md:mb-[-45px]'>
                        <img src={delivery} className="w-full" alt="delivery" />
                    </div>
                    <div className='md:pl-3 px-10 md:px-0'>
                    <p className={`text-[20px] ${selectLanguage === "ar" ? "md:text-right mr-2" : "md:text-left mr-0"} text-center font-semibold`}>{t('Fast_one')}</p>
                    <p className={`${selectLanguage === "ar" ? "md:text-right mr-2" : "md:text-left mr-0"} text-center`}>{t('Fast_two')}</p>
                    </div>
                </div>


                <div className='flex flex-col rounded-md md:py-0 py-3 bg-white md:bg-transparent md:flex-row items-center'>
                    <div className='md:w-32 w-[90px] md:h-32 md:mb-[-45px]'>
                        <img src={customers} className="w-full" alt="customer" />
                    </div>
                    <div className='md:pl-3 px-10 md:px-0'>
                    <p className={`text-[20px] ${selectLanguage === "ar" ? "md:text-right mr-2" : "md:text-left mr-0"} text-center font-semibold`}>{t('Customer_one')}</p>
                    <p className={`${selectLanguage === "ar" ? "md:text-right mr-2" : "md:text-left mr-0"} text-center`}>
                        {t('Customer_two')}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Secure;