import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import updateData from "../../../hooks/updateData";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";

const PaymentMethods = () => {
  const [gateway, setGateway] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const { t } = useTranslation();
  const selectLanguage = i18next.language;

  useEffect(() => {
    const getAllGateways = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallpaymentsetting`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setGateway(response.data);
      }
    };

    getAllGateways();
  }, []);

  const setGatewayInfo = (id, key, e) => {
    const updatedGateway = gateway.map((item) => {
      if (item.setting_id === id) {
        const newItem = { ...item };
        newItem[key] = e;
        return newItem;
      }
      return item;
    });
    setGateway(updatedGateway);
  };

  const updateGateway = async (id, event) => {
    event.preventDefault();

    const data = gateway.find((item) => item.setting_id === id);
    const updData = {
      key_id: data.key_id,
      key_secret: data.key_secret,
      is_enabled: data.is_enabled,
      id: data.setting_id,
    };
    const res = await updateData("vendor/updatepaymentsetting", updData);
    if (res.status_code === 200) {
      toast.success(res.message);
    }
  };
  return (
    <div>
      <div className="flex justify-between">
        <h1 className="text-lg">
          {t("Available")} {t("Payment_Method")}
        </h1>
        <Link
          className="btn btn-sm rounded btn-primary text-white font-thin"
          to={"/vendor/settings/payment-methods/add"}
        >
          {t("Add Gateway")}
        </Link>
      </div>

      <div className="bg-gray-50 p-3">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {gateway.map((gateway) => (
            <div
              key={gateway.setting_id}
              className=" border p-4 shadow rounded bg-white flex flex-col items-center"
            >
              <img
                className="w-28 h-12 object-contain"
                src={gateway.gateway_image}
                alt=""
              />

              <h2 className="text-xl py-2 font-semibold">
                {gateway.gateway_name}
              </h2>

              <div className="self-start w-full">
                <form onSubmit={(e) => updateGateway(gateway.setting_id, e)}>
                  <div className="flex flex-col gap-y-2">
                    <div className="grid grid-cols-4 gap-x-1">
                      <label className="label cursor-pointer">Active :</label>
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          setGatewayInfo(
                            gateway.setting_id,
                            "is_enabled",
                            Number(e.target.checked)
                          )
                        }
                        checked={gateway.is_enabled}
                        className="toggle toggle-primary col-span-3 mt-2"
                      />
                    </div>
                    <div className="grid grid-cols-4 gap-x-1">
                      <label className="col-span-1" htmlFor="key_id">
                        Publishable Key :{" "}
                      </label>
                      <input
                        onChange={(e) =>
                          setGatewayInfo(
                            gateway.setting_id,
                            "key_id",
                            e.target.value
                          )
                        }
                        value={gateway.key_id}
                        type="text"
                        className="input input-bordered input-sm rounded focus:border-none focus:outline-none col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 gap-x-1">
                      <label className="col-span-1" htmlFor="key_secret">
                        Secret Key :{" "}
                      </label>
                      <input
                        onChange={(e) =>
                          setGatewayInfo(
                            gateway.setting_id,
                            "key_secret",
                            e.target.value
                          )
                        }
                        value={gateway.key_secret}
                        type="text"
                        className="input input-bordered input-sm rounded focus:border-none focus:outline-none col-span-3"
                      />
                    </div>
                  </div>
                  <div className="text-right my-2">
                    <input
                      type="submit"
                      className="btn btn-primary btn-sm rounded text-white"
                      value="Save"
                    />
                  </div>
                </form>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
