import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChangeLanguage from "../../locales/ChangeLanguage";
import axios from "../../components/utils/Interceptors";

const Header2 = () => {
  const [ganarel, setGanarel] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`)
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  return (
    <div className="w-full border-b shadow border-gray-200 bg-white sticky z-50">
      <header className="h-[85px] mt-[26px] container mx-auto max-w-[1280px] px-5 md:px-10 drop-shadow-md-top py-1 md:py-2 flex justify-between items-center">
        <div className="brand-logo w-20 md:w-32 p-1 md:p-2">
          <Link to="/">
            <img src={ganarel?.logo} className="cursor-pointer" alt="logo" />
          </Link>
        </div>
        <ChangeLanguage />
      </header>
    </div>
  );
};

export default Header2;
