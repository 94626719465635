import React from 'react';

const Div = ({ children, classes }) => {
    return (
        <div className={`container mx-auto max-w-[1280px] px-5 md:px-10 ${classes}`}>
            {children}
        </div>
    );
};

export default Div;