import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import SectionTitle from "../components/utils/SectionTitle";

const AgrementPage = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  return (
    <div id="agreement" className="mb-10">
      <div className="py-7">
        <SectionTitle page="agrement" />
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">مقدمة :</h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">Introduction :</h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-3">
              نحن، تقنيات الذكاء الاصطناعي لتقنية المعلومات، بالسجل التجاري رقم
              4650228014، نرحب بكم ونود إعلامكم بأن الشروط والأحكام المذكورة
              أدناه تحكم استخدامكم لمنصة تقنيات الذكاء الاصطناعي وتتضمن جميع
              الآثار القانونية المترتبة على استخدامكم لخدماتنا عبر الإنترنت.
              استخدام أي شخص لمنصة تقنيات الذكاء الاصطناعي، سواء كان مستهلكاً أو
              تاجراً أو غير ذلك، يُعتبر بمثابة موافقة صريحة وقبول لجميع بنود
              وشروط هذه الاتفاقية، ويؤكد على أهليته الكاملة بموجب الشرع والنظام
              والقانون لالتزام بما ورد فيها. نوضح لكم أن منصة تقنيات الذكاء
              الاصطناعي قد تظهر في صورة موقع إلكتروني، تطبيق للهواتف المحمولة،
              أو منصة إلكترونية أخرى، وتصبح هذه الاتفاقية سارية وملزمة فور
              موافقتكم عليها وتسجيلكم في المنصة، طبقاً للمادتين الخامسة والعاشرة
              من نظام التعاملات الإلكترونية السعودي.
            </p>
          </>
        ) : (
          <>
            <p className="py-3">
              We, Artificial Intelligence Technologies for Information
              Technology, with commercial registration number 4650228014,
              welcome you and would like to inform you that the terms and
              conditions mentioned below govern your use of the Artificial
              Intelligence Technologies platform and include all legal
              consequences resulting from your use of our online services. The
              use of the Artificial Intelligence Technologies platform by any
              individual, whether a consumer, merchant, or otherwise, shall be
              deemed an explicit consent and acceptance of all the terms and
              conditions of this agreement, and confirms their full eligibility
              under Sharia, regulations, and laws to abide by its provisions. We
              clarify that the Artificial Intelligence Technologies platform may
              appear in the form of a website, mobile application, or other
              electronic platform, and this agreement becomes effective and
              binding upon your acceptance and registration on the platform, in
              accordance with articles five and ten of the Saudi Electronic
              Transactions System.
            </p>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الأولى – المقدمة والتعريفات:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 1 - Introduction and Definitions:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="py-1">
              يُعتبر التمهيد المذكور أعلاه جزءاً أساسياً من هذه الاتفاقية، وفيما
              يلي تعريفات للمصطلحات الرئيسية المستخدمة في الوثيقة:
            </p>
            <ul className="list-[upper-roman] text-justify py-2 pr-4">
              <li className="mb-2">
                <span className="font-bold">منصة تقنيات الذكاء الاصطناعي</span>:
                يشير هذا المصطلح إلى منصة تقنيات الذكاء الاصطناعي المملوكة
                لمؤسسة تقنيات الذكاء الاصطناعي لتقنية المعلومات، ويشمل هذا
                التعريف جميع الأشكال التي تظهر بها المؤسسة عبر الإنترنت، سواء
                كانت تطبيقاً إلكترونياً أو موقعاً إلكترونياً
              </li>
              <li className="mb-2">
                <span className="font-bold"> التاجر</span>: يُطلق هذا المصطلح
                على أي شخص طبيعي أو معنوي مسجّل في منصة تقنيات الذكاء الاصطناعي
                لإنشاء متجره الإلكتروني، ويغطي هذا التعريف جميع جوانب المتجر
                الإلكتروني الخاص به.
              </li>
              <li className="mb-2">
                <span className="font-bold"> المتجر</span>: يُستخدم هذا المصطلح
                للإشارة إلى المتجر الذي يديره التاجر عبر منصة تقنيات الذكاء
                الاصطناعي
              </li>
              <li className="mb-2">
                <span className="font-bold"> المستهلك</span>: يُعرف بأنه الشخص
                الذي يقوم بشراء المنتج أو الخدمة من التاجر عبر المتجر الإلكتروني
                الذي تم إنشاؤه على منصة تقنيات الذكاء الاصطناعي
              </li>
              <li className="mb-2">
                <span className="font-bold"> الاتفاقية</span>: تُشير هذه العبارة
                إلى الشروط والأحكام الكاملة التي تحكم استخدام منصة تقنيات الذكاء
                الاصطناعي، وتنظم العلاقات بين جميع الأطراف المعنية
              </li>
              <li className="mb-2">
                <span className="font-bold"> مزود الخدمة</span>: هذا المصطلح
                يُعنى بالخدمات التي تُقدم عن طريق منصة تقنيات الذكاء الاصطناعي
                من خلال أطراف ثالثة، ويُشير إلى توفير الوصل بين التاجر ومزود
                الخدمة دون أن تملك منصة تقنيات الذكاء الاصطناعي أية سلطة أو
                علاقة تعاقدية مباشرة مع الاتفاقيات المبرمة بين التاجر ومزود
                الخدمة.
              </li>
            </ul>
          </>
        ) : (
          <>
            <p className="py-1">
              The above-mentioned preamble is considered an integral part of
              this agreement. Below are definitions of the key terms used in the
              document:
            </p>
            <ul className="list-[upper-roman] text-justify py-2 pr-4">
              <li className="mb-2">
                <span className="font-bold">
                  Artificial Intelligence Technologies Platform
                </span>
                : This term refers to the Artificial Intelligence Technologies
                platform owned by Artificial Intelligence Technologies for
                Information Technology, and this definition includes all forms
                in which the institution appears online, whether as an
                electronic application or website.
              </li>
              <li className="mb-2">
                <span className="font-bold">Merchant</span>: This term refers to
                any natural or legal person registered on the Artificial
                Intelligence Technologies platform to create their online store,
                and this definition covers all aspects of their online store.
              </li>
              <li className="mb-2">
                <span className="font-bold">Store</span>: This term is used to
                refer to the store managed by the merchant through the
                Artificial Intelligence Technologies platform.
              </li>
              <li className="mb-2">
                <span className="font-bold">Consumer</span>: Defined as the
                person who purchases the product or service from the merchant
                through the online store created on the Artificial Intelligence
                Technologies platform.
              </li>
              <li className="mb-2">
                <span className="font-bold">Agreement</span>: This term refers
                to the complete terms and conditions governing the use of the
                Artificial Intelligence Technologies platform, regulating the
                relationships between all concerned parties.
              </li>
              <li className="mb-2">
                <span className="font-bold">Service Provider</span>: This term
                refers to the services provided through the Artificial
                Intelligence Technologies platform by third parties, indicating
                the provision of a link between the merchant and the service
                provider without the Artificial Intelligence Technologies
                platform having any authority or direct contractual relationship
                with the agreements made between the merchant and the service
                provider.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثانية – أهلية التاجر القانونية:.
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 2 - Legal Capacity of the Merchant:.
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
                يعترف التاجر بأنه يمتلك الأهلية القانونية الكاملة وفقاً للشرع
                والقانون، وأن عمره لا يقل عن ثمانية عشر عاماً، كما هو محدد
                بالأنظمة المعمول بها في المملكة العربية السعودية، وأن لديه
                الخبرة الكافية لإنشاء وإدارة متجره الإلكتروني على منصة تقنيات
                الذكاء الاصطناعي.
              </li>
              <li className="mb-2">
                باستثناء ما ورد في البند الأول، يمكن للتاجر الذي يقل عمره عن
                ثمانية عشر عاماً أن يقوم بأنشطة تجارية بشرط تقديم موافقة وليه
                القانوني الشرعي، مثل صك الولاية وإفادة الموافقة الموقعة من قبل
                وليه.
              </li>
              <li className="mb-2">
                يقر التاجر بأنه يتمتع بأهلية سليمة وأنه ليس مصاباً بأي عارض من
                عوارض الأهلية وبأية حالات تؤثر على قدرته القانونية.
              </li>
              <li className="mb-2">
                في حال قيام التاجر بالتسجيل كمؤسسة أو شركة، أو أي هيكل نظامي
                آخر، فإن هذه المؤسسة أو الشركة أو الشكل النظامي المسجّل عن طريقه
                يجب أن تتوافر فيه الأهلية القانونية والنظامية والشرعية اللازمة
                لممارسة الأعمال التجارية عبر منصة تقنيات الذكاء الاصطناعي.
              </li>
              <li className="mb-2">
                يوافق التاجر بأنه في حال مخالفته لهذه المادة ” أهلية التاجر
                القانونية ” فإنه يتحمّل تبعات هذه المخالفة أمام المستهلكين أو
                المستخدمين أو الغير، حيث أن منصة تقنيات الذكاء الاصطناعي لا
                علاقة لها بتعاملات التاجر مع المستهلك أو الغير من الناحية
                القانونية والنظامية والشرعية، كما أنه يحق لمنصة تقنيات الذكاء
                الاصطناعي في هذه الحالة مساءلة التاجر بموجب أحكام المسئولية
                العقدية، ومطالبته بالتعويض عن الأضرار التي قد تنشأ نتيجة هذه
                المخالفة، ومن قبيل هذه الأضرار ما يصيب منصة تقنيات الذكاء
                الاصطناعي في سمعتها أمام التجار الآخرين أو المستهلكين أو
                المستخدمين أو أمام الغير.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-2 pr-4">
              <li className="mb-2">
                The merchant confirms that they have full legal capacity
                according to Sharia and the law, and that they are at least
                eighteen years old, as specified by the regulations in the
                Kingdom of Saudi Arabia, and that they have sufficient
                experience to create and manage their online store on the
                Artificial Intelligence Technologies platform.
              </li>
              <li className="mb-2">
                Except as provided in clause one, a merchant under the age of
                eighteen may engage in business activities provided they obtain
                consent from their legal guardian, such as guardianship document
                and a signed consent form from their guardian.
              </li>
              <li className="mb-2">
                The merchant acknowledges that they have a sound legal
                eligibility and are not afflicted by any incapacity or
                conditions affecting their legal capacity.
              </li>
              <li className="mb-2">
                If the merchant registers as an institution, company, or any
                other legal entity, this entity must possess the necessary
                legal, regulatory, and Sharia eligibility to conduct business
                through the Artificial Intelligence Technologies platform.
              </li>
              <li className="mb-2">
                The merchant agrees that in case of breach of this clause
                "Merchant's Legal Capacity," they bear the consequences of this
                breach before consumers, users, or others, as the Artificial
                Intelligence Technologies platform has no involvement in the
                legal, regulatory, and Sharia aspects of the merchant's
                transactions with consumers or others. Additionally, the
                Artificial Intelligence Technologies platform reserves the right
                to hold the merchant accountable under contractual liability
                provisions and to claim compensation for damages resulting from
                this breach, including damages to the reputation of the
                Artificial Intelligence Technologies platform among other
                merchants, consumers, users, or third parties.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الثالثة – طبيعة التزامات منصة تقنيات الذكاء الاصطناعي:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 3 - The nature of the obligations of the artificial
              intelligence technology platform:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
                إن مهمة منصة تقنيات الذكاء الاصطناعي هي مجرد تقديم أدوات الدعم
                الالكتروني، من خلال إنشاء المتجر، حيث أن التزام منصة تقنيات
                الذكاء الاصطناعي بموجب هذه الاتفاقية هو فقط انشاء المتجر
                الالكتروني الخاص بالتاجر لدى منصة تقنيات الذكاء الاصطناعي
                الالكترونية، ووضع المتجر أمام المستخدمين.
              </li>
              <li className="mb-2">
                قد تقدم منصة تقنيات الذكاء الاصطناعي للتاجر خدمات إضافية مثل
                الدعم التسويقي، التوجيه، والإرشاد، وتوفير بوابات الدفع
                الإلكتروني عبر الانترنت، وجميع خدمات منصة تقنيات الذكاء
                الاصطناعي تخضع لمواد وبنود وأحكام اتفاقية الاستخدام هذه.
              </li>
              <li className="mb-2">
                لا تلتزم منصة تقنيات الذكاء الاصطناعي بإتمام عمليات بيع المنتجات
                أو الخدمات بين المستهلك والمتجر، حيث أن التزامها ينتهي بتقديم
                خدمات إنشاء المتجر الالكتروني عبر منصة تقنيات الذكاء الاصطناعي،
                ولا يضر ذلك بالخدمات الأخرى التي تستمر منصة تقنيات الذكاء
                الاصطناعي بتقديمها كخدمات التوجيه والإرشاد والدعم الفني والتسويق
                ووسائل الدفع.
              </li>
              <li className="mb-2">
                إن منصة تقنيات الذكاء الاصطناعي قد توفر في بعض الأحيان بعض
                الخدمات المجانية مؤقتة لبعض المتاجر، مثل أن يقوم التاجر بـ :
                تعديل وتغيير مظهر أو شكل أو ألوان المتجر بناء على رغبه التاجر ،
                وتُقدّم هذه الخدمات من قبل إدارة منصة تقنيات الذكاء الاصطناعي
                بشكل مجاني ومحدود وتخضع لقيود محددة ومعيّنة فإن رغب التاجر
                بخدمات إضافية عليه أن يتواصل مع الدعم الفني الخاص بمنصة تقنيات
                الذكاء الاصطناعي للاستفسار عن العروض ورسوم الباقات والخدمات
                الإضافية.
              </li>
              <li className="mb-2">
                إن جميع التعاملات التي تتم بين التاجر والمستهلك لا علاقة لها
                بشخص منصة تقنيات الذكاء الاصطناعي، ومنصة تقنيات الذكاء الاصطناعي
                غير مسئولة عنها، حيث أن هذا التعامل هو علاقة تعاقدية مستقلة تخضع
                للاتفاق الذي يبرم بين التاجر والمستهلك. وبناءً عليه فإذا تخلّف
                المستهلك عن سداد ثمن الخدمة أو المنتج الذي يوفّره التاجر فلا
                علاقة لمنصة تقنيات الذكاء الاصطناعي بهذه المخالفات ولذا فإن
                المنصة لا تتحمل أي مسؤولية قانونية عن هذه المعاملات.
              </li>
              <li className="mb-2">
                إن جميع التعاملات التي تتم بين التاجر ومزودي الخدمات الخارجيين (
                خدمات الطرف الثالث ) الذين توفر منصة تقنيات الذكاء الاصطناعي
                الربط مع خدماتهم أو عرض خدماتهم ليستفيد منها التاجر والمستهلك لا
                علاقة لها بمنصة تقنيات الذكاء الاصطناعي ، حيث أن هذا التعامل هو
                علاقة تعاقدية مستقلة عن منصة تقنيات الذكاء الاصطناعي وخاضعة
                للاتفاق المبرم بين التاجر ومزود الخدمة ، وبناءً عليه فإذا تخلّف
                أو امتنع أو لم يلتزم أحد الاطراف في تنفيذ التزاماته التي جرى
                الاتفاق عليها أو لم ينفذها على الوجه المطلوب فإن منصة تقنيات
                الذكاء الاصطناعي غير مسئولة عن ما ينتج عن هذه التصرفات و
                المخالفات ، إن منصة تقنيات الذكاء الاصطناعي غير مسئولة عن أي
                مخالفات تحدث أو يتم ارتكابها بين التاجر ومزود الخدمة المخالفات
                ولذا فإن المنصة لا تتحمل أي مسؤولية قانونية عن هذه المعاملات.
              </li>
              <li className="mb-2">
                منصة تقنيات الذكاء الاصطناعي تعتبر منصة إلكترونية تقنية على شبكة
                الانترنت تتيح للتاجر الذي يوافق على هذه الاتفاقية تأسيس متجره
                الالكتروني، وممارسة نشاطه عبر المتجر، ومهمتها تنتهي عند هذا
                الحد. فليس هناك أدنى مسئولية على منصة تقنيات الذكاء الاصطناعي
                حول المخالفات التي يقوم بها التاجر في متجره بالمخالفة لأحكام هذه
                الاتفاقية، وليس لمنصة تقنيات الذكاء الاصطناعي أي علاقة بالنسبة
                للتعاملات التي تتم بين التاجر والمستهلك.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
                The mission of the Artificial Intelligence Technologies platform
                is simply to provide electronic support tools by creating the
                store, where the commitment of the Artificial Intelligence
                Technologies platform under this agreement is only to create the
                electronic store for the merchant with the Artificial
                Intelligence Technologies electronic platform and to present the
                store to the users.
              </li>
              <li className="mb-2">
                Artificial Intelligence Technologies platform may offer
                additional services to the merchant such as marketing support,
                guidance, and counseling, and providing online payment gateways,
                and all services of the Artificial Intelligence Technologies
                platform are subject to the terms and conditions of this
                agreement.
              </li>
              <li className="mb-2">
                Artificial Intelligence Technologies platform is not obligated
                to complete the sale transactions of products or services
                between the consumer and the store, as its commitment ends with
                providing the service of creating the electronic store through
                the Artificial Intelligence Technologies platform, and this does
                not affect other services that the Artificial Intelligence
                Technologies platform continues to provide such as guidance,
                counseling, technical support, marketing, and payment methods.
              </li>
              <li className="mb-2">
                Artificial Intelligence Technologies platform may occasionally
                provide some temporary free services to some stores, such as
                allowing the merchant to modify and change the appearance or
                colors of the store according to the merchant's desire, and
                these services are provided by the management of the Artificial
                Intelligence Technologies platform free of charge and with
                restrictions, and if the merchant desires additional services,
                he must contact the technical support of the Artificial
                Intelligence Technologies platform to inquire about offers,
                package fees, and additional services.
              </li>
              <li className="mb-2">
                All transactions between the merchant and the consumer are not
                related to the Artificial Intelligence Technologies platform,
                and the Artificial Intelligence Technologies platform is not
                responsible for them, as this transaction is an independent
                contractual relationship subject to the agreement concluded
                between the merchant and the consumer. Therefore, if the
                consumer fails to pay for the service or product provided by the
                merchant, the Artificial Intelligence Technologies platform is
                not involved in these violations, and thus the platform does not
                bear any legal responsibility for these transactions.
              </li>
              <li className="mb-2">
                All transactions between the merchant and external service
                providers (third-party services) that Artificial Intelligence
                Technologies platform provides linking to their services or
                offering their services for the benefit of the merchant and the
                consumer are not related to the Artificial Intelligence
                Technologies platform. This transaction is an independent
                contractual relationship separate from the Artificial
                Intelligence Technologies platform and is subject to the
                agreement concluded between the merchant and the service
                provider. Therefore, if any party fails, refuses, or fails to
                fulfill its obligations as agreed upon or does not perform them
                in the required manner, the Artificial Intelligence Technologies
                platform is not responsible for the consequences of these
                actions and violations. The Artificial Intelligence Technologies
                platform is not responsible for any violations that occur or are
                committed between the merchant and the service provider, and
                therefore the platform does not bear any legal responsibility
                for these transactions.
              </li>
              <li className="mb-2">
                Artificial Intelligence Technologies platform is considered a
                technological electronic platform on the Internet that allows
                the merchant who agrees to this agreement to establish his
                electronic store and conduct his activity through the store, and
                its mission ends at this limit. There is no slightest
                responsibility on the Artificial Intelligence Technologies
                platform for the violations committed by the merchant in his
                store in violation of the provisions of this agreement, and the
                Artificial Intelligence Technologies platform has no
                relationship regarding the transactions between the merchant and
                the consumer.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
              المادة الرابعة – ضوابط إنشاء المتجر الإلكتروني:{" "}
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 4 - Controls for creating an online store :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li class="mb-2">
                ١. يُسمح لأي شخص يمتلك الأهلية القانونية، كما هو معرّف بموجب
                النظام والشرع، بإنشاء متجره الإلكتروني طبقًا لأحكام وشروط هذه
                الاتفاقية وخصوصًا "المادة الأولى – أهلية التاجر القانونية".
              </li>
              <li class="mb-2">
                ٢. يجب أن يكون المتجر الذي تم تأسيسه وفقاً لاتفاقية استخدام منصة
                تقنيات الذكاء الاصطناعي متوافقًا مع الأنظمة والقوانين في المملكة
                العربية السعودية، ويلتزم التاجر بتوضيح ماهية الأعمال التي يقوم
                بالتجارة الالكترونية عن طريقها، وماهية الخدمات أو المنتجات التي
                يقدّمها أو يبيعها، وتخلي منصة تقنيات الذكاء الاصطناعي مسئوليتها
                عن مخالفة المتجر لأحكام النظام السعودي في المملكة العربية
                السعودية والآداب العامة، و يبقى لمنصة تقنيات الذكاء الاصطناعي
                دائماً الحق في رفض تسجيل أي متجر الكتروني لا يتوافق مع القوانين
                والأنظمة المرعية في المملكة العربية السعودية أو أحكام هذه
                الاتفاقية، وعليه فإن التاجر يقر ب...
              </li>
              <li class="mb-2">
                ٣. يجب أن يضمن التاجر أن متجره، المُنشأ عبر منصة تقنيات الذكاء
                الاصطناعي، لا ينتهك أي من شروط هذه الاتفاقية وأن الأعمال التي
                يديرها عبر المتجر تتوافق تمامًا مع الأنظمة والقوانين السعودية
                المعمول بها في المملكة العربية السعودية.
              </li>
              <li class="mb-2">
                ٤. لا يحق لأي شخص استخدام منصة تقنيات الذكاء الاصطناعي في حال تم
                إلغاء عضويته أو متجره من قِبل منصة تقنيات الذكاء الاصطناعي أو
                بموجب أوامر أو أحكام قضائية.
              </li>
              <li class="mb-2">
                ٥. في حال قيام أي مستخدم بالتسجيل كمؤسسة أو شركة، أو مؤسسة خيرية
                أو جهة اعتبارية، فإن المؤسسة أو الشركة أو الجهة المسجّلة تكون
                ملزمة بكافة القواعد والأحكام المذكورة في اتفاقية الاستخدام
                الخاصة بمنصة تقنيات الذكاء الاصطناعي.
              </li>
              <li class="mb-2">
                ٦. يجب على كافة المتاجر والتُجّار الالتزام بكافة القوانين
                المعمول بها لتنظيم التجارة عبر الانترنت وكذلك الالتزام بنظام
                الجرائم المعلوماتية وأنظمة وزارة التجارة والاستثمار ونظام
                التعاملات الالكترونية ونظام التجارة الالكترونية.
              </li>

              <li class="mb-2">
                يقر التاجر بأنه قبل التسجيل في منصة تقنيات الذكاء الاصطناعي قد
                استوفى جميع المتطلبات والشروط الرسمية اللازمة لممارسة نشاطه
                التجاري وفقًا للجهات الرسمية في المملكة العربية السعودية.
              </li>
              <li class="mb-2">
                إذا كان التاجر الذي يقدم طلبًا للانضمام وتأسيس متجره شخصًا
                طبيعيًا "فردًا"، فيلتزم كذلك بالتحقق من الاشتراطات المطلوبة لدى
                الجهات الرسمية وتوفيرها بحسب طبيعة نشاط الفرد التاجر، حيث أن
                التاجر الفرد يقر بأنه ملتزم بهذه الاشتراطات وملتزم بتوفيرها
                وتجهيزها، كما يلتزم التاجر الفرد بتوفير رقم هويته الوطنية وغير
                ذلك من المعلومات اللازمة والوثائق التي تطلبها منصة تقنيات الذكاء
                الاصطناعي.
              </li>
              <li class="mb-2">
                إذا كان التاجر المتقدم بطلب الانضمام وإنشاء متجر يمثل مؤسسة
                تجارية أو شركة أو مؤسسة خيرية أو جهة اعتبارية فلابُد من تزويد
                منصة تقنيات الذكاء الاصطناعي بكافة المعلومات والوثائق الثبوتية،
                مثل السجل التجاري وأي وثائق أخرى للمتجر تطلبها منصة تقنيات
                الذكاء الاصطناعي للتسجيل ولإثبات الشخصية القانونية الخاصة
                بالمتجر.
              </li>
              <li class="mb-2">
                يجب على المتجر والتاجر الالتزام بمتطلبات التسجيل المحددة في
                "المادة الخامسة – الحسابات والتزامات التسجيل"، بالإضافة إلى
                الالتزام بكافة القواعد والأحكام المنصوص عليها في اتفاقية
                الاستخدام.
              </li>
            </ul>
          </>
        ) : (
          <>
 <ul className="list-[upper-roman] text-justify py-3 pr-4">    <li class="mb-2">Any person who possesses legal eligibility, as defined by law and Sharia, is permitted to establish their online store in accordance with the provisions and conditions of this agreement, especially "Article 1 - Legal Merchant Eligibility."</li>
    <li class="mb-2">The store established according to the agreement of using the Artificial Intelligence Technologies Platform must comply with the systems and laws in the Kingdom of Saudi Arabia. The merchant is obligated to clarify the nature of the business conducted through electronic commerce, as well as the nature of the services or products offered or sold. The Artificial Intelligence Technologies Platform disclaims responsibility for any violation of the store's provisions under the Saudi system in the Kingdom of Saudi Arabia and public morals. The Artificial Intelligence Technologies Platform reserves the right to refuse to register any electronic store that does not comply with the laws and regulations in the Kingdom of Saudi Arabia or the provisions of this agreement. Therefore, the merchant acknowledges, pursuant to the provisions of the agreement, that their store does not violate the general system in the Kingdom of Saudi Arabia or Islamic morals.</li>
    <li class="mb-2">The merchant must ensure that their store, established through the Artificial Intelligence Technologies Platform, does not violate any of the terms of this agreement and that the activities conducted through the store fully comply with the Saudi laws applicable in the Kingdom of Saudi Arabia.</li>
    <li class="mb-2">Any person is not entitled to use the Artificial Intelligence Technologies Platform if their membership or store is canceled by the Artificial Intelligence Technologies Platform or by orders or judgments of the court.</li>
    <li class="mb-2">If any user registers as an establishment, company, charity, or legal entity, the registered establishment, company, or entity is bound by all the rules and provisions mentioned in the User Agreement of the Artificial Intelligence Technologies Platform.</li>
    <li class="mb-2">All stores and merchants must comply with all the laws governing e-commerce and adhere to the Cybercrime Law, the regulations of the Ministry of Commerce and Investment, the Electronic Transactions System, and the E-commerce System.</li>
    <li class="mb-2">The merchant acknowledges that before registering with the Artificial Intelligence Technologies Platform, they have fulfilled all the official requirements necessary to conduct their business activities according to official authorities in the Kingdom of Saudi Arabia.</li>
    <li class="mb-2">If the merchant applying for membership and establishing their store is an individual, they must also comply with the requirements specified by the official authorities and provide them according to the nature of the individual merchant's activity. The individual merchant acknowledges that they are bound by these requirements and undertakes to provide and prepare them, including providing their national ID number and any other necessary information and documents requested by the Artificial Intelligence Technologies Platform.</li>
    <li class="mb-2">If the merchant applying for membership and establishing a store represents a commercial entity, company, charity, or legal entity, it is necessary to provide the Artificial Intelligence Technologies Platform with all the information and documentary evidence, such as the commercial register and any other documents for the store requested by the Artificial Intelligence Technologies Platform for registration and to prove the legal personality of the store.</li>
    <li class="mb-2">The store and the merchant must comply with the registration requirements specified in "Article 5 - Accounts and Registration Obligations," in addition to complying with all the rules and provisions stipulated in the User Agreement.</li>
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الخامسة – الحسابات والتزامات التسجيل:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 5 - Accounts and Registration Obligations:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-2">
عند تقديم طلب الانضمام لعضوية منصة تقنيات الذكاء الاصطناعي أو إنشاء متجر إلكتروني، يجب عليك الكشف عن معلومات معينة واختيار اسم مستخدم وكلمة مرور سرية لاستخدامهما في الوصول إلى خدمات المنصة. بعد تفعيل حسابك، تصبح مستخدمًا رسميًا للمنصة، وبهذا توافق على:
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
        <li class="mb-2">
            ١. تحمل مسؤولية الحفاظ على سرية حسابك وكلمة المرور، والتزامك بإخطار منصة تقنيات الذكاء الاصطناعي فورًا عن أي استخدام غير مصرح به لحسابك أو أي اختراق لمعلوماتك الشخصية.
        </li>
        <li class="mb-2">
            ٢. منصة تقنيات الذكاء الاصطناعي لن تكون مسؤولة بأي شكل من الأشكال عن أي خسائر مباشرة أو غير مباشرة، معنوية أو مادية، قد تنجم عن إفشاء اسم المستخدم أو كلمة المرور، أو سوء استخدام المتجر.
        </li>
        <li class="mb-2">
            ٣. أنت تلتزم باستخدام متجرك الالكتروني بنفسك، حيث أنك مسؤولاً عنه مسؤولية كاملة، وفي حال استخدام غيرك له فهذه يعني أنك قد فوّضته باستخدام المتجر باسمك ولحسابك مالم يتم إبلاغ منصة تقنيات الذكاء الاصطناعي بخلاف ذلك.
        </li>
        <li class="mb-2">
            ٤. أنت ملزم عند استخدام منصة تقنيات الذكاء الاصطناعي أن تستخدمها بكل جدية ومصداقية، وأن تلتزم بقواعد وأحكام اتفاقية الاستخدام وأن تلتزم بالضوابط النظامية والقانونية المعمول بها في المملكة العربية السعودية، وتعتبر ملزماً بتعويض منصة تقنيات الذكاء الاصطناعي عن أي خسائر مباشرة أو غير مباشرة قد تلحق بمنصة تقنيات الذكاء الاصطناعي نتيجة أي استخدام غير شرعي أو غير حقيقي أو غير مفوض لحسابك من طرفك أو من طرف أي شخص آخر حصل على مفاتيح الولوج إلى حسابك بالمنصة سواء كان لإنجاز الخدمات باستعمال اسم المستخدم وكلمة المرور أو نتيجة لإهمالك المحافظة على سرية اسم المستخدم وكلمة المرور ، وسواء بتفويض منك أو بلا تفويض.
        </li>
        <li class="mb-2">
            ٥. أنت ملزم بتقديم معلومات حقيقية وصحيحة ومحدثة وكاملة وقانونية عن نفسك حسبما هو مطلوب أثناء التسجيل لدى منصة تقنيات الذكاء الاصطناعي وتلتزم بتحديث بياناتك في حال تغيرت لأي سبب من الأسباب.
        </li>
        <li class="mb-2">
            ٦. يجب عدم تضمين أية تفاصيل اتصال شخصية مثل عناوين البريد الإلكتروني أو أرقام الهاتف في اسم المستخدم، وتجنب أي تعبيرات تُشير إلى علاقة شخصية أو تجارية بمنصة تقنيات الذكاء الاصطناعي أو أي من منسوبيها أو مالكيها.
        </li>
        <li class="mb-2">
            ٧. يُمنع وضع أية دلالات في المتجر تُشير إلى وجود علاقة مباشرة أو غير مباشرة بين المتجر ومنصة تقنيات الذكاء الاصطناعي أو إدارتها أو مُلّاكها أو منسوبيها، حيث أن منصة تقنيات الذكاء الاصطناعي لا تتحمل أي مسؤولية عن الأنشطة التي تُجرى ضمن متجرك.
        </li>
        <li class="mb-2">
            ٩. سوف تكون ملزمًا بالحفاظ على بيانات التسجيل وتحديثها دائمًا للإبقاء عليها حقيقية وصحيحة وحديثة وكاملة وقانونية، وإذا أفصحت عن معلومات غير حقيقية أو غير صحيحة أو غير راهنة أو غير كاملة أو غير قانونية او مخالفة لما جاء في اتفاقية الاستخدام، فإن منصة تقنيات الذكاء الاصطناعي تمتلك الحق في وقف أو تجميد أو إلغاء عضويتك أو متجرك وحسابك في المنصة، وذلك دون إلحاق الأضرار بحقوق منصة تقنيات الذكاء الاصطناعي الأخرى ووسائلها المشروعة في استرداد حقوقها وحماية باقي المستخدمين.
        </li>
        <li class="mb-2">
            ١٠. تحتفظ منصة تقنيات الذكاء الاصطناعي بالحق في إجراء تحقيقات عند الضرورة، سواء بشكل مباشر أو من خلال طرف ثالث، وقد تطلب منك الكشف عن معلومات إضافية أو تقديم وثائق لإثبات هويتك أو ملكيتك للأموال أو لحسابك.
        </li>
        <li class="mb-2">
            ١١. في حالة عدم الالتزام بأي مما ورد أعلاه فإن لإدارة منصة تقنيات الذكاء الاصطناعي الحق في إيقاف أو إلغاء متجرك أو عضويتك أو حجبك من الوصول لخدمات منصة تقنيات الذكاء الاصطناعي مرة أخرى. وتحتفظ كذلك بالحق في إلغاء أي حسابات غير مؤكدة وغير مثبتة أو عمليات أو حسابات مر عليها مدة طويلة دون نشاط.
        </li>
            </ul>
          </>
        ) : (
          <>
            <p className="my-2">
            When submitting an application to join the Artificial Intelligence Technologies Platform or creating an online store, you must disclose certain information and choose a username and secret password to use to access the platform’s services. After activating your account, you become an official user of the platform, and hereby agree to:
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">Assume responsibility for maintaining the confidentiality of your account and password, and commit to immediately notifying the Artificial Intelligence Technologies platform of any unauthorized use of your account or any breach of your personal information.</li>
    <li class="mb-2">Acknowledge that the Artificial Intelligence Technologies platform shall not be liable in any way for any direct or indirect losses, moral or material, resulting from the disclosure of your username or password, or the misuse of the store.</li>
    <li class="mb-2">Undertake to use your online store yourself, as you are fully responsible for it, and if someone else uses it, it means that you have authorized them to use the store on your behalf unless otherwise informed to the Artificial Intelligence Technologies platform.</li>
    <li class="mb-2">Oblige yourself to use the Artificial Intelligence Technologies platform seriously and credibly, and comply with the rules and terms of the user agreement, as well as comply with the regulatory and legal controls applicable in the Kingdom of Saudi Arabia, and consider yourself obliged to compensate the Artificial Intelligence Technologies platform for any direct or indirect losses that may be incurred by the platform as a result of any unauthorized, fictitious, or unauthorized use of your account by you or by any other person who obtained access keys to your platform account, whether to perform services using the username and password or as a result of your negligence in maintaining the confidentiality of the username and password, whether authorized by you or without authorization.</li>
    <li class="mb-2">Provide true, accurate, up-to-date, complete, and legal information about yourself as required during registration with the Artificial Intelligence Technologies platform and undertake to update your information if it changes for any reason.</li>
    <li class="mb-2">Do not include any personal contact details such as email addresses or phone numbers in the username, and avoid any expressions that indicate a personal or business relationship with the Artificial Intelligence Technologies platform or any of its affiliates, managers, or owners.</li>
    <li class="mb-2">Do not include any indications in the store that imply a direct or indirect relationship between the store and the Artificial Intelligence Technologies platform or its management, owners, or affiliates, as the Artificial Intelligence Technologies platform does not bear any responsibility for the activities carried out within your store.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform undertakes to process your personal information and contact addresses confidentially in accordance with the provisions of the privacy and confidentiality policy adopted by it.</li>
    <li class="mb-2">You shall be obliged to maintain and update your registration data to keep it true, accurate, up-to-date, complete, and legal at all times, and if you disclose untrue, inaccurate, outdated, incomplete, or illegal information or violate the terms of use agreement, the Artificial Intelligence Technologies platform reserves the right to suspend, freeze, or cancel your membership or store and your account on the platform, without prejudicing the other rights of the Artificial Intelligence Technologies platform and its legitimate means to recover its rights and protect other users.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform reserves the right to conduct investigations when necessary, either directly or through a third party, and may require you to disclose additional information or provide documents to prove your identity or ownership of funds or your account.</li>
    <li class="mb-2">In the event of non-compliance with any of the above, the management of the Artificial Intelligence Technologies platform has the right to suspend or cancel your store, membership, or re-block you from accessing the services of the Artificial Intelligence Technologies platform again. The platform also reserves the right to cancel any unconfirmed or unverified accounts, operations, or accounts that have been inactive for a long period without activity.</li>
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
            المادة السادسة – الاتصالات الإلكترونية ووسائل التواصل الرسمية:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 6 - Electronic communications and official means of communication:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
           <ul className="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">يوافق التاجر في منصة تقنيات الذكاء الاصطناعي على أن يتم التواصل معه عبر البريد الإلكتروني، أو من خلال إرسال رسائل عامة من إدارة منصة تقنيات الذكاء الاصطناعي تصل إلى كافة المستخدمين أو إلى مستخدمين محددين عند الولوج إلى حساباتهم داخل منصة تقنيات الذكاء الاصطناعي. ويوافق التاجر على أن جميع الاتفاقيات والإعلانات والبيانات والاتصالات الأخرى التي يتم توفيرها إلكترونياً تعتبر مثيلات لها من الوثائق المكتوبة، وتمثل حجة قائمة بذاتها في تلبية الاحتياجات القانونية.</li>
    <li class="mb-2">خلال فترة عضويتك وتجارتك في منصة تقنيات الذكاء الاصطناعي، ستقوم المنصة بإرسال رسائل إلكترونية ترويجية لإعلامك بأي تغييرات أو إجراءات أو أنشطة دعائية جديدة قد تُضاف إلى المنصة.</li>
</ul>

          </>
        ) : (
          <>
            <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">The merchant on the Artificial Intelligence Technologies platform agrees to be contacted via email or through sending general messages from the management of the Artificial Intelligence Technologies platform reaching all users or specific users when accessing their accounts within the Artificial Intelligence Technologies platform. The merchant also agrees that all agreements, advertisements, data, and other communications provided electronically are deemed equivalent to written documents and constitute standalone evidence in meeting legal requirements.</li>
    <li class="mb-2">During your membership period and your business on the Artificial Intelligence Technologies platform, the platform will send promotional emails to inform you of any changes, procedures, or new advertising activities that may be added to the platform.</li>
</ul>

          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة السابعة – التعديلات على اتفاقية الاستخدام والرسوم:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 7 - Amendments to the User Agreement and Fees:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
           <ul class="list-[upper-roman] text-justify py-3 pr-4">
  <li class="mb-2">يتعين عليك أن تكون على علم وموافقة بأن منصة تقنيات الذكاء الاصطناعي ستقوم بإشعارك بأي تعديل على هذه الاتفاقية، وبناءً على ذلك، ستتغير التزاماتك أو تتقلص حقوقك وفقًا لأي تعديلات تحدث على هذه الاتفاقية.</li>
  <li class="mb-2">أنت توافق على أن منصة تقنيات الذكاء الاصطناعي تملك بمطلق صلاحيتها ودون تحملها المسؤولية القانونية أن تجري أي تعديلات أساسية أو فرعية على هذه الاتفاقية، ويتم إعلام المستخدمين عن هذا التعديل بأي وسيلة تقنية يتم توفيرها، ويجوز أن يكون ذلك عن طريق البريد الإلكتروني أو ببث رسالة عامة لكافة المستخدمين، وتوفّر منصة تقنيات الذكاء الاصطناعي وسائل تقنية لإظهار قبول المتجر لهذا التعديل، ويكون هذا القبول قانوني ونافي للجهالة ومُلزِم للمتجر لأحكام الاتفاقية بما فيها من تعديلات.</li>
  <li class="mb-2">في حال اعتراضك على أي تعديل في اتفاقية الاستخدام، قد يعيق ذلك وصولك إلى المتجر، حيث يتعين الموافقة على هذه الاتفاقية وأي تعديل يحدث عليها للاستفادة من خدمات منصة تقنيات الذكاء الاصطناعي. وبالتالي، في حالة عدم قبول التعديل، يُرجى التوقف عن استخدام خدمات المنصة، إذ يُعتبر مجرد الولوج إلى حسابك في منصة تقنيات الذكاء الاصطناعي أو استخدام خدماتها موافقة على التعديلات وموافقة كاملة ونافية للجهالة.</li>
  <li class="mb-2">يجب على التاجر دفع كافة الرسوم المستحقة بالريال السعودي، بالإضافة إلى أي نفقات أخرى تضافها منصة تقنيات الذكاء الاصطناعي، ويتم السداد باستخدام الوسائل المعتمدة والمتاحة عبر المنصة.</li>
  <li class="mb-2">لا تكون جميع الباقات والعروض المقدمة من منصة تقنيات الذكاء الاصطناعي مجانية للتُجّار أو المتاجر، حيث تُفرض رسوم متفاوتة على بعض هذه الباقات والعروض.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي قد تُفرض رسومًا على التُجّار أو المتاجر، وذلك حسب العروض أو الباقات التي يشتركون فيها أو الخدمات التي يستخدمونها.</li>
  <li class="mb-2">تحتفظ منصة تقنيات الذكاء الاصطناعي بالحق في إضافة أو تعديل أو خفض أو خصم أي رسوم أو نفقات وفقًا لشروط وأحكام اتفاقية الاستخدام، وذلك بغض النظر عن سبب تسجيل المستخدمين.</li>
  </ul>
          </>
        ) : (
          <>
           <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">It is your responsibility to maintain the confidentiality of your account and password, and you are obligated to notify the Artificial Intelligence Technologies platform immediately of any unauthorized use of your account or any breach of your personal information.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform shall not be liable in any way for any direct or indirect losses, moral or material, that may result from disclosing your username or password or misuse of the store.</li>
    <li class="mb-2">You are responsible for using your online store yourself, as you are fully responsible for it. In case someone else uses it, this means that you have authorized them to use the store on your behalf unless the Artificial Intelligence Technologies platform has been informed otherwise.</li>
    <li class="mb-2">When using the Artificial Intelligence Technologies platform, you must use it seriously and credibly, comply with the rules and provisions of the terms of use agreement, comply with the regulatory and legal controls applicable in Saudi Arabia, and undertake to compensate the Artificial Intelligence Technologies platform for any direct or indirect losses that may be incurred by the Artificial Intelligence Technologies platform as a result of any unauthorized, unreal, or unauthorized use of your account by you or by any other person who has obtained access keys to your account on the platform, whether to perform services using your username and password or as a result of your negligence in maintaining the confidentiality of your username and password, and whether by authorization or without authorization.</li>
    <li class="mb-2">You are obligated to provide true, accurate, up-to-date, complete, and legal information about yourself as required during registration with the Artificial Intelligence Technologies platform and to update your data if it changes for any reason.</li>
    <li class="mb-2">You must not include any personal contact details such as email addresses or phone numbers in the username, and avoid any expressions indicating a personal or business relationship with the Artificial Intelligence Technologies platform or any of its affiliates, employees, or owners.</li>
    <li class="mb-2">It is prohibited to include any indications in the store that suggest a direct or indirect relationship between the store and the Artificial Intelligence Technologies platform or its management, owners, or employees, as the Artificial Intelligence Technologies platform does not assume any responsibility for the activities carried out within your store.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform undertakes to process your personal information and contact addresses with confidentiality in accordance with the adopted privacy and confidentiality policy.</li>
    <li class="mb-2">You will be obliged to maintain and update your registration data at all times to keep it true, accurate, up-to-date, complete, and legal. If you disclose untrue, inaccurate, outdated, incomplete, or illegal information or violate the terms of use agreement, the Artificial Intelligence Technologies platform reserves the right to suspend, freeze, or cancel your membership or store and your account on the platform, without prejudice to the rights of the Artificial Intelligence Technologies platform and its legitimate means of recovering its rights and protecting other users.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform reserves the right to conduct investigations when necessary, either directly or through a third party, and may request additional information from you or provide documents to prove your identity or ownership of funds or your account.</li>
    <li class="mb-2">In case of non-compliance with any of the above, the management of the Artificial Intelligence Technologies platform has the right to suspend or cancel your store or membership or block you from accessing the services of the Artificial Intelligence Technologies platform again. Also, it reserves the right to cancel any unconfirmed or unverified accounts or operations or accounts that have been inactive for a long time without activity.</li>
    
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
            المادة الثامنة - خدمات الدفع والتسديد للمتاجر عبر منصة تقنيات الذكاء الاصطناعي:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 8 - Payment and payment services for stores via the artificial intelligence technology platform:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul class="list-[upper-roman] text-justify py-3 pr-4">
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي تقدم، بالتعاون مع شركائها، نظام دفع وتسديد يمكن إجراؤه كليًا عبر الإنترنت من خلال خيارات الدفع المتاحة على المنصة أو عبر أي وسيلة دفع توفرها المنصة من وقت لآخر.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي لا تتدخل في طريقة الدفع عند الاستلام، حيث تعتبر هذه الطريقة تحت سيطرة العلاقة بين المستهلك والتاجر ومزود الخدمة.</li>
  <li class="mb-2">يجوز أن تشترط منصة تقنيات الذكاء الاصطناعي إجراء الدفع مباشرة بين التاجر والمستهلك عبر حساباتهم البنكية الخاصة في أي وقت وتحت أي ظروف، وليس هناك أي علاقة حينئذٍ لمنصة تقنيات الذكاء الاصطناعي.</li>
  <li class="mb-2">تهدف المنصة إلى تسهيل وضمان حقوق التجار والمتاجر من خلال توفير خدمة الدفع عبر الإنترنت.</li>
  <li class="mb-2">يلتزم التاجر بتحديد تحديد أسعار الخدمات أو السلع في متجره وفقًا للقيمة السوقية المتعارف عليها، ولا علاقة لمنصة تقنيات الذكاء الاصطناعي بأي حال من الأحوال بسوء تقدير تكلفة المنتجات أو الخدمات المعروضة في المتاجر على منصة تقنيات الذكاء الاصطناعي، حيث أن تقديرها بالشكل المتعارف عليه تجارياً هو التزاماً واقع على عاتق التاجر.</li>
  <li class="mb-2">ييلتزم التاجر بتوفير فواتير وسندات قبض وسندات استلام لكل المبالغ والأرباح التي تنشأ في متجره، ويلتزم أن يبيّن في جميع هذه الفواتير نوع البضاعة أو الخدمة وكمياتها وأوصافها وقيمتها، وعليه فإن التاجر يلتزم بتوفير المواصفات المحاسبية المطلوبة في متجره الالكتروني، تطبيقاً لأحكام هذه الاتفاقية، ولما في هذا التنظيم من مصالح قانونية واقتصادية وتجارية للتجار، وفي حالة مخالفة التاجر لأحكام هذا البند فإنه يُعَد مسئولاً عن أي أضرار قد تنشأ نتيجة هذه المخالفة.</li>
  <li class="mb-2">قيمة المنتج السوقية بما فيه خسارة لمنصة تقنيات الذكاء الاصطناعي ولا تتحمل منصة تقنيات الذكاء الاصطناعي مسئولية تلك المبالغ.</li>
  <li class="mb-2">لإدارة المنصة الحق في إلغاء أو تعديل أو تغيير أي وسائل دفع متاحة على منصة تقنيات الذكاء الاصطناعي.</li>
</ul>
          </>
        ) : (
          <>
            <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">Artificial Intelligence Technologies platform, in collaboration with its partners, offers an online payment and settlement system that can be entirely conducted through the available payment options on the platform or through any payment method provided by the platform from time to time.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform does not intervene in the cash on delivery payment method, as this method is considered under the control of the relationship between the consumer, the merchant, and the service provider.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform may stipulate direct payment between the merchant and the consumer through their respective bank accounts at any time and under any circumstances, and there is no relationship for the Artificial Intelligence Technologies platform at that point.</li>
    <li class="mb-2">The platform aims to facilitate and ensure the rights of merchants and stores by providing online payment services.</li>
    <li class="mb-2">The merchant is obliged to determine the prices of services or goods in his store according to the market value commonly recognized, and the Artificial Intelligence Technologies platform has no relation whatsoever to any underestimation of the cost of products or services offered in the stores on the Artificial Intelligence Technologies platform, as their estimation in the customary commercial manner is a commitment and obligation for the merchant.</li>
    <li class="mb-2">The merchant is committed to providing invoices, receipts, and payment receipts for all amounts and profits arising in his store, and he undertakes to specify in all these invoices the type of goods or service, their quantities, descriptions, and values. Therefore, the merchant is committed to providing the accounting specifications required in his online store, in accordance with the provisions of this agreement, and for the legal, economic, and commercial interests of the merchants contained in this regulation. In the event of a merchant's violation of the provisions of this clause, he shall be held responsible for any damages that may arise as a result of this violation.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform reserves the right to prevent the completion of any payment transaction that violates the rules and provisions of the terms of use agreement or to cancel any purchase or sale order as a result of a technical or technological error in the platform leading to differences in the prices displayed on the platform from the market value of the product, causing loss to the Artificial Intelligence Technologies platform. The Artificial Intelligence Technologies platform does not assume any responsibility for those amounts.</li>
    <li class="mb-2">The platform management has the right to cancel, modify, or change any available payment methods on the Artificial Intelligence Technologies platform.</li>
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
            المادة التاسعة - معلوماتك الشخصية وتفاصيل العمليات:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 9 - Your personal information and transaction details:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
  <li class="mb-2">لا مانع لديك بأن تمنح منصة تقنيات الذكاء الاصطناعي الحق غير المحدود والعالمي والدائم وغير القابل للإلغاء، والمعفي من المصاريف، في استخدام المعلومات أو المواد الشخصية التي قدمتها أو زودت بها المنصة أو الإعلان عنها عبر المنصة، سواء عبر التسجيل أو التواصل أو النماذج الخاصة، أو من خلال الرسائل الإلكترونية أو أية قنوات اتصال أخرى متاحة، بهدف تحقيق المصالح التي تراها المنصة مناسبة.</li>
  <li class="mb-2">أنت المسؤول الوحيد عن المعلومات التي ترسلها أو تنشرها، وتقتصر مسؤولية منصة تقنيات الذكاء الاصطناعي على السماح بعرض هذه المعلومات عبر المنصة وقنواتها الإعلانية.</li>
  <li class="mb-2">تبقى سرية المعلومات الخاصة بالمتاجر والتجار خاضعة لسياسة الخصوصية وسرية المعلومات التي تنظمها منصة تقنيات الذكاء الاصطناعي.</li>
            </ul>
          </>
        ) : (
          <>
           <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">You have no objection to granting Artificial Intelligence Technologies platform unlimited, global, permanent, irrevocable, and expense-free rights to use the information or personal materials you have provided or supplied to the platform or advertised through the platform, whether through registration, communication, special forms, or through email messages or any other available communication channels, for the purpose of achieving the interests deemed appropriate by the platform.</li>
    <li class="mb-2">You are solely responsible for the information you send or publish, and the responsibility of the Artificial Intelligence Technologies platform is limited to allowing the display of this information through the platform and its advertising channels.</li>
    <li class="mb-2">The confidentiality of information pertaining to stores and merchants remains subject to the privacy policy and information confidentiality regulations established by the Artificial Intelligence Technologies platform.</li>
</ul>

          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة العاشرة – التزام التاجر بالقوانين والأنظمة في المملكة العربية السعودية
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 10 - The merchant’s compliance with the laws and regulations in the Kingdom of Saudi Arabia
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">التاجر ملزم بالتقيد بجميع القوانين والأنظمة السارية في المملكة العربية السعودية المتعلقة بمنتجاته واستخدامه لمنصة تقنيات الذكاء الاصطناعي، بما في ذلك القوانين والشروط والأحكام المنظمة لاستخدام الإنترنت، إضافة إلى اتفاقية الاستخدام وسياسة الخصوصية وسرية المعلومات المتبعة في المنصة.</li>
  <li class="mb-2">في حال خرق التاجر لأي من بنود المادة العاشرة، يعترف بحق منصة تقنيات الذكاء الاصطناعي في اتخاذ إجراءات متنوعة مثل التنبيه، وقف الخدمة، إغلاق المتجر، أو إعادة أي مبالغ معلقة في المدفوعات الإلكترونية إلى المستهلكين.</li>
</ul>
          </>
        ) : (
          <>
           <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">The merchant is obligated to comply with all laws and regulations applicable in the Kingdom of Saudi Arabia regarding his products and his use of the Artificial Intelligence Technologies platform, including the laws, terms, and conditions governing internet use, as well as the terms of use, privacy policy, and information confidentiality policy followed on the platform.</li>
    <li class="mb-2">In the event of the merchant's violation of any of the provisions of Article 10, he acknowledges the right of the Artificial Intelligence Technologies platform to take various actions such as warning, suspension of service, closure of the store, or refunding any amounts pending in electronic payments to consumers.</li>
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
            المادة الحادية عشرة – الحقوق:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">Article 11 - Rights :</h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">جميع المحتويات الموجودة على منصة تقنيات الذكاء الاصطناعي، سواء كانت إلكترونية أو غير ذلك، مثل النصوص، التصاميم الجرافيكية، الأفكار التقنية، الشعارات، العروض، أيقونات الأزرار، الرموز، المقاطع الصوتية، البيانات المجمعة والبرامج الإلكترونية، هي ملك للمنصة وحقوقها محفوظة لها، ولا يُسمح لأي شخص باستخدامها بشكل مباشر أو غير مباشر أو عبر طرف ثالث دون إذن.</li>
  <li class="mb-2">تؤكد إدارة منصة تقنيات الذكاء الاصطناعي أنها ستتخذ الإجراءات اللازمة ضد أي تجاوز أو انتهاك لحقوق المنصة أو ملكيتها الفكرية.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي لا تتحمل المسؤولية عن أي انتهاك لحقوق الملكية الفكرية التي يمتلكها التجار أو المتاجر المسجلة في المنصة.</li>
</ul>
          </>
        ) : (
          <>
            <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">All content on the Artificial Intelligence Technologies platform, whether electronic or otherwise, such as texts, graphic designs, technical ideas, logos, offers, button icons, symbols, audio clips, aggregated data, and software programs, is owned by the platform and its rights are reserved, and no one is allowed to use it directly or indirectly or through a third party without permission.</li>
    <li class="mb-2">The management of the Artificial Intelligence Technologies platform confirms that it will take necessary actions against any infringement or violation of the platform's rights or intellectual property.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform is not responsible for any infringement of intellectual property rights owned by merchants or stores registered on the platform.</li>
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الثانية عشرة – الملكية الفكرية:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 12 - Intellectual Property :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">إدارة منصة تقنيات الذكاء الاصطناعي تقدر وتحترم حقوق الملكية الفكرية للتجار التي تم تكوينها من خلال متاجرهم، سواء كانت هذه الحقوق قائمة قبل أو بعد تأسيس المتجر.</li>
  <li class="mb-2">التاجر ملزم باحترام حقوق الملكية الفكرية الخاصة بمنصة تقنيات الذكاء الاصطناعي، بما في ذلك الكلمات، الشعارات، والرموز المستخدمة أو المعروضة على المنصة. هذه الحقوق محمية بموجب قوانين الملكية الفكرية والعلامات التجارية وتعتبر ملكية حصرية للمنصة، ولا يجوز استخدامها دون تفويض من إدارة المنصة.</li>
</ul>
          </>
        ) : (
          <>
         <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">The management of the Artificial Intelligence Technologies platform values and respects the intellectual property rights of merchants formed through their stores, whether these rights existed before or after the establishment of the store.</li>
    <li class="mb-2">Merchants are obligated to respect the intellectual property rights of the Artificial Intelligence Technologies platform, including the words, logos, and symbols used or displayed on the platform. These rights are protected under intellectual property and trademark laws and are the exclusive property of the platform, and they may not be used without authorization from the platform management.</li>
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">              
المادة الثالثة عشرة – المستهلك:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">Article 13 - Consumer</h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
	خلال عمليات الشراء، يزود المستهلك منصة تقنيات الذكاء الاصطناعي بمعلومات مثل الاسم، البريد الإلكتروني، ورقم الهاتف.

              </li>
              <li className="mb-2">
              تحتفظ منصة تقنيات الذكاء الاصطناعي ببيانات المستهلكين لتسهيل الوصول إلى المنصة وإتمام عمليات الشراء.
              </li>

              <li className="mb-2">
               من منطلق التزام منصة تقنيات الذكاء الاصطناعي بحماية حقوق المستهلكين، ورغم أن المستهلك لا يعتبر طرفًا في هذه الاتفاقية، تسعى المنصة دائمًا لتقديم خدمة عالية الجودة لجميع المستخدمين. لضمان أن يتلقى المستهلكين جميع مشترياتهم بالحالة المناسبة، تقوم منصة تقنيات الذكاء الاصطناعي توضّح للمتجر التالي:
               <ul class="list-disc pl-6">
                <li>	في حال قام المستهلك بشراء بضاعة بأحد طرق الدفع المتوفرة على منصة تقنيات الذكاء الاصطناعي، ولم يتسلم بضاعته أو أنه تسلم بضاعة تختلف عما عرض من مواصفات ذكرت بصفحة المتجر، حينها يكون من حق المستهلك إبلاغ منصة تقنيات الذكاء الاصطناعي ولمنصة تقنيات الذكاء الاصطناعي الحق في اتخاذ ما تراه مناسب حيال المتجر الذي خالف قواعد هذه الاتفاقية، وفقاً لقواعد هذه الاتفاقية. غير أن منصة تقنيات الذكاء الاصطناعي غير مسئولة قانوناً أو شرعاً عن هذا الإخلال الحاصل من قبل التاجر في مواجهة المستهلك، حيث أنها ليست طرفاً في العلاقة بين المستهلك والتاجر، ولكنها تقوم بذلك لدواعي تحسين جودة منصة تقنيات الذكاء الاصطناعي والتجار المتواجدين في المنصة.</li>
                <li>	للمستهلك مراجعة مشترياته قبل الاستلام للتأكد من أنها تطابق المواصفات المطلوبة وأنها سليمة ومطابقة لما تم شرائه، حيث أن منصة تقنيات الذكاء الاصطناعي غير مسئولة عن العلاقة التي بين المستهلك والتاجر.</li>
                <li>	إن منصة تقنيات الذكاء الاصطناعي لا تلتزم بأي حال من الأحوال وفق هذه المادة وهذا البند باتخاذ ردّة فعل تجاه شكوى المستهلك وإنما تخضع ردّة فعل المنصة تجاه شكوى المستهلك إلى محض رغبة منصة تقنيات الذكاء الاصطناعي ووفق ما تراه المنصة مناسبًا، وفقاً لقواعد هذه الاتفاقية. وبما يحافظ على مصلحتها وسمعتها التجارية والاقتصادية، وعلى كل حال فإن منصة تقنيات الذكاء الاصطناعي قدّ تتخذ بعض الإجراءات تجاه المتاجر التي تتعمّد خداع أو تظليل أو غش المستهلك، مثل: تجميد عضوية المتجر أو لفت نظر المتجر إلى عدم تكرار هذا الأمر أو إلزام المتجر بتعويض المستهلك، وذلك انطلاقًا من رغبة منصة تقنيات الذكاء الاصطناعي في حفظ وحماية حقوق كافة المستخدمين والمسجّلين لديها.</li>
               </ul>
              </li>
            </ul>
          </>
        ) : (
          <>
           <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">During the purchasing process, the consumer provides the Artificial Intelligence Technologies platform with information such as name, email, and phone number.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform retains consumer data to facilitate access to the platform and complete purchase transactions.</li>
    <li class="mb-2">In commitment to protecting consumer rights, although the consumer is not a party to this agreement, the platform always strives to provide high-quality service to all users. To ensure that consumers receive all their purchases in proper condition, the Artificial Intelligence Technologies platform clarifies the following to the merchant:
        <ul class="list-disc pl-6">
            <li>If a consumer purchases a product using one of the payment methods available on the Artificial Intelligence Technologies platform and does not receive the product, or if the received product differs from the specifications listed on the store page, the consumer has the right to notify the Artificial Intelligence Technologies platform. The platform then reserves the right to take appropriate action against the merchant who violated the rules of this agreement, in accordance with these rules. However, the Artificial Intelligence Technologies platform is not legally or religiously responsible for this breach by the merchant in the face of the consumer, as it is not a party to the relationship between the consumer and the merchant. Nevertheless, it does so to improve the quality of the Artificial Intelligence Technologies platform and the merchants present on the platform.</li>
            <li>Consumers should review their purchases before receiving them to ensure they match the required specifications, are in good condition, and correspond to what was purchased. The Artificial Intelligence Technologies platform is not responsible for the relationship between the consumer and the merchant.</li>
            <li>The Artificial Intelligence Technologies platform does not commit under any circumstances, according to this article and this clause, to react to a consumer complaint. The platform's response to a consumer complaint is solely at the discretion of the Artificial Intelligence Technologies platform and according to what the platform deems appropriate, following the rules of this agreement. This preserves its commercial and economic interests and reputation. However, the Artificial Intelligence Technologies platform may take some actions against merchants who intentionally deceive, mislead, or defraud consumers, such as freezing the store's membership, alerting the store not to repeat this act, or obligating the store to compensate the consumer. This is done based on the Artificial Intelligence Technologies platform's desire to preserve and protect the rights of all its users and registrants.</li>
        </ul>
    </li>
</ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الرابعة عشرة – مسؤولية منصة تقنيات الذكاء الاصطناعي:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
              Article 14 - Responsibility of the Artificial Intelligence
              Technologies Platform :
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">منصة تقنيات الذكاء الاصطناعي لا تتحمل مسؤولية أي تأخير أو أداء غير مرضٍ من جانب المتاجر، شركات الشحن، بوابات الدفع الإلكتروني، البنوك، أو عدم التزام المستهلكين بالسداد، ولا تتحمل أي خسائر، أعطال، أو تأخير ناتج عن بضاعة غير متوفرة ، تأخر التوصيل، أو سوء جودة الخدمة من المتاجر.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي ليست مسؤولة عن أي مطالبات ناتجة عن أخطاء أو إهمال سواء كانت ناتجة مباشرة أو غير مباشرة أو عرضية أو من طرف ثالث.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي لا تتحمل أي مطالبات أو مسؤوليات ناجمة عن خسائر مالية، تشويه سمعة، قذف، أو أضرار أخرى ناتجة عن سوء استخدام، إساءة استخدام، أو عدم القدرة على استخدام منصة تقنيات الذكاء الاصطناعي.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي ومسؤوليها، منسوبيها، أو ملاكها ليسوا مسؤولين عن أي مطالبة، نزاع، تكاليف، أضرار، أو خسائر مباشرة أو غير مباشرة نشأت عن أفعال المستخدمين للمنصة.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي ومنسوبيها، ملاكها، وممثليها ليس لهم أي علاقة بمسؤولية تتعلق بمنتجات صالحة وشرعية مصرح بها وفق قوانين وأنظمة المملكة العربية السعودية إذا تم استخدامها لأغراض غير شرعية أو نظامية، ولا يمكن المطالبة بأي دعوى أو تعويض في حال سوء الاستخدام هذا.</li>
  <li class="mb-2">منصة تقنيات الذكاء الاصطناعي ومنسوبيها وملاكها وممثليها ليس لهم أي علاقة بأنشطة غير شرعية أو غير نظامية يقوم بها المتجر أو أي نشاط يخالف التعليمات والأنظمة المعمول بها في المملكة العربية السعودية، حيث تقتصر مسؤولية المنصة فقط على توفير وتقديم الدعم الإلكتروني اللازم لتأسيس المتاجر الإلكترونية وخدمات الدعم الإلكتروني الأخرى.</li>
  <li class="mb-2"> تحذر إدارة منصة تقنيات الذكاء الاصطناعي جميع التجار من أنها، في حال اكتشاف أنشطة مشبوهة قيد التخطيط أو التنفيذ أو التي تم تنفيذها على المنصة، ستقوم بإبلاغ السلطات المختصة عن هذه الأنشطة. المنصة ليست مسؤولة عن هذه المخالفات التي تحدث دون علم أو ملاحظة منصة تقنيات الذكاء الاصطناعي.</li>
  </ul>
          </>
        ) : (
          <>
            <ul class="list-[upper-roman] text-justify py-3 pr-4">
    <li class="mb-2">The Artificial Intelligence Technologies platform is not responsible for any delays, non-medical performance by stores, shipping companies, electronic payment gateways, banks, or consumer non-payment compliance, and does not bear any losses, malfunctions, or delays resulting from unavailable goods, delivery delays, or poor service quality from stores.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform is not responsible for any claims resulting from errors or negligence, whether directly, indirectly, incidentally, or by a third party.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform does not bear any claims or liabilities arising from financial losses, reputation defamation, slander, or other damages resulting from misuse, abuse, or inability to use the platform.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform, its affiliates, owners, or representatives are not responsible for any claims, disputes, costs, damages, or direct or indirect losses arising from users' actions on the platform.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform, its affiliates, owners, and representatives have no responsibility for products that are legitimate and lawful as per the laws and regulations of the Kingdom of Saudi Arabia if they are used for unlawful or non-legal purposes, and no claim or compensation can be sought in case of such misuse.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform, its affiliates, owners, and representatives have no relation to illegal or non-legal activities carried out by the store or any activity that violates the instructions and regulations applicable in the Kingdom of Saudi Arabia. The platform's responsibility is limited only to providing the necessary electronic support for establishing electronic stores and other electronic support services.</li>
    <li class="mb-2">The management of the Artificial Intelligence Technologies platform warns all merchants that if it discovers any suspicious activities being planned, executed, or already executed on the platform, it will report these activities to the relevant authorities. The platform is not responsible for these violations that occur without the knowledge or observation of the Artificial Intelligence Technologies platform.</li>
</ul>

          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الخامسة عشرة – سرية المعلومات:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 15 - Confidentiality of Information:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">تلفت منصة تقنيات الذكاء الاصطناعي انتباهكم إلى أن الشبكة العنكبوتية (الإنترنت) ليست بيئة مضمونة الأمان تمامًا، ولا يمكن ضمان سرية المعلومات الشخصية بنسبة 100% على الإنترنت.</li>
<li class="mb-2">تعمل منصة تقنيات الذكاء الاصطناعي بمعايير عالية الجودة، سواء كانت ملموسة، تنظيمية أو تقنية، لحماية المستخدمين والمستهلكين وضمان عدم وصول الأشخاص غير المفوضين إلى المعلومات الشخصية للمستخدمين أو بيانات متاجرهم.</li>
<li class="mb-2">منصة تقنيات الذكاء الاصطناعي لا تمتلك السيطرة على تصرفات الأطراف الثالثة أو الأفراد الآخرين، مثل المواقع الإلكترونية الأخرى التي قد تكون مرتبطة بالمنصة أو أشخاص يدعون تمثيلك أو تمثيل الآخرين.</li>
<li class="mb-2">أنت مُدرك وموافق على أن منصة تقنيات الذكاء الاصطناعي يمكن أن تستخدم المعلومات التي زودتها بها لغرض تقديم الخدمات على المنصة، ولإرسال رسائل تسويقية إليك، وأن سياسة الخصوصية لمنصة تقنيات الذكاء الاصطناعي تحدد كيفية جمع، معالجة، استخدام ونقل معلوماتك الشخصية، وتخضع سرية المعلومات لـ "سياسة الخصوصية وسرية المعلومات" الخاصة بمنصة تقنيات الذكاء الاصطناعي.</li>
            </ul>
          </>
        ) : (
          <>
            <ul class="list-[upper-roman] text-justify py-3 pl-4">
    <li class="mb-2">The Artificial Intelligence Technologies platform would like to draw your attention to the fact that the Internet is not a completely secure environment, and the confidentiality of personal information cannot be guaranteed 100% online.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform operates with high-quality standards, whether tangible, organizational, or technical, to protect users and consumers and ensure that unauthorized persons do not access users' personal information or their store data.</li>
    <li class="mb-2">The Artificial Intelligence Technologies platform does not control the actions of third parties or other individuals, such as other websites that may be linked to the platform or individuals claiming to represent you or others.</li>
    <li class="mb-2">You are aware of and agree that the Artificial Intelligence Technologies platform may use the information you have provided for the purpose of providing services on the platform and sending marketing messages to you, and that the privacy policy of the Artificial Intelligence Technologies platform specifies how your personal information is collected, processed, used, and transferred, and confidentiality of information is subject to the "Privacy and Confidentiality Policy" of the Artificial Intelligence Technologies platform.</li>
</ul>

          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة السادسة عشر – سياسة الاستبدال والاسترجاع:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 16 - Exchange and Return Policy:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">يمكنك إلغاء الاشتراك في باقات (STARTER PLAN ، ADVANCE PLAN) عند التفعيل لأول مرة واسترداد رسوم الاشتراك خلال 14 يومًا من تاريخ بدء الاشتراك، باستثناء باقة (تقنيات الذكاء الاصطناعي سبيشل).</li>
    <li class="mb-2">ستتم عملية تجديد الباقات المدفوعة تلقائيًا إذا تم تفعيل خيار التجديد التلقائي وكانت بطاقة ائتمانية مضافة أو وجود رصيد كافٍ في المحفظة.</li>
    <li class="mb-2">في حالة تفعيل التجديد التلقائي، سيتم خصم رصيد الاشتراك تلقائيًا عند إصدار فاتورة الاشتراك الجديدة.</li>
    <li class="mb-2">يمكن إيقاف الاشتراك مؤقتًا في باقات ((STARTER PLAN ، ADVANCE PLAN) لمدة تصل إلى 30 يومًا بدون رسوم، شرط عدم تجاوز إيقاف الاشتراك مرتين في السنة.</li>
    <li class="mb-2">عند إلغاء الاشتراك في إحدى الباقات المدفوعة، يمكنك الاستمرار في استخدام الباقة والاستفادة من مزاياها حتى تاريخ انتهاء الاشتراك.</li>
    <li class="mb-2">عند إلغاء أي باقة لأول مرة، سيتم فرض رسوم تحويل إلى حسابكم البنكي تبلغ 8.05 ريال، شاملة الضريبة، بالإضافة إلى أي رسوم أخرى قد تُفرض لتحقيق ذلك..</li>
    <li class="mb-2">لا يمكن إلغاء أو استبدال قوالب تصميم المتجر.</li>
    <li class="mb-2">فترة صلاحية حسابات المستخدمين والفروع المشتراة تتطابق مع فترة سريان الاشتراك، وتُحتسب تكلفة الحساب وفقًا للمدة المتبقية للاشتراك.</li>
    <li class="mb-2">فترة صلاحية الرسائل المشتراة للمتجر هي سنة واحدة من تاريخ الشراء.</li>
    <li class="mb-2">في حالة عدم التجديد خلال 5 أيام من تاريخ انتهاء الباقة، لن يتمكن التاجر من الاستفادة من أي خدمات المنصة حتى يتم إعادة تفعيل الباقة.</li>
</ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
            <li class="mb-2">You can cancel subscriptions to the STARTER PLAN and ADVANCE PLAN upon first activation and get a refund of the subscription fees within 14 days from the subscription start date, except for the Artificial Intelligence Technologies Special package.</li>
    <li class="mb-2">Paid subscriptions will be automatically renewed if the auto-renewal option is enabled and a credit card is added or there is sufficient balance in the wallet.</li>
    <li class="mb-2">If auto-renewal is activated, the subscription balance will be deducted automatically upon issuing the new subscription invoice.</li>
    <li class="mb-2">Subscription to STARTER PLAN and ADVANCE PLAN can be temporarily suspended for up to 30 days free of charge, provided that the suspension does not exceed twice a year.</li>
    <li class="mb-2">Upon canceling a paid subscription, you can continue to use the package and benefit from its features until the subscription expiration date.</li>
    <li class="mb-2">Upon canceling any package for the first time, a transfer fee of 8.05 SAR, inclusive of tax, will be charged to your bank account, plus any other fees that may be imposed to achieve this.</li>
    <li class="mb-2">Design templates for the store cannot be canceled or replaced.</li>
    <li class="mb-2">The validity period of user accounts and purchased branches corresponds to the subscription period, and the account cost is calculated based on the remaining duration of the subscription.</li>
    <li class="mb-2">The validity period of purchased store messages is one year from the date of purchase.</li>
    <li class="mb-2">If renewal is not made within 5 days from the end date of the package, the merchant will not be able to benefit from any platform services until the package is reactivated.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة السابعة عشر – إيقاف أو إلغاء العضوية:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 17 - Suspension or cancellation of membership:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <p className="my-3 font-semibold">
تحتفظ منصة تقنيات الذكاء الاصطناعي، وفقاً لاتفاقية الاستخدام والقوانين المعمول بها في المملكة العربية السعودية، بالحق في تعليق الوصول إلى خدماتها بشكل مؤقت أو دائم، أو إلغاء عضوية التاجر وحقوقه، في الحالات التالية:
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">انتهاك قواعد وأحكام اتفاقية الاستخدام.</li>
    <li class="mb-2">عدم تمكن منصة تقنيات الذكاء الاصطناعي من التحقق من صحة المعلومات المقدمة من قبل المستخدم.</li>
    <li class="mb-2">إذا قررت منصة تقنيات الذكاء الاصطناعي أن أنشطة المستخدم تنتهك القانون أو تسبب مشاكل قانونية للمستخدمين الآخرين أو للمنصة.</li>
    <li class="mb-2">قد تلجأ منصة تقنيات الذكاء الاصطناعي “حسب تقديرها” إلى إعادة نشاط المستخدمين الموقوفين، حيث أن المستخدم الذي أوقف نشاطه نهائياً أو أُلغيت عضويته، قد لا يكون بإمكانه التسجيل أو استعادة حسابه في منصة تقنيات الذكاء الاصطناعي أو استخدام المنصة بأي طريقة كانت مهما كانت الظروف، لحين السماح له بإعادة نشاطه في منصة تقنيات الذكاء الاصطناعي من قبل إدارة منصة تقنيات الذكاء الاصطناعي ومع ذلك فإن المستخدم في حال انتهاكه لاتفاقية الاستخدام هذه فإن منصة تقنيات الذكاء الاصطناعي تحتفظ بحقها في استعادة أو المطالبة بأي مبالغ مستحقة لمنصة تقنيات الذكاء الاصطناعي على المستخدم وأي خسائر أو أضرار تسبب بها التاجر لمنصة تقنيات الذكاء الاصطناعي كما أن لمنصة تقنيات الذكاء الاصطناعي الحق باتخاذ الإجراءات القانونية أو اللجوء إلى السلطات القضائية في المملكة العربية السعودية ضد المستخدم حسبما تراه منصة تقنيات الذكاء الاصطناعي مناسباً.</li>
    <li class="mb-2">تحتفظ المنصة بحق المطالبة بأية مبالغ مستحقة أو التعويض عن الخسائر الناتجة عن أنشطة التاجر، ولها الحق في اتخاذ الإجراءات القانونية المناسبة.</li>
    <li class="mb-2">إن منصة تقنيات الذكاء الاصطناعي لا تتنازل عن حقها في اتخاذ الإجراءات المناسبة حيال أي انتهاك يحدث لقواعد وأحكام اتفاقية الاستخدام ولغيرها من أفعال الانتهاك المشابهة ، وكذلك إن منصة تقنيات الذكاء الاصطناعي لا تلتزم في القيام باتخاذ أي اجراءات ضد أي انتهاك يحدث لاتفاقية الاستخدام وإنما يخضع هذا الأمر لتقدير إدارة منصة تقنيات الذكاء الاصطناعي وإدارتها القانونية.</li>
</ul>
          </>
        ) : (
          <>
            <p className="my-3 font-semibold">
            The Artificial Intelligence Technologies Platform, in accordance with the Terms of Use and the laws in force in the Kingdom of Saudi Arabia, reserves the right to temporarily or permanently suspend access to its services, or cancel the merchant’s membership and rights, in the following cases:
            </p>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
              Violation of the rules and provisions of the Terms of Use.
              </li>
              <li className="mb-2">
              Inability of the AI Technologies Platform to verify the accuracy of the information provided by the user.</li>
              <li className="mb-2">
              If the AI Technologies Platform determines that the user's activities violate the law or cause legal problems for other users or the platform.</li>
              <li className="mb-2">
              The AI Technologies Platform may, at its discretion, restore the activities of suspended users. However, a user whose activity has been permanently suspended or whose membership has been canceled may not be able to register or retrieve their account on the AI Technologies Platform or use the platform in any way, regardless of the circumstances, until permitted to resume activity by the management of the AI Technologies Platform. However, if the user violates this Terms of Use, the AI Technologies Platform reserves the right to reclaim or demand any amounts due to the AI Technologies Platform from the user and any losses or damages caused by the merchant to the AI Technologies Platform. The AI Technologies Platform also has the right to take appropriate legal action or resort to the judicial authorities in the Kingdom of Saudi Arabia against the user as deemed appropriate by the AI Technologies Platform.</li>
              <li className="mb-2">
              The platform reserves the right to claim any amounts due or compensation for losses resulting from the merchant's activities, and it has the right to take appropriate legal action.</li>
              <li className="mb-2">
              The AI Technologies Platform does not waive its right to take appropriate actions for any violations of the rules and provisions of the Terms of Use or any similar violations.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
            المادة الثامنة عشر – الدفع والمعاملات التجارية:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 18 - Payment and Commercial Transactions:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
             
            <li class="mb-2">يلتزم التاجر بإدارة متجره بما يضمن حقوق المستهلك ويمنع النزاعات.</li>
    <li class="mb-2">يجب على التاجر التحكم في عمليات الدفع والبيع وفقاً للطرق الموضحة في هذه الاتفاقية.</li>
    <li class="mb-2">منصة تقنيات الذكاء الاصطناعي الحق في رفض أو إلغاء أي معاملة شرائية، مع الالتزام بإخطار المستخدم خلال خمسة أيام بأي من طرق التواصل المتاحة لمنصة تقنيات الذكاء الاصطناعي.</li>
    <li class="mb-2">تنوه منصة تقنيات الذكاء الاصطناعي بأنها ليست طرفاً في أي نزاع قد ينشأ بين التاجر والمستهلك، ولا علاقة لمنصة تقنيات الذكاء الاصطناعي بأي تقصير يحصل من المستهلك تجاه المتجر، سواءً بتخلّفه عن السداد أو غير ذلك.</li>
    <li class="mb-2">يوافق التاجر على عدم إجراء عمليات شراء وهمية أو مزورة وعلى استخدام معلومات حقيقية فقط، أو استخدام بطاقة ائتمان لا تخصه دون تفويض بالشراء، حيث أن منصة تقنيات الذكاء الاصطناعي لها الحق في اتخاذ الإجراءات القانونية المناسبة ضد كل من يقوم بعمليات احتيالية من هذا النوع.</li>
    <li class="mb-2">يعي التجار أن إدارة التحويلات المالية تخضع للرقابة من قبل مؤسسة النقد ووزارة التجارة والاستثمار.</li>
    <li class="mb-2">تحتفظ المنصة بحق فرض رسوم على التجار أو المتاجر نظير أنشطتهم التجارية، وقد تشمل هذه الرسوم ما يُفرض من قبل الجهات الحكومية أو البنوك أو أية رسوم إدارية أخرى.</li>
</ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
            <li class="mb-2">The merchant commits to managing their store in a way that ensures consumer rights and prevents disputes.</li>
  <li class="mb-2">The merchant must control payment and sales operations according to the methods outlined in this agreement.</li>
  <li class="mb-2">The AI Technologies Platform reserves the right to reject or cancel any purchase transaction, with the obligation to notify the user within five days using any of the available communication methods provided by the AI Technologies Platform.</li>
  <li class="mb-2">The AI Technologies Platform notifies that it is not a party to any dispute that may arise between the merchant and the consumer, and has no involvement in any negligence by the consumer towards the store, whether it be failure to pay or otherwise.</li>
  <li class="mb-2">The merchant agrees not to conduct fraudulent or forged purchases and to use only genuine information, or to use a credit card not belonging to them without authorization for purchases. The AI Technologies Platform reserves the right to take appropriate legal action against anyone engaging in such fraudulent activities.</li>
  <li class="mb-2">Merchants are aware that financial transactions are subject to supervision by the Saudi Arabian Monetary Authority and the Ministry of Commerce and Investment.</li>
  <li class="mb-2">The platform reserves the right to impose fees on merchants or stores for their commercial activities, which may include fees imposed by government entities, banks, or any other administrative fees.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة التاسعة عشرة – المحتويات والمنتجات الممنوعة:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 19 - Prohibited Contents and Products:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">كتاجر في منصة تقنيات الذكاء الاصطناعي، يجب عليك عدم السماح بعرض أو نشر أي محتوى يخالف سياسة الخصوصية أو ينتهك شروط اتفاقية الاستخدام.</li>
    <li class="mb-2">تحظر المنصة بيع المنتجات والخدمات التالية، وهي تشمل لكن لا تقتصر على: أي منتج أو خدمة تتضمن مقامرة، صناديق حظ أو أي منتج عشوائي، منتجات أو خدمات قد تسبب ضررًا جسديًا أو نفسيًا، محتويات مقرصنة أو أي شيء يخالف حقوق الملكية الفكرية، منتجات أو خدمات ذات طابع جنسي أو إباحي أو تدعو لذلك.</li>
</ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
            <li class="mb-2">As a merchant on the AI Technologies Platform, you must not allow the display or publication of any content that violates the privacy policy or breaches the terms of use agreement.</li>
  <li class="mb-2">The platform prohibits the sale of the following products and services, including but not limited to: any product or service involving gambling, luck-based boxes, or any random product, products or services that may cause physical or psychological harm, pirated contents, or anything that violates intellectual property rights, products or services of a sexual or pornographic nature or that promote it.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">       
المادة العشرون – الالتزام بنظام الجرائم المعلوماتية السعودي:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 20 - Compliance with the Saudi Information Crimes Law:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
              	يجب على التجار التزام بنظام الجرائم المعلوماتية السعودي، وفي حالة المخالفة، يتحمل التاجر كامل المسؤولية، ولا تتحمل منصة تقنيات الذكاء الاصطناعي أي مسؤولية عن أي نتائج قانونية تنجم عن مخالفات المتجر، بحيث أن التاجر هو المسئول عن متجره وعن تعامله مع المستهلكين مسئولية كاملة، ولمنصة تقنيات الذكاء الاصطناعي دائماً الحق في اتخاذ ما تراه مناسباً عن أي متجر أو تاجر يخالف أحكام نظام الجرائم المعلوماتية السعودي، سواءً عن طريق ابلاغ السلطات الرسمية أو الاكتفاء بإغلاق المتجر الالكتروني أو إلغاء عضوية التاجر لدى المنصة.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
              Merchants must adhere to the Saudi cybercrime law, and in the event of a violation, the merchant bears full responsibility, and the Artificial Intelligence Technologies platform does not bear any responsibility for any legal consequences resulting from the store’s violations, as the merchant is fully responsible for his store and his dealings with consumers, and the platform has full responsibility. Artificial intelligence technologies always have the right to take what they deem appropriate regarding any store or merchant who violates the provisions of the Saudi cybercrime law, whether by notifying the official authorities or simply closing the online store or canceling the merchant’s membership on the platform.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الحادية والعشرون – تقييد الولوج والعضوية:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 21 - Restricting Access and Membership:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
               يحق لمنصة تقنيات الذكاء الاصطناعي، دون المساس بحقوق التجار الأخرى، تعليق أو إلغاء عضوية التاجر أو تقييد وصوله إلى خدمات المنصة في أي وقت، دون إنذار مسبق ولأي سبب، ودون تحديد.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
              The Artificial Intelligence Technologies Platform has the right, without prejudice to the rights of other merchants, to suspend or cancel the merchant’s membership or restrict his access to the platform’s services at any time, without prior warning and for any reason, and without limitation.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الثانية والعشرون – الضمانات:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 22 - Guarantees:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">لا تضمن منصة تقنيات الذكاء الاصطناعي إصلاح الأعطال أو خلو المنتجات من العيوب؛ بل يقع ذلك على عاتق التاجر الذي يجب أن يذكر شروط الضمان المحددة ومدته في وصف المنتج.</li>
    <li class="mb-2">يجب على المتجر تحديد سياسة الاستبدال والاسترجاع الخاصة به والالتزام بأحكام نظام التجارة الإلكترونية وغيرها من الأنظمة ذات الصلة.</li>
</ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
            <li class="mb-2">AI Technologies Platform does not guarantee the repair of defects or that products are free of flaws; this responsibility lies with the merchant, who must specify the warranty terms and duration in the product description.</li>
  <li class="mb-2">The store must define its exchange and return policy and comply with the provisions of the e-commerce regulations and other relevant laws.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الثالثة والعشرون – مسؤولية التاجر:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 23 - Merchant's Responsibility:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
              	يوافق التاجر على تحمل المسئولية ووقاية منصة تقنيات الذكاء الاصطناعي أو منسوبيها أو مُلّاكها أو المتفرعين عنها من أي ضرر قد يقع على منصة تقنيات الذكاء الاصطناعي من جراء مخالفات التاجر ، كما يلتزم برفع أي ضرر أو منع أي ضرر قد يلحق بمنصة تقنيات الذكاء الاصطناعي أو بمنسوبيها أو بأحدهم من جراء مطالبات أو خسائر أو أعطال أو تكاليف أو نفقات أو رسوم نتجت بسبب إساءة استخدام التاجر أو سوء استخدام المستخدم ونتج عن ذلك خرق لاتفاقية الاستخدام أو الأنظمة والقوانين المرعية بالمملكة العربية السعودية أو تعدي على حقوق أحد التجار أو أطراف ثالثة أو شكوى من أحد المستخدمين أو أطراف ثالثة.

              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-2">
              The merchant agrees to bear responsibility and protect the Artificial Intelligence Technologies Platform, its employees, owners, or its affiliates from any damage that may occur to the Artificial Intelligence Technologies platform as a result of the merchant’s violations. He is also committed to removing any damage or preventing any damage that may befall the Artificial Intelligence Technologies platform, its employees, or One of them as a result of claims, losses, malfunctions, costs, expenses, or fees resulting from the merchant’s misuse or the user’s misuse, which resulted in a violation of the usage agreement or the regulations and laws in force in the Kingdom of Saudi Arabia, an infringement of the rights of a merchant or third parties, or a complaint from a user. or third parties.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الرابعة والعشرون – العلاقة والإشعارات بين منصة تقنيات الذكاء الاصطناعي والتجار:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 24 - Relationship and Notices between the Artificial Intelligence Technologies Platform and Merchants:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
              	لا تتضمن أي من قواعد وأحكام اتفاقية الاستخدام هذه إشارة إلى وجود شراكة بين أي تاجر ومنصة تقنيات الذكاء الاصطناعي ، ولا تسمح منصة تقنيات الذكاء الاصطناعي لأي تاجر في أي حال من الأحوال بالإشارة المباشرة أو الغير مباشرة أو الإملاء إلى وجود علاقة أي كان نوعها سواء كانت مباشرة أو غير مباشرة بينه كتاجر وبين منصة تقنيات الذكاء الاصطناعي أو إدارتها ، وأن أي إشعارات يرغب التاجر في إرسالها إلى منصة تقنيات الذكاء الاصطناعي ، عليه أن يقوم بإرسالها عبر البريد الإلكتروني وتقوم منصة تقنيات الذكاء الاصطناعي بالرد على الرسالة الإلكترونية. وأنت كتاجر توافق على أن أي إشعارات ترسل إليك من منصة تقنيات الذكاء الاصطناعي سوف تُسلّم إليك بواسطة البريد الإلكتروني الذي قمت بتزويد منصة تقنيات الذكاء الاصطناعي به خلال عملية التسجيل.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
              None of the rules and provisions of this Terms of Use Agreement imply the existence of a partnership between any merchant and the AI Technologies Platform. The AI Technologies Platform does not allow any merchant, under any circumstances, to directly or indirectly imply or suggest the existence of any kind of relationship, whether direct or indirect, between them as a merchant and the AI Technologies Platform or its management. Any notifications that the merchant wishes to send to the AI Technologies Platform must be sent via email, and the AI Technologies Platform will respond to the email message. As a merchant, you agree that any notifications sent to you from the AI Technologies Platform will be delivered to you via the email address you provided to the AI Technologies Platform during the registration process.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الخامسة والعشرون – انتقال الحقوق والواجبات:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 25 - Transfer of Rights and Duties:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
               كتاجر، لا يحق لك الاعتراض على الإجراءات التي تتخذها إدارة منصة تقنيات الذكاء الاصطناعي بشأن العمليات الإدارية أو التقنية المتعلقة بالمنصة، وليس من الضروري أن تقوم المنصة بإخطارك بتلك الإجراءات ما لم تجد ضرورة لذلك، وفقًا لشروط اتفاقية الاستخدام.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
              As a merchant, you do not have the right to object to the actions taken by the management of the Artificial Intelligence Technologies Platform regarding administrative or technical operations related to the platform, and it is not necessary for the platform to notify you of those actions unless it finds it necessary, in accordance with the terms of the Terms of Use.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة السادسة والعشرون – التشريعات المطبقة:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 26 - Applicable Legislation:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
              تخضع اتفاقية الاستخدام هذه للقوانين والأنظمة السعودية، وتحكمها بالكامل القوانين المعمول بها في المملكة العربية السعودية.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
              This User Agreement is subject to Saudi laws and regulations, and is entirely governed by the laws in force in the Kingdom of Saudi Arabia.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة السابعة والعشرون – الالتزامات الأخلاقية للتاجر مع المستهلكين:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 27 - The merchant’s ethical obligations with consumers:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">يجب على التاجر التعامل مع المستهلكين بصدق، أمانة، ونزاهة.</li>
    <li class="mb-2">يلتزم التاجر بمعاملة المستهلكين بأخلاق حميدة.</li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
            <li class="mb-2">The merchant must deal with consumers honestly, faithfully, and fairly.</li>
  <li class="mb-2">The merchant is committed to treating consumers with good manners.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الثامنة والعشرون – الترويج والعروض التجارية:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 28 - Promotions and Commercial Offers:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">تطبق شروط اتفاقية الاستخدام على جميع الأنشطة الترويجية سواء الورقية أو الإلكترونية عبر منصات متعددة وعبر مختلف وسائل التواصل الاجتماعي.</li>
    <li class="mb-2">تعتبر هذه المنشورات أدوات ترويجية لمنصة تقنيات الذكاء الاصطناعي.</li>
    <li class="mb-2">إن المنشورات قابلة للتغيير وهي غير ملزمة لمنصة تقنيات الذكاء الاصطناعي فيما يخص ثبات أسعار الخدمات أو الباقات أو العروض وتغيير الأسعار يخضع للمتغيرات التي تطرأ على منصة تقنيات الذكاء الاصطناعي أو المتاجر أو التجار.</li>
    <li class="mb-2">العروض المقدمة من المنصة محدودة زمنيًا ولا تعهد المنصة بتمديد هذه الفترات أو الاستمرار فيها.</li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
            <li class="mb-2">The terms of the user agreement apply to all promotional activities, whether in print or electronic, across multiple platforms and various social media channels.</li>
  <li class="mb-2">These publications are promotional tools for the AI Technologies Platform.</li>
  <li class="mb-2">The publications are subject to change and do not bind the AI Technologies Platform regarding the stability of service prices, packages, or offers. Price changes are subject to the variables affecting the AI Technologies Platform, stores, or merchants.</li>
  <li class="mb-2">The offers provided by the platform are time-limited, and the platform does not guarantee the extension or continuation of these periods.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة التاسعة والعشرون – الخدمات اللوجستية والإستراتيجية:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 29 - Logistics and Strategic Services:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">بموجب قواعد وأحكام اتفاقية الاستخدام هذه فإن منصة تقنيات الذكاء الاصطناعي قد توفّر بعض الخدمات الإستراتيجية أو اللوجستية عن طريق طرف ثالث أو أطراف ثالثة ، وقدّ تكون هذه الخدمات على سبيل المثال لا الحصر : خدمات شركات الشحن وتوصيل المنتجات والبضائع.</li>
    <li class="mb-2">تعمل المنصة على تسهيل الوصول لهذه الخدمات لكنها لا تتحمل مسؤولية مباشرة عن أفعال الأطراف الثالثة وتحيطكم علمًا منصة تقنيات الذكاء الاصطناعي بأنها غير مسئولة تمامًا بشكل مباشر أو غير مباشر عن أي تصرفات تصدُر من أي طرف ثالث وأن ما تقوم به هو مجرد ربط بين المستخدم ومُقدِم الخدمة (الطرف الثالث).</li>
    <li class="mb-2">ليس إلزاميًا للمستخدمين طلب هذه الخدمات؛ الأمر يعود لرغبة المستخدم.</li>
    <li class="mb-2">تخلي المنصة مسؤوليتها عن العلاقات بين التجار والأطراف الثالثة.</li>
    <li class="mb-2">إن بعض مقدمين الخدمات الإستراتيجية واللوجستية يضعون اشتراطات خاصة بهم أو تكاليف خاصة بهم ولا تملك منصة تقنيات الذكاء الاصطناعي أي سلطة على هذه الاشتراطات أو هذه التكاليف ، ولذلك تنصح منصة تقنيات الذكاء الاصطناعي التجّار المسجّلين لديها إلى الاطلاع على شروط مقدم الخدمة (الطرف الثالث) وتكاليف خدماته قبل تأكيد طلب الخدمة.</li>
    <li class="mb-2">في حال تقدّم المستخدم بطلب خدمة مقدّمة عن طريق (طرف ثالث) فإن المستخدم بهذا التصرّف يصرّح لمنصة تقنيات الذكاء الاصطناعي ويمنحها الإذن بتزويد مقدم الخدمة (الطرف الثالث) ببيانات المستخدم الشخصية التي يطلبها ، وغير ذلك من البيانات التي يحتاجها مقدّم الخدمة (الطرف الثالث) ، ويكون ذلك وفقاً لقواعد وأحكام سياسة الخصوصية وسرية المعلومات المعمول بها لدى منصّة تقنيات الذكاء الاصطناعي.</li>
    <li class="mb-2">بعض الخدمات المقدمة بواسطة (اطراف ثالثة) على منصة تقنيات الذكاء الاصطناعي يستفيد منها التاجر عن طريق بوليصات صادرة بالاتفاق بين مزود الخدمة ومنصة تقنيات الذكاء الاصطناعي ، فيكون استخدامها عن طريق منصة تقنيات الذكاء الاصطناعي وقد تحسم منصة تقنيات الذكاء الاصطناعي بعض التكاليف منها تنفيذاً للاتفاق الذي بين منصة تقنيات الذكاء الاصطناعي ومزوّد الخدمة (اطراف ثالثة) ، ولا تقع على عاتق منصة تقنيات الذكاء الاصطناعي أي مسؤولية تنتج عن إخلال مزود الخدمة في تقديم خدماته.</li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
            <li class="mb-2">Under the terms and conditions of this User Agreement, the AI Technologies Platform may provide some strategic or logistical services through a third party or parties. These services may include, but are not limited to, shipping and delivery services.</li>
  <li class="mb-2">The platform facilitates access to these services but does not bear direct responsibility for the actions of third parties. It is important to note that the AI Technologies Platform is not entirely responsible, directly or indirectly, for any actions taken by third parties. The platform merely acts as a link between the user and the service provider (the third party).</li>
  <li class="mb-2">It is not mandatory for users to request these services; it is at the discretion of the user.</li>
  <li class="mb-2">The platform disclaims responsibility for relationships between merchants and third parties.</li>
  <li class="mb-2">Some providers of strategic and logistical services may impose their own conditions or costs, over which the AI Technologies Platform has no authority. Therefore, the registered merchants are advised to review the terms and costs of service providers (third parties) before confirming a service request.</li>
  <li class="mb-2">If a user requests a service provided by a third party through the platform, the user hereby authorizes and grants the AI Technologies Platform permission to provide the third-party service provider with the personal user data requested. This is in accordance with the rules and provisions of the Privacy and Confidentiality Policy applicable to the AI Technologies Platform.</li>
  <li class="mb-2">Some services provided by third parties on the AI Technologies Platform may be utilized by the merchant through policies issued through an agreement between the service provider and the AI Technologies Platform. The platform may deduct some costs from them as per the agreement between the AI Technologies Platform and the service provider (third parties). The AI Technologies Platform bears no responsibility for any defaults by the service provider in providing its services.</li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الثلاثون – خدمات الدعم الفني:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 30 - Technical Support Services:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
            <li class="mb-2">AI Technologies Platform provides technical support services to stores and merchants according to the level of service available in the selected package. These services include:
  <ol class="list-decimal text-justify py-3 pr-4">
    <li class="mb-2">Free dashboard for stores including specific services at no cost.</li>
    <li class="mb-2">Merchants can request additional or diverse services subject to the fees specified in the package policy provided by the platform.</li>
    <li class="mb-2">The platform enables stores to communicate with the technical support team to assist them in managing their operations on the platform and addressing any technical issues they may encounter.</li>
  </ol>
  </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
                The Artificial Intelligence Technologies platform provides technical support services to stores and merchants according to the level of service available in the package chosen by the store. These services include:
              
              <ol class="list-decimal text-justify py-3 pr-4">
             <li class="mb-2">لوحة تحكم مجانية للمتاجر تشمل خدمات محددة بدون تكلفة.</li>
    <li class="mb-2">يمكن للتاجر طلب خدمات إضافية أو متنوعة تخضع للرسوم المحددة في سياسة الباقات التي تقدمها المنصة.</li>
    <li class="mb-2">تمكن المنصة المتاجر من التواصل مع فريق الدعم الفني لمساعدتهم في إدارة أعمالهم على المنصة ومعالجة المشاكل التقنية التي قد تواجههم.</li>

             </ol>
             </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الحادية والثلاثون – تسوية النزاعات:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 31 - Dispute Settlement:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
بموجب قواعد وأحكام اتفاقية الاستخدام هذه فإنه في حال النزاع فيتم حل الخلاف عن طريق التوفيق والمفاوضات أو التسوية الودية، وفي حال استمرار الخلاف فيتم حله عن طريق السلطات المختصة بالمملكة العربية السعودية.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pl-4">
              <li className="mb-2">
              In accordance with the rules and provisions of this User Agreement, in the event of a dispute, the dispute will be resolved through conciliation, negotiations or amicable settlement, and if the dispute continues, it will be resolved through the competent authorities in the Kingdom of Saudi Arabia.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">         
المادة الثانية والثلاثون – سياسة معالجة شكاوى المستهلكين:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 32 - Policy for Handling Consumer Complaints:
            </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
              ترجو منصة تقنيات الذكاء الاصطناعي من المستهلكين اتباع الإجراءات التالية في حال وجود شكوى:
              </li>
              <ol className="list-disc mr-4">
              <li className="mb-2">الخطوة الأولى: التقدم بإفادة شكوى عبر الرابط المخصص لذلك في منصة تقنيات الذكاء الاصطناعي ، على أن تتضمّن الإفادة: نص الشكوى والمستندات المؤيدة لها – اسم المتجر المخالف. ومن ثم سوف تقوم منصة تقنيات الذكاء الاصطناعي بإحالة الشكوى إلى المتجر المعني من أجل حلها.</li>
            <li className="mb-2">الخطوة الثانية: إذا لم يستجب المتجر خلال سبعة أيام، فسوف تتم إفادة المستهلك بذلك.</li>
            <li className="mb-2">الخطوة الثالثة: يحق للمستهلك اتخاذ الإجراءات التي يراها مناسبة بناءً على رد المتجر، وفي حال عدم قناعة المستهلك برد المتجر فإنه يحق له طلب الحصول على نسخة من الهوية القانونية للمتجر لاتخاذ ما يراه مناسباً بحق المتجر.</li>
              </ol>
              <li className="mb-2">
              تحتفظ منصة تقنيات الذكاء الاصطناعي بالحق في إغلاق أي متجر مخالف إذا ثبتت المخالفة.
              </li>
            </ul>
          </>
        ) : (
          <>
            <ul className="list-decimal py-3 pl-4">
              <li className="mb-2">
              The AI ​​Technologies Platform requests that consumers follow the following procedures in the event of a complaint:
              </li>
              <ol className="list-disc ml-4">
              <li class="mb-2">First Step: Submitting a complaint through the dedicated link on the AI Technologies Platform, including: the complaint text, supporting documents, and the name of the violating store. AI Technologies Platform will then refer the complaint to the concerned store for resolution.</li>
  <li class="mb-2">Second Step: If the store does not respond within seven days, the consumer will be informed of this.</li>
  <li class="mb-2">Third Step: The consumer has the right to take appropriate action based on the store's response. If the consumer is not satisfied with the store's response, they have the right to request a copy of the store's legal identity to take appropriate action against the store.</li>
              </ol>
              <li className="mb-2">
              The Artificial Intelligence Technologies platform reserves the right to close any violating store if the violation is proven.
              </li>
            </ul>
          </>
        )}
      </div>

      <div className="mt-5 text-justify">
        {selectLanguage === "ar" ? (
          <>
            <h4 className="text-xl font-bold">
المادة الثالثة والثلاثون – الأحكام العامة:
            </h4>
          </>
        ) : (
          <>
            <h4 className="text-xl font-bold">
            Article 33 - General Provisions:    
                    </h4>
          </>
        )}

        {selectLanguage === "ar" ? (
          <>
            <ol className="list-disc mr-4 my-3">
              <li className="mb-2">
              في حال إبطال أي مادة أو بند ضمن اتفاقية الاستخدام هذه، أو إذا باتت مادة أو بند معين غير نافذ، فإن ذلك لا يؤثر على صحة الأحكام والبنود الأخرى المتبقية، التي تستمر في النفاذ حتى تقرر إدارة منصة تقنيات الذكاء الاصطناعي خلاف ذلك
              </li>
              <li className="mb-2">
              تُعد اتفاقية الاستخدام هذه، والتي قد تخضع للتعديل من وقت لآخر بناءً على الضرورة، الإطار الأساسي للعمل والتعاون بين التاجر ومتجره ومنصة تقنيات الذكاء الاصطناعي فقط، وعلى التاجر والمتجر الالتزام بما جاء فيها، ويتوجب على التاجر مراعاة ما يلي:
              </li>
            </ol>
            <ul className="list-[upper-roman] text-justify py-3 pr-4">
              <li className="mb-2">
                تنطبق إتفاقية الاستخدام هذه على جميع مستخدمي منصة AI
                Techplatform، وتنظم العلاقة، التي هي العقد بين التاجر و AI
                TechPlatform فقط، بغض النظر عن أي شكل قانوني أو مؤسسي أو تجاري
                أو خيري يقوم به التاجر أو المتجر. أما العلاقة بين التاجر
                والمستهلك فهي علاقة قانونية مستقلة لها ضوابطها الحالية الخاصة .
              </li>
              <li className="mb-2">
                يجب علي المحل ان يضع اتفاقيه إستعماله الخاصه به علي ان يتم ذلك
                وفقا لاحكام القوانين والانظمه المعمول بها في المملكه العربيه
                السعوديه وعلي الاخص نظام المعاملات الالكترونيه ونظام التجاره
                الالكترونيه وغيرها من الانظمه المتعلقه بنشاط التاجر .
              </li>
              <li className="mb-2">
                التاجر الذي يلتحق لمنصة آي تكنعبر ( شركاء النجاح - الرابط موضوع
                ) وبذلك يعطي شريك النجاح الذي سجل معه الحق في الاطلاع على
                معلومات التاجر و محله .
              </li>
              <li className="mb-2">
                لا يحق لأحد - بخلاف إدارة منصة تكنولوجيا الذكاء الاصطناعي - فرض
                أي مواد أو شروط أو أحكام في إتفاقية إستخدام منصة تكنولوجيا
                الذكاء الاصطناعي، وقد تتلقى منصة تكنولوجيا الذكاء الاصطناعي
                اقتراحات من التجار فيما يتعلق بهذه الاتفاقية .
              </li>
              <li className="mb-2">
                إذا تمت ترجمة إتفاقية الاستخدام إلى أي لغة أخرى، سواء كانت على
                منصة AI Techor خلاف ذلك، فإن النص العربي لاتفاقية الاستخدام يبقى
                هو الأصل في جميع المعاملات .
              </li>
              <li className="mb-2">منصة تقنية الذكاء الاصطناعي.</li>
            </ul>
          </>
        ) : (
          <>
            <ol className="list-disc ml-4 my-3">
              <li className="mb-2">
              If any article or clause within this User Agreement is invalidated, or if a specific article or clause becomes ineffective, this does not affect the validity of the other remaining provisions and clauses, which continue to be in effect until the management of the Artificial Intelligence Technologies Platform decides otherwise.
              </li>
              <li className="mb-2">
              This User Agreement, which may be subject to amendment from time to time based on necessity, is the basic framework for work and cooperation between the merchant, his store, and the artificial intelligence technology platform only, and the merchant and store must abide by what is stated therein, and the merchant must take into account the following:
              </li>
            </ol>
            <ul className="list-decimal py-3 pl-4">
            <li class="mb-2">This User Agreement applies to all users of the Artificial Intelligence Technologies platform and regulates the relationship between the merchant and the Artificial Intelligence Technologies platform only, regardless of any formal or legal entity or institutional or commercial or charitable form adopted by the merchant or the store. Regarding the relationship between the merchant and the consumer, it is subject to an independent legal relationship with its own regulations.</li>
  <li class="mb-2">Every store must specify its own User Agreement, in line with Saudi laws, especially the Electronic Transactions System and the E-commerce System.</li>
  <li class="mb-2">No person other than the management of the Artificial Intelligence Technologies platform has the right to modify or add any provisions to the platform's User Agreement, although the platform accepts merchants' proposals regarding the agreement.</li>
  <li class="mb-2">If the User Agreement is translated into other languages, the Arabic text is considered the official reference in all transactions.</li>
  <li class="mb-2">This User Agreement cannot be cancelled or modified except by a decision from the management of the Artificial Intelligence Technologies platform.</li>
</ul>
          </>
        )}
      </div>
    </div>
  );
};

export default AgrementPage;
