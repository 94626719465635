import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "../../../components/utils/Interceptors";

const PackagePayments = () => {
  const { t } = useTranslation();
  const [packagePayments, setpackagePayments] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const baseUrl = process.env.REACT_APP_APIURL;

    const fetchAllPackagePayments = async () => {
      const res = await axios.get(`${baseUrl}/vendor/getallpurchasedplans`, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = await res.data;

      if (response.status_code === 200) {
        setpackagePayments(response.data);
      }
    };
    fetchAllPackagePayments();
  }, []);
  
  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-title text-sm md:text-base font-medium roboto">
          {t("pack_title_payment")}
        </h2>
      </div>

      <div className="mb-12">
        <div className="border rounded my-8 bg-white">
          <div className="border-b p-4">
            <h2 className="text-md font-medium">{t("pack_title_payment_p")}</h2>
          </div>
          <div className="body p-4">
            <div className="data-table">
              <div className="overflow-x-auto rounded-none">
                <table className="table w-full rounded-none text-[13px]">
                  <tr className="rounded-none border-b">
                    <th>#</th>
                    <th>{t("Package")}</th>
                    <th>{t("Amount")}</th>
                    <th>{t("Payment_Method")}</th>
                    <th>{t("Transaction_Id")}</th>
                    <th>{t("From")}</th>
                    <th>{t("To")}</th>
                  </tr>
                  <tbody>
                    {packagePayments.map((item, idx) => (
                      <tr>
                        <th>{idx + 1}</th>
                        <td>
                          <span className="text-xs truncate max-w-[100px]">
                            {item?.plan_name}
                          </span>
                        </td>
                        <td>{item?.payment_amount} ر.س</td>
                        <td>
                          <span className="py-1 px-3 rounded bg-gray-300 text-black roboto font-medium text-[.75rem]">
                            {" "}
                            {item?.payment_mode}
                          </span>
                        </td>
                        <td>{item?.transaction_id}</td>
                        <td>{moment(item?.from_date).format("DD/MM/YY")}</td>
                        <td>{moment(item?.to_date).format("DD/MM/YY")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackagePayments;
