import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getDataById from "../../../hooks/getDataById";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import axios from "../../../components/utils/Interceptors";

const UpdateCoupon = () => {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  const { state } = useLocation();
  const navigate = useNavigate();
  const { register, setValue, handleSubmit } = useForm();

  useEffect(() => {
    const getCoupon = async () => {
      const res = await getDataById("vendor/getbyidcoupon", state);
      if (res.status_code === 200) {
        setValue("coupon_id", res.data[0]?.coupon_id);
        setValue("coupon_code", res.data[0]?.coupon_code);
        setValue("coupon_type", res.data[0]?.coupon_type);
        setValue(
          "minimum_purchase_amount",
          res.data[0]?.minimum_purchase_amount
        );
        setValue("maximum_discount", res.data[0]?.maximum_discount);
        setValue("from_date", res.data[0]?.from_date);
        setValue("to_date", res.data[0]?.to_date);
      }
    };
    getCoupon();
  }, []);

  const onSubmit = async (data) => {
    const form = new FormData();
    form.append("to_date", data.to_date);
    form.append("id", data.coupon_id);

    const info = {
      id: data.coupon_id,
      to_date: data.to_date,
      minimum_purchase_amount: data.minimum_purchase_amount,
      maximum_discount: data.maximum_discount,
    };

    const toDate = data.to_date;
    const fromDate = data.from_date;

    if (toDate < fromDate) {
      return toast.error(
        "Coupon end date can't be less than coupon start date"
      );
    } else {
      const res = await axios.patch(
        `${process.env.REACT_APP_APIURL}/vendor/updatecoupon`,
        JSON.stringify(info),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;

      if (response?.status_code === 200) {
        toast.success(response.message);
        navigate("/vendor/coupons");
      } else {
        toast.error("Something went Wrong");
      }
    }
  };

  return (
    <div className="mt-2 mb-16">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl mx-auto">
          <div className="flex gap-x-4 bg-white border p-4">
            <div className="flex gap-x-4">
              <h2 className="text-title text-lg md:text-xl uppercase">
                {t("update_coupon")}
              </h2>
              <Link to={"/vendor/coupons"}>
                <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
                  <FaArrowCircleLeft size={15} />
                  {t("add_new_product_back")}
                </button>
              </Link>
            </div>
          </div>
          <div className="md:col-span-2 mx-auto">
            <div className="border bg-white">
              <div className="py-5 px-5">
                <div className="form-group mb-5">
                  <div className="grid md:grid-cols-2 gap-y-3">
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {" "}
                        {t("add_coupon_code")}{" "}
                        <span className="text-error">*</span>
                      </label>
                    </div>
                    <div>
                      <input
                        className="w-full h-[40px] input input-bordered rounded cursor-not-allowed"
                        readOnly
                        placeholder={`${t("add_coupon_code")}`}
                        {...register("coupon_code")}
                      />
                    </div>
                    <div className="hidden">
                      <label className="text-[13px]" htmlFor="planName">
                        {" "}
                        {t("update_coupon_id")}{" "}
                        <span className="text-error">*</span>
                      </label>
                    </div>
                    <div className="hidden">
                      <input
                        className="w-full h-[40px] input input-bordered rounded "
                        readOnly
                        placeholder={`${t("update_coupon_id")}`}
                        {...register("coupon_id")}
                      />
                    </div>

                    {/* coupon_type */}
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("add_coupon_type")}{" "}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] cursor-not-allowed input input-bordered rounded"
                        placeholder={`${t("add_coupon_type")}`}
                        {...register("coupon_type")}
                      />
                    </div>

                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("update_coupon_Purchase")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="number"
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("update_coupon_Purchase")}`}
                        {...register("minimum_purchase_amount")}
                      />
                    </div>
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("update_coupon_m_d")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="number"
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("update_coupon_m_d")}`}
                        {...register("maximum_discount")}
                      />
                    </div>

                    <div>
                      <label className="text-[13px]">
                        {t("update_coupon_f_date")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] input input-bordered rounded cursor-not-allowed"
                        placeholder={`${t("update_coupon_f_date")}`}
                        {...register("from_date")}
                      />
                    </div>

                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("update_coupon_end_date")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("update_coupon_end_date")}`}
                        {...register("to_date")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input
              className="py-1 mt-2 px-3 bg-primary text-white rounded cursor-pointer"
              type="submit"
              value={`${t("vd_pc_btn2")}`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateCoupon;
