import React, { useState } from "react";
import { useEffect } from "react";
import getStoreDetails from "../../../hooks/getStoreDetails";
import GeneralTab from "./Tabs/GeneralTab";
import LocationTab from "./Tabs/LocationTab";
import OtherTab from "./Tabs/OtherTab";
import { useTranslation } from "react-i18next";

const StoreSettings = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState("general");
  const [storeInfo, setStoreInfo] = useState({});
  const [otherInfo, setOtherInfo] = useState([]);

  useEffect(() => {
    const getStoreData = async () => {
      const response = await getStoreDetails();
      if (response.status_code === 200) {
        setStoreInfo(response.data);
        setOtherInfo(response.other_details);
      }
    };
    getStoreData();
  }, []);

  return (
    <div className="mb-16">
      <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
        {t("Settings")}
      </h2>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
          <div className="md:col-span-2 p-4">
            <ul className="flex flex-col gap-y-3">
              <li
                className={`p-2 border border-gray-200 rounded cursor-pointer hover:bg-primary hover:text-white duration-300 shadow-md ${
                  tab === "general" && "activeTab"
                }`}
                onClick={() => setTab("general")}
              >
                {t("General")}
              </li>
              <li
                className={`p-2 border border-gray-200 rounded cursor-pointer hover:bg-primary hover:text-white duration-300 shadow-md ${
                  tab === "location" && "activeTab"
                }`}
                onClick={() => setTab("location")}
              >
                {t("Location")}
              </li>
              <li
                className={`p-2 border border-gray-200 rounded cursor-pointer hover:bg-primary hover:text-white duration-300 shadow-md ${
                  tab === "other" && "activeTab"
                }`}
                onClick={() => setTab("other")}
              >
                {t("Social")}
              </li>
            </ul>
          </div>
          <div className="md:col-span-4 p-4">
            {tab === "general" ? (
              <GeneralTab storeInfo={storeInfo} />
            ) : tab === "location" ? (
              <LocationTab storeInfo={storeInfo} />
            ) : tab === "other" ? (
              <OtherTab storeInfo={storeInfo} otherInfo={otherInfo} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreSettings;
