import { useTranslation } from "react-i18next";
import CustomerTable from "./CustomerTable";

const RequestCustomers = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">
        {t("Requested Customers")}
      </h2>
      <CustomerTable />
    </>
  );
};

export default RequestCustomers;
