import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { AiFillEdit } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/utils/FilterComponent";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteDataById from "../../../hooks/deleteDataById";
import getDataById from "../../../hooks/getDataById";
import ImageUploading from "react-images-uploading";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  maxHeight: "calc(100vh - 5em)",
};

const Attribute = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const selectLanguage = i18next.language;
  const [attributes, setAttributes] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = attributes.filter(
    (item) =>
      item.attribute_type &&
      item.attribute_type.toLowerCase().includes(filterText.toLowerCase())
  );
  const [images, setImages] = useState([]);
  const [type, setType] = useState("slider");

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const columns = [
    {
      name: `${t("vd_pc_C_ID")}`,
      selector: (row, i) => i + 1,
      sortable: true,
    },
    {
      name: `${t("coupon_type")}`,
      selector: (row) => row.attribute_type,
      sortable: true,
    },
    {
      name: `${t("Image")}`,
      selector: (row) => row.attribute_type,
      cell: (row) => (
        <div className="flex items-center">
          <img
            className="w-12 h-20 mx-2 object-contain"
            src={row.attribute_image}
            alt=""
          />
          <span className="text-xs truncate max-w-[100px]">
            {selectLanguage == "ar" && row?.product_name_ar
              ? row?.product_name_ar
              : row?.product_name}
          </span>{" "}
        </div>
      ),
      sortable: true,
    },
    // {
    //   name: `${t("Url")}`,
    //   selector: (row) => row.attribute_url,
    //   sortable: true,
    // },
    // {
    //   name: `${t("Description")}`,
    //   selector: (row) =>
    //     selectLanguage == "ar" && row.attribute_description_ar
    //       ? row.attribute_description_ar
    //       : row.attribute_description,
    //   sortable: true,
    // },
    {
      name: `${t("vd_pc_C_Options")}`,
      sortable: true,
      cell: (item) => (
        <div className="flex gap-2">
          <label
            onClick={() => selectItemToEdit(item.attribute_id)}
            htmlFor="my-modal-4"
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:text-white cursor-pointer duration-300 modal-button"
            title={`${t("Edit")}`}
          >
            <AiFillEdit />
          </label>
          <span
            onClick={() => deleteItem(item.attribute_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);

    const getAllAttributes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallattribute`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      let response = await res.data;
      if (response.status_code === 200) {
        setAttributes(response.data);
      } else {
        setAttributes([]);
      }

      setLoading(false);
    };

    getAllAttributes();
  }, []);

  // handlers
  const onSubmit = async (data) => {
    const file = images[0]?.file;
    data["attribute_image"] = file;

    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_APIURL}/vendor/createattribute`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status_code === 200) {
          if (filteredItems) {
            filteredItems.push(res.data.data);
          } else {
            filteredItems = [];
          }
          toast.success("data saved successfully");
          setAttributes([...attributes, res.data.data]);
          setLoading(false);
          reset();
        }
      })
      .catch((Err) => {
        console.log(Err);
      });
  };

  const deleteItem = async (id) => {
    confirmAlert({
      title: <p className="text-3xl py-2">{t("delete_alert_title")}</p>,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            let response = await deleteDataById("vendor/deleteattribute", id);
            if (response.status_code === 200) {
              toast.success("data deleted successfully");
              const newAttributeList = attributes.filter(
                (item) => item.attribute_id !== id
              );
              setAttributes(newAttributeList);
              filteredItems = filteredItems.filter(
                (item) => item.attribute_id !== id
              );
            } else {
              toast.error(response.message);
            }
            setLoading(false);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const [showEdit, setShowEdit] = useState(false);
  const selectItemToEdit = async (id) => {
    let response = await getDataById("vendor/getbyidattribute", id);
    if (response.data) {
      setSelectedItem(response.data);
      setShowEdit(true);
    } else {
      toast.error(response.message || response.err);
    }
    setModal(true);
  };

  const submitEditForm = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("id", selectedItem.attribute_id);
    data.append("attribute_type", selectedItem.attribute_type);
    data.append("attribute_description", selectedItem.attribute_description);
    data.append(
      "attribute_description_ar",
      selectedItem.attribute_description_ar
    );
    data.append("attribute_url", selectedItem.attribute_url);
    const file = images[0]?.file;
    if (file) {
      data.append("attribute_image", file);
    }

    let response = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/updateattribute`,
      data,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    response = await response.data;
    if (response.status_code === 200) {
      const updatedList = attributes.map((c) => {
        if (c.attribute_id === selectedItem.attribute_id) {
          return response.data;
        }
        return c;
      });

      setAttributes(updatedList);

      filteredItems = filteredItems.map((c) => {
        if (c.attribute_id === selectedItem.attribute_id) {
          return response.data;
        }
        return c;
      });

      setSelectedItem({});
      setShowEdit(false);
      setModal(false);
      toast.success("Updated Successfully");
    } else {
      toast.error(response.message || response.err);
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
        {t("slider_banner")}{" "}
      </h2>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div>
            <form
              className="flex flex-col gap-4 mt-5"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">
                    {t("Type")} &nbsp;
                    <b>
                      {type === "slider"
                        ? "(Size must be 1900 X 450)"
                        : "(Size must be 360 X 230)"}
                    </b>
                  </span>
                </label>
                <select
                  {...register("attribute_type")}
                  className="w-full h-[40px] input input-bordered rounded"
                  placeholder="0"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option value="slider">{t("Slider")}</option>
                  <option value="banner">{t("Banner")}</option>
                </select>
              </div>
              {/* <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text"> {t("Description")}</span>
                </label>
                <input
                  {...register("attribute_description")}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                  required
                />
              </div> */}

              {/* <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">
                    {" "}
                    {t("Description")} {t("in Arabic")}
                  </span>
                </label>
                <input
                  {...register("attribute_description_ar")}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
              </div> */}

              {/* <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text"> {t("Url")}</span>
                </label>
                <input
                  {...register("attribute_url")}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
              </div> */}

              <div className="form-group mb-5">
                <div className="form-control w-full max-w-lg">
                  <label className="label">
                    <span className="label-text"> {t("Image")}</span>
                  </label>

                  <div className="col-span-12">
                    <ImageUploading
                      value={images}
                      onChange={onChange}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <p
                            className="border text-sm cursor-pointer py-2 px-3"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {t("add_new_product_T_img_info")}
                          </p>
                          &nbsp;
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image-item__btn-wrapper">
                                {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                <button
                                  className="text-red-600"
                                  onClick={() => onImageRemove(index)}
                                >
                                  X
                                </button>
                              </div>
                              <img
                                className="w-20 h-20"
                                src={image["data_url"]}
                                alt=""
                              />
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>

              <button className="btn btn-primary max-w-lg rounded-none text-white">
                {t("vd_pc_btn")}
              </button>
            </form>
          </div>
          <div className="col-span-2 md:p-5">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                pagination
                noDataComponent={
                  <p className="py-8 text-gray-400 text-center">
                    {t("No records")}
                  </p>
                }
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        open={showEdit}
        onClose={() => setShowEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="my-4"
      >
        <Box sx={style} className="w-11/12 max-w-[32rem] overflow-y-scroll">
          {modal && (
            <div>
              <h3 className="text-lg font-bold">{t("vd_pc_btn2")} </h3>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <form className="flex flex-col gap-4" onSubmit={submitEditForm}>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text"> {t("coupon_type")}</span>
                    </label>
                    <select
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          attribute_type: e.target.value,
                        })
                      }
                      className="w-full h-[40px] input input-bordered rounded"
                      placeholder="0"
                    >
                      <option value="slider">{t("Slider")}</option>
                      <option value="banner">{t("Banner")}</option>
                    </select>
                  </div>
                  {/* <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("Description")}</span>
                    </label>
                    <input
                      value={selectedItem?.attribute_description}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          attribute_description: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                    />
                  </div> */}

                  {/* <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">
                        {t("Description")} {t("in Arabic")}
                      </span>
                    </label>
                    <input
                      value={selectedItem?.attribute_description_ar}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          attribute_description_ar: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                    />
                  </div> */}

                  {/* <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("Url")}</span>
                    </label>
                    <input
                      value={selectedItem?.attribute_url}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          attribute_url: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                    />
                  </div> */}
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                      <div className="col-span-4">
                        <label className="text-[13px]" htmlFor="vendorPhoto">
                          {t("Photo")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <ImageUploading
                          value={images}
                          onChange={onChange}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                              <p
                                className="border text-sm cursor-pointer py-2 px-3"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                {t("add_new_product_T_img_info")}
                              </p>
                              &nbsp;
                              {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                              {images.length > 0
                                ? imageList.map((image, index) => (
                                    <div key={index} className="image-item">
                                      <div className="image-item__btn-wrapper">
                                        {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                        <button
                                          className="text-red-600"
                                          onClick={() => onImageRemove(index)}
                                        >
                                          X
                                        </button>
                                      </div>
                                      <img
                                        className="w-20 h-20"
                                        src={image["data_url"]}
                                        alt=""
                                      />
                                    </div>
                                  ))
                                : selectedItem?.attribute_image && (
                                    <div className="image-item">
                                      <img
                                        className="w-20 h-20"
                                        src={selectedItem?.attribute_image}
                                        alt=""
                                      />
                                    </div>
                                  )}
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  </div>

                  <button
                    type="submit"
                    className={`btn btn-primary max-w-lg rounded-none text-white ${
                      loading && "loading"
                    }`}
                  >
                    {t("vd_pc_btn2")}
                  </button>
                </form>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Attribute;
