import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Header from "../../components/Header/Header";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { domainProvider } from "../../contexts/DomainContext";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import Aos from "aos";

const VendorForgetPass = () => {
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    var user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (user?.vendor_id) {
      navigate("/vendor/dashboard");
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/reset_password_link`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const response = await res.data;

    if (response.code === 200) {
      setMessage(response.message);
      toast.success(response.message, {
        autoClose: 10000,
      });
    } else if (response.code === 400) {
      setMessage(response.message);
      toast.error(response.message);
    } else {
      setMessage(response.err || response.message);
      toast.error(response.err || response.message);
    }
    setLoading(false);
  };

  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const [ganarel, setGanarel] = useState([]);
  const { customdomain, titleName } = useContext(domainProvider);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Forget Password - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header />
      <div className=" min-h-[100vh] flex flex-col">
        <section className="px-2 md:px-5 lg:px-20 py-5" style={{ flexGrow: 1 }}>
          <div className="container place-content-center mx-auto">
            <div className="registration-form max-w-md mx-auto  px-2 md:px-8">
              <form
                className="flex flex-col gap-y-4 card border shadow py-5 justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h2 className="text-center text-xl md:text-2xl mb-6">
                  {selectLanguage === "ar"
                    ? "هل نسيت كلمة المرور ؟"
                    : "Forgot Your password?"}
                </h2>
                {/* {
                                message && <span className="text-green-600 text-sm">{message}</span>
                            } */}
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text"> {t("l_email")}</span>
                  </label>
                  <input
                    {...register("email", { required: true })}
                    type="email"
                    placeholder={t("l_phone_m")}
                    className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                    required
                  />
                </div>
                <div className="form-control w-full">
                  <button
                    type="submit"
                    className={`btn cursor-pointer btn-primary text-white w-full  rounded-none ${
                      loading && "loading"
                    }`}
                  >
                    {selectLanguage === "ar" ? "إعادة ضبط" : "Reset"}
                  </button>
                </div>
              </form>
              <div className="flex flex-col w-full">
                <Link to="/vendor/login" className="divider text-primary">
                  {selectLanguage === "ar"
                    ? "العودة إلى تسجيل الدخول"
                    : "Back to Login"}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default VendorForgetPass;
