import React, { useContext, useEffect, useState } from "react";
import { MoyasarPayment } from "./StoreComponents/MoyasarPayment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import i18next, { t } from "i18next";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "../../components/utils/CustomerInterceptor";

const StorePayment = () => {
  // recieve data from state
  const storeName = window.location.pathname.split("/")[1];
  const navigate = useNavigate();
  const [payment_method, setPaymentMethod] = useState(0);
  const customer = JSON.parse(localStorage.getItem("customer"));
  const selectLanguage = i18next.language;
  const { customdomain } = useContext(domainProvider);

  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    let transaction_id = params.get("id");
    let transaction_status = params.get("status");
    let orderDetails = JSON.parse(localStorage.getItem("orderDetails"));
    setPaymentMethod(parseInt(orderDetails?.payment_method));
    let order_id =
      orderDetails && orderDetails.order_id ? orderDetails.order_id : 0;

    if (transaction_id !== undefined && transaction_status === "paid") {
      let data = {
        order_id: order_id,
        payment_status: transaction_status,
        transaction_id,
      };
      let res = null;
      const response = async () => {
        const resp = await axios.patch(
          `${process.env.REACT_APP_APIURL}/vendor/store/customer/updateorder`,
          JSON.stringify(data),
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${customer ? customer.token : ""}`,
            },
          }
        );
        res = await resp.data;
        if (res && res.status_code === 200) {
          localStorage.removeItem("orderDetails");
          toast.success("order has been created successfully");
          if (customdomain === "aitech.com.sa") navigate(`/${storeName}`);
          else navigate(`/`);
        }
      };
      response();
    } else if (
      transaction_id !== undefined &&
      transaction_status === "failed"
    ) {
      let data = {
        order_id: order_id,
        payment_status: transaction_status,
        transaction_id,
      };
      let res = null;
      const response = async () => {
        const resp = await axios.patch(
          `${process.env.REACT_APP_APIURL}/vendor/store/customer/updateorder`,
          JSON.stringify(data),
          {
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${customer ? customer.token : ""}`,
            },
          }
        );
        res = await resp.data;

        if (res && res.status_code === 200) {
          localStorage.removeItem("orderDetails");
          localStorage.removeItem("cart");
          toast.error("Payment Failed But your order has been created");
          if (customdomain === "aitech.com.sa") navigate(`/${storeName}`);
          else navigate(`/`);
        }
      };
      response();
    }
  }, []);

  return (
    <>
      <div className="flex flex-col min-h-[100vh]">
        <ShopNavbar />
        <div className="px-5 mx-auto md:px-24" style={{ flexGrow: 1 }}>
          <div className="py-4 container flex gap-3 items-center">
            <a
              href={storeName ? `/${storeName}` : "/"}
              className="text-primary text-base"
            >
              <i className="fas fa-home"></i>
            </a>
            <span className="text-sm text-gray-400">
              <i className="fas fa-chevron-right"></i>
            </span>
            <p className="text-gray-600 font-medium uppercase">
              {t("Payment")}
            </p>
          </div>
          {payment_method === 2 ? (
            <MoyasarPayment />
          ) : (
            <div className="text-center my-3">
              <h4>
                {selectLanguage === "ar"
                  ? "هذا البوابة للدفع غير مدمجة حاليًا"
                  : "This Payment Gateway Is Not Integrated Right Now "}
              </h4>
              <p>
                {selectLanguage === "ar"
                  ? "لا تقلق، تم تسجيل طلبك بنجاح"
                  : "Don't Worry Your Order has been Registered Successfully"}
              </p>
            </div>
          )}
        </div>
        <StoreFooter />
      </div>
    </>
  );
};
export default StorePayment;
