const updateData = async (url, data) => {
  const user = JSON.parse(localStorage.getItem("admin"));

  const baseUrl = process.env.REACT_APP_APIURL;

  const res = await fetch(`${baseUrl}/${url}`, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  });

  const response = await res.json();

  return response;
};

export default updateData;
