import { useContext, useEffect, useState } from "react";
import { domainProvider } from "../../../contexts/DomainContext";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { BsCart2 } from "react-icons/bs";
import axios from "axios";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1150 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1150, min: 760 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 760, min: 0 },
    items: 1,
  },
};

export default function RelatedProduct(props) {
  var id = props.productId;
  const { customdomain } = useContext(domainProvider);
  const [products, setProducts] = useState([]);
  const selectLanguage = i18next.language;
  var storeName;

  useEffect(() => {
    const api = `${process.env.REACT_APP_APIURL}/vendor/store/getAllSuggestedProducts/${id}`;
    const fetchTopProducts = async () => {
      var res = await axios.get(api);
      const response = await res.data;
      if (response.status_code === 200) {
        if (response.data.length > 0) {
          setProducts(response.data);
        }
      }
    };
    fetchTopProducts();
  }, [id]);

  return (
    <>
      <div className="Container">
        <div className="px-6 pb-12 pt-4">
          <p className="text-3xl text-gray-700 font-medium mb-4">Related Products</p>
          <Carousel autoPlay={true} interval={1000} responsive={responsive}>
            {products && products?.map((item, idx) => (
              <div
                className="single_product relative cursor-pointer bg-gray-200 pb-4 shadow-sm rounded-sm hover:shadow-md hover:scale-[1.01] ease-linear duration-300 w-full max-w-[360px] align-self-center"
                key={idx}
              >
                <div className="absolute top-0 right-0 p-3 bg-gray-200 hover:bg-black hover:text-primary heart ease-in-out transition-all duration-300">
                  <p>{item.product_price} ر.س</p>
                </div>
                <div className="w-full h-[320px]">
                  <Link
                    to={`${
                      customdomain === "aitech.com.sa"
                        ? `/${storeName}/product_details/${item.product_id}`
                        : `/product_details/${item.product_id}`
                    }`}
                    className="text-center text-gray-400"
                  >
                    <img
                      className="w-full h-full object-cover"
                      src={item.thumbnail_image}
                      alt={
                        selectLanguage == "ar" && item.product_name_ar
                          ? item.product_name_ar
                          : item.product_name
                      }
                    />
                  </Link>
                </div>
                <div className="">
                  <h2 className="card-title justify-center text-gray-400 text-[14px] my-3 mx-1">
                    <Link
                      to={`${
                        customdomain === "aitech.com.sa"
                          ? `/${storeName}/product_details/${item.product_id}`
                          : `/product_details/${item.product_id}`
                      }`}
                      className="text-gray-600 text-base capitalize text-center productCard"
                    >
                      {selectLanguage == "ar" && item.product_name_ar
                        ? item.product_name_ar
                        : item.product_name}
                    </Link>
                  </h2>
                  <div className="card-actions justify-center items-center flex-col lg:overflow-hidden lg:h-[20px]">
                    <div className="price text-center">
                      <p>{item.product_price} ر.س</p>
                      <Link
                        to={`${
                          customdomain === "aitech.com.sa"
                            ? `/${storeName}/product_details/${item.product_id}`
                            : `/product_details/${item.product_id}`
                        }`}
                        className="transition-all ease-in duration-300 text-blue-700 hover:text-blue-700 flex items-center"
                      >
                        <BsCart2
                          className={`inline mr-2 ${
                            selectLanguage == "ar" ? "ml-3" : "ml-0"
                          } `}
                        />
                        {selectLanguage == "ar"
                          ? "إضافة إلى السلة"
                          : "Add to cart"}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </>
  );
}
