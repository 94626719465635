import React from "react";
import "./footer.css";
import Secure from "../Secure";
import Footer from "./Footer";

const Footer2 = () => {

  return (
    <div className="">
      <div className="container mx-auto max-w-[1280px] py-8 px-5 md:px-10 mb-6">
        <Secure />
      </div>
      <Footer />
    </div>
  );
};

export default Footer2;
