import React from 'react';
import SectionTitle from '../utils/SectionTitle';
import Div from '../utils/Div';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const News = () => {

    const {t} = useTranslation();
    const selectLanguage = i18next.language;

    const reviewsData = [
        {
            name: t("news_title1") ,
            post: {
                by: t("admin"),
                date: "20 August, 2023"
            },
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgsUhzbpaXgwvzmNAcyM_hb9zLenUQKpneeg&usqp=CAU",
            description: t("news_desc")
        },
        {
            name: t("news_title2"),
            post: {
                by: t("admin"),
                date: "20 August, 2023"
            },
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTJmkXhXVzTDnfSaqf3aGkot-utDLCS6rbgXg&usqp=CAU",
            description: t("news_desc")
        },
        {
            name: t("news_title3"),
            post: {
                by: t("admin"),
                date: "20 August, 2023"
            },
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnVtqW81wc8-SxyOapaxQwowgzkWUWirmMnQ&usqp=CAU",
            description: t("news_desc")
        }
    ]

    return (
        <section id="review" className="px-2">
            <Div>
                <div className="py-10">
                    <div className="pb-7">
                        <SectionTitle page={'news'} />
                    </div>


                    <div className=" container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 gap-x-16 px-3 mx-auto">
                        {
                            reviewsData?.map((review, index) =>
                                <div key={index} className=" rounded-lg font-poppin  text-[#2E2E2E] gap-y-4 mb-10 duration-300 relative">
                                    <div className='w-full h-48'>
                                        <figure><img className='w-full h-48 rounded-lg' src={review.image} alt="images" /></figure>
                                    </div>
                                    <div className='flex justify-between items-center mt-1'>
                                        <div className='font-semibold'>
                                            <span>{selectLanguage === "ar" ?" نشر بواسطة"
:"Post by"}</span>
                                            : {review.post.by}
                                        </div>
                                        <div className='font-semibold'>
                                            {review.post.date}
                                        </div>
                                    </div>
                                    <p className="my-5 h-20 leading-7 text-[25px] font-semibold font-poppin text-[#2E2E2E]">{review.name}</p>
                                    <p className="text-sm  text-[#2E2E2E] font-poppin text-justify">{review.description}</p>


                                    <p className='text-[18px] mt-5 font-poppin font-bold text-[#236260]'>{t("read_more")} &#62;</p>

                                </div>
                            )
                        }

                    </div>
                </div>
            </Div>
        </section>
    );
};

export default News;