import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import axios from "../../../components/utils/Interceptors"

const MoyaserForPackageUp = () => {
  let callback_url = `https://aitech.com.sa/vendor/packages/upgrade-package/thankyou`;
  // let callback_url = `http://localhost:3000/vendor/packages/upgrade-package/thankyou`;

  let packageDetails = null;
  const [key, setKey] = useState();
  useEffect(() => {
    const getAitechDetails = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`
      );
      const response = await res.data;
      if (response.data) {
        setKey(response.data[0].moyasar_publishable_key);
      }
    };
    getAitechDetails();
  });

  useEffect(() => {
    packageDetails = JSON.parse(localStorage.getItem("vendorPackagesInfo"));
    let amount =
      packageDetails && packageDetails.plan_price
        ? packageDetails.plan_price
        : 0;
    let plan_id =
      packageDetails && packageDetails.payment_mode
        ? packageDetails.payment_mode
        : 0;
    let plan_description = "plan_id" + plan_id;
    window.Moyasar.init({
      element: ".mysr-form",
      amount: amount * 100,
      currency: "SAR",
      description: plan_description,
      publishable_api_key: key
        ? key
        : `${process.env.REACT_APP_MOYASAR_PUBLISHABLE_KEY}`,
      callback_url: callback_url,
      methods: ["creditcard", "stcpay", "applepay"],
      apple_pay: {
        country: "SA",
        label: "Awesome Cookie Store",
        validate_merchant_url: "https://api.moyasar.com/v1/applepay/initiate",
      },
      on_completed: function (payment) {},
    });
  }, []);
  return (
    <div>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">
          Package Update
        </h2>
        <Link to={"/vendor/packages"}>
          <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
            <FaArrowCircleLeft size={15} />
            Cancel
          </button>
        </Link>
      </div>
      <div className="mysr-form" />
    </div>
  );
};

export default MoyaserForPackageUp;
