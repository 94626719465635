import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer2 from "../components/Footer/Footer2";
import Header from "../components/Header/Header";
import SectionTitle from "../components/utils/SectionTitle";
import about from "../assets/images/about.png";
import Div from "../components/utils/Div";
import { GoPrimitiveDot } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../contexts/DomainContext";
import Whatsapp from "../components/utils/WhatsappIcon";
import axios from "axios";
import Aos from "aos";

const AboutUs = () => {
  const { t } = useTranslation();
  const [ganarel, setGanarel] = useState([]);
  const { customdomain, titleName } = useContext(domainProvider);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  return (
    <div className="bg-[#F7F7F7]">
      <Helmet>
        <title>About Us - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header navigation={true} />

      <Div id="privacyPolicy">
        <div className="pb-7 pt-9">
          <SectionTitle page="aboutUs" />
        </div>

        <div className="flex justify-between flex-col md:flex-row">
          <div className="rounded-sm">
            <img className="rounded-sm w-11/12" src={about} alt="about" />
          </div>
          <div className="py-3 md:px-5 ">
            <p className="text-[20px] text-[#236260]">
              <span className="font-bold"> {t("about_head1")} : </span>{" "}
              <span className="font-semibold">{t("about_head2")}</span>
            </p>

            <p className="text-[15px] text-justify"> {t("about_content1")}</p>
            <p className="text-[15px] text-justify"> {t("about_content2")}</p>
          </div>
        </div>
        <div className="my-5 md:my-10 px-2 md:px-0">
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title1")} : {t("about_desc1")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title2")} : {t("about_desc2")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title3")} : {t("about_desc3")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title4")} : {t("about_desc4")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title5")}: {t("about_desc5")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title6")} : {t("about_desc6")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title7")} : {t("about_desc7")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title8")} : {t("about_desc8")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title9")} : {t("about_desc9")}
            </span>
          </p>
          <p className="flex mb-3">
            <span className="mr-2 text-[#236260]">
              <GoPrimitiveDot size={20} />
            </span>
            <span className="text-[15px] text-justify">
              {t("about_title10")} : {t("about_desc10")}
            </span>
          </p>
          <p className="text-[15px] text-justify font-semibold">
            {t("about_end1")}
          </p>
          <p className="text-[15px] text-justify font-semibold">
            {t("about_end2")}
          </p>
        </div>
        <Whatsapp />
      </Div>

      <Footer2 />
    </div>
  );
};

export default AboutUs;
