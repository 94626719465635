import React from "react";
import coupon1 from "../../assets/guide/coupon1.jpg";
import coupon2 from "../../assets/guide/coupon2.jpg";
import coupon1Ar from "../../assets/guide/coupon1-ar.jpg";
import coupon2Ar from "../../assets/guide/coupon2-ar.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const GuideCoupons = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();

  return (
    <>
      <div>
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
          {selectLanguage === "ar" ? "القسائم" : "Coupons"}
        </h1>
        <ul className="list-disc">
          <li className="text-lg text-gray-600 my-3">
            <b>{t("Coupons")} : </b>{" "}
            {selectLanguage === "ar"
              ? " انتقل إلى قسم القسائم ويمكنك إنشاء رمز قسيمة هنا بالنقر فوق "
              : "Go to coupon section and You can generate a coupon code here by clicking on "}
            <b>{t("coupon_al_add_c")}</b>.
            <img
              src={selectLanguage === "ar" ? coupon1Ar : coupon1}
              className="w-full md:w-[70%] m-auto my-2"
            />
          </li>
          <li className="text-lg text-gray-600 my-3">
            <b>{t("coupon_al_add_c")} : </b>{" "}
            {selectLanguage === "ar"
              ? " بعد النقر فوق إضافة قسيمة جديدة، ستوجه إلى صفحة قسيمة جديدة لإنشاء قسيمة "
              : "After clicking on Add New Coupon you will direct to new Coupon page to create your coupon and "}
            <b>{t("Save")}</b>.
            <img
              src={selectLanguage === "ar" ? coupon2Ar : coupon2}
              className="w-full md:w-[70%] m-auto my-2"
            />
            <b>{t("Following are the steps")} :</b>
            <ol className="list-decimal">
              <li className="text-lg text-gray-600 my-3">
                {" "}
                <b>{t("add_coupon_code")} : </b>{" "}
                {selectLanguage === "ar"
                  ? "قم بإنشاء رمز فريد لا ينسى سيدخله العملاء أثناء السحب لتطبيق الخصم."
                  : "Create a unique and memorable code that customers will enter during checkout to apply the discount."}
              </li>
              <li className="text-lg text-gray-600 my-3">
                {" "}
                <b>{t("Type of Coupon")} : </b>{" "}
                {selectLanguage === "ar"
                  ? "حدد نوع القسيمة التي تريد إنشاؤها. وتشمل الأنواع الشائعة النسبة المئوية أو المبلغ."
                  : "Decide on the type of coupon you want to create. Common types include Percentage or Amount."}
              </li>
              <li className="text-lg text-gray-600 my-3">
                {" "}
                <b>
                  {selectLanguage === "ar"
                    ? "مبلغ الخصم أو النسبة المئوية"
                    : "Discount Amount or Percentage"}{" "}
                  :{" "}
                </b>{" "}
                {selectLanguage === "ar"
                  ? "حدد قيمة الخصم، سواء كانت نسبة مئوية من إجمالي الأمر أو مبلغا نقديا ثابتا."
                  : "Define the discount value, whether it's a percentage off the total order or a fixed monetary amount."}
              </li>
              <li className="text-lg text-gray-600 my-3">
                {" "}
                <b>{t("add_coupon_min_shopping")} : </b>{" "}
                {selectLanguage === "ar"
                  ? "حدد بشكل إختياري الحد الأدنى لمبلغ الشراء ليكون القسيمة صالحة. وهذا يشجع العملاء على إنفاق المزيد للاستفادة من الخصم."
                  : "Optionally, set a minimum purchase amount for the coupon to be valid. This encourages customers to spend more to avail of the discount."}
              </li>
              <li className="text-lg text-gray-600 my-3">
                {" "}
                <b>{t("coupon_date")} : </b>{" "}
                {selectLanguage === "ar"
                  ? "أختر تاريخ انتهاء صلاحية كود القسيمة. وهذا يضيف شعورا بالحاجة الملحة، ويحفز العملاء على إجراء عملية شراء في وقت أقرب"
                  : "Choose a date for the coupon code to expire. This adds a sense of urgency, motivating customers to make a purchase sooner"}
              </li>
            </ol>
          </li>
        </ul>
      </div>
    </>
  );
};

export default GuideCoupons;
