import React, { useEffect, useState, useContext, useRef } from "react";
import { BsSearch } from "react-icons/bs";
import { BsCart2 } from "react-icons/bs";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import { Link, useLocation } from "react-router-dom";
import { userProvider } from "../../contexts/UserContext";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "axios";
import "./style.css"

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [dummyProducts, setDummyProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isVendor } = useContext(userProvider);
  const [range, setRange] = useState(0);
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [dbTags, setdbTags] = useState([]);
  const [dbSizes, setdbSizes] = useState([]);
  const [productHead, setProducthead] = useState(false);
  const { state } = useLocation();
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  const storeName = window.location.href.split("/")[3];

  // custom domain
  const { customdomain, titleName } = useContext(domainProvider);

  useEffect(() => {
    const getAllProducts = async () => {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts/${storeName}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts`,
          {
            headers: {
              domain_name: customdomain,
              "Content-Type": "application/json",
            },
          }
        );
      }
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        setProducts(response.data);
        setAllProducts(response.data);
      }
      if (response.status_code === 200 && response.dummyData)
        setDummyProducts(response.dummyData);
      setLoading(false);
    };

    const getBySearch = async () => {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/searchitems/${storeName}?search_term=${state?.searchValue}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/searchitems?search_term=${state?.searchValue}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
      const response = await res.data;
      if (response) {
        if (response.status_code === 200 && response.data) {
          setProducts(response.data);
          setAllProducts(response.data);
        }
      }
    };

    const getAllCategories = async () => {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstorecategory/${storeName}`
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstorecategory`,
          {
            headers: {
              domain_name: customdomain,
            },
          }
        );
      }
      const response = await res.data;
      if (response.data) {
        const categoryData = response.data.map((category) => {
          const obj = {
            name:
              selectLanguage === "ar" && category.category_name_ar
                ? category.category_name_ar
                : category.category_name,
            value: category.category_id,
          };
          return obj;
        });

        setCategories(categoryData);
      } else {
        setCategories([]);
      }
    };

    const getAllColors = async () => {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstorecolor/${storeName}`
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstorecolor`,
          {
            headers: {
              domain_name: customdomain,
            },
          }
        );
      }
      const response = await res.data;
      if (response.data) {
        setColors(response.data);
      }
    };

    const getAllTags = async () => {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstoretag/${storeName}`
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstoretag`,
          {
            headers: {
              domain_name: customdomain,
            },
          }
        );
      }
      const response = await res.data;
      if (response.data) {
        const allData = response.data.map((tag) => {
          const obj = {
            label:
              selectLanguage === "ar" && tag.tag_name_ar
                ? tag.tag_name_ar
                : tag.tag_name,
            value: tag.tag_id,
          };
          return obj;
        });

        setdbTags(allData);
      }
    };

    const getAllSize = async () => {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstoresize/${storeName}`
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallstoresize`,
          {
            headers: {
              domain_name: customdomain,
            },
          }
        );
      }
      const response = await res.data;
      if (response.data) {
        const allData = response.data.map((size) => {
          const obj = {
            label:
              selectLanguage === "ar" && size.size_name_ar
                ? size.size_name_ar
                : size.size_name,
            value: size.size_id,
          };
          return obj;
        });
        setdbSizes(allData);
      }
    };

    getAllProducts();
    if (state?.searchValue) {
      getBySearch();
    }
    getAllCategories();
    getAllColors();
    getAllTags();
    getAllSize();
  }, [customdomain, selectLanguage]);

  // this for search
  const handleToSearch = async (e) => {
    e.preventDefault();
    const form = e.target;
    const value = form.value.value;
    var res;
    if (customdomain === "aitech.com.sa") {
      res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/store/searchitems/${storeName}?search_term=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/store/searchitems?search_term=${value}`,
        {
          headers: {
            "Content-Type": "application/json",
            domain_name: customdomain,
          },
        }
      );
    }
    const response = await res.data;
    if (response) {
      if (response.status_code === 200 && response.data) {
        setProducts(response.data);
        setAllProducts(response.data);
      } else {
        toast.error(response.message);
        setProducts(null);
        setAllProducts(null);
        form.reset();
      }
    }
  };

  // this section for select color
  const [productColor, setProductColor] = useState({
    colors: [],
    store: [],
  });
  const handleColorChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { colors } = productColor;

    // Case 1 : The user checks the box
    if (checked) {
      setProductColor({
        colors: [...colors, value],
        store: [...colors, value],
      });
    }

    // Case 2 : The user unchecks the box
    else {
      setProductColor({
        colors: colors.filter((e) => e !== value),
        store: colors.filter((e) => e !== value),
      });
    }
  };

  // this is for category selector
  const [productCategory, setProductCategory] = useState({
    category: [],
    store: [],
  });
  const handleProductCategoryChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { category } = productCategory;

    // Case 1 : The user checks the box
    if (checked) {
      setProductCategory({
        category: [...category, value],
        store: [...category, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setProductCategory({
        category: category.filter((e) => e !== value),
        store: category.filter((e) => e !== value),
      });
    }
  };

  // this is for Tags selector
  const [productTags, setProductTags] = useState({
    tags: [],
    store: [],
  });
  const handleProductTagsChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { tags } = productTags;

    // Case 1 : The user checks the box
    if (checked) {
      setProductTags({
        tags: [...tags, value],
        store: [...tags, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setProductTags({
        tags: tags.filter((e) => e !== value),
        store: tags.filter((e) => e !== value),
      });
    }
  };

  const [productSize, setProductSize] = useState({
    size: [],
    store: [],
  });

  const handleProductSizeChange = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { size } = productSize;
    // Case 1 : The user checks the box
    if (checked) {
      setProductSize({
        size: [...size, value],
        store: [...size, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
      setProductSize({
        size: size.filter((e) => e !== value),
        store: size.filter((e) => e !== value),
      });
    }
  };

  const handleRange = (e) => {
    const dataRange = e * 100;
    setRange(dataRange);
  };

  const handleToFilter = async ({ type }) => {
    let amount = 0;
    if (range !== 0) {
      amount = range;
    } else {
      amount = 10000;
    }
    const category = productCategory.store.toString() || "";
    const color = productColor.store.toString() || "";
    const size = productSize.store.toString() || "";
    const tag = productTags.store.toString() || "";
    if (type === "filter") {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts/${storeName}?amount=${amount}&category=${category}&size=${size}&color=${color}&tag=${tag}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts?amount=${amount}&category=${category}&size=${size}&color=${color}&tag=${tag}`,
          {
            headers: {
              "Content-Type": "application/json",
              domain_name: customdomain,
            },
          }
        );
      }
      const response = await res.data;
      if (response) {
        if (response.status_code === 200) {
          if (response.data) {
            setProducts(response.data);
            setAllProducts(response.data);
          }
          // else if (response?.dummyData?.length === 0) {
          //   setDummyProducts(null);
          //   toast.info(response.message);
          // }
          // else if (response.dummyData.length > 0) {
          //   setDummyProducts(response.dummyData);
          //   toast.info(response.message);
          // }
           else {
            setProducthead(true);
            toast.error(response.message);
            setProducts(null);
            setAllProducts(null);
            setDummyProducts(null);
          }
        }
      }
    } else if (type === "reset") {
      handleResetActive();
      const getAllProducts = async () => {
        var res;
        if (customdomain === "aitech.com.sa") {
          res = await axios.get(
            `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts/${storeName}`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          res = await axios.get(
            `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts`,
            {
              headers: {
                "Content-Type": "application/json",
                domain_name: customdomain,
              },
            }
          );
        }
        const response = await res.data;
        if (response.status_code === 200) {
          setProducts(response?.data ? response?.data : []);
          setAllProducts(response?.data ? response?.data : []);
          setDummyProducts(response?.dummyData);
        }
        //  else if (response.status_code === 200 && response.dummyData) {
        //   setDummyProducts(response.dummyData);
        //   toast.info(response.message);
        // }
        setLoading(false);
      };
      getAllProducts();
      // reset all selected filters
      setProductCategory({ category: [], store: [] });
      setProductTags({ tags: [], store: [] });
      setProductSize({ size: [], store: [] });
      setProductColor({ colors: [], store: [] });
    }
  };

  // to show active option
  function handleActive(index, type, dim) {
    var options;
    if (type === "category")
      options = document.getElementsByClassName("categories");
    else if (type === "color")
      options = document.getElementsByClassName("colors");
    else if (type === "size")
      options = document.getElementsByClassName("sizes");
    else if (type === "tag") options = document.getElementsByClassName("tags");

    if (dim === "desktop") {
      index += options.length / 2;
    }

    for (var i = 0; i < options.length; i++) {
      if (i == index) {
        if (type === "tag") {
          if (options[i].style.color === "rgb(255, 255, 255)") {
            options[i].style.color = "#575757";
            options[i].style.backgroundColor = "#fff";
          } else {
            options[i].style.color = "#fff";
            options[i].style.backgroundColor = "#f4900c";
          }
        } else {
          if (options[i].style.color === "rgb(244, 144, 12)")
            options[i].style.color = "#575757";
          else options[i].style.color = "#f4900c";
        }
      }
      // } else {
      //   options[i].style.color = "#575757";
      // }
    }
  }

  function handleResetActive() {
    var cats = document.getElementsByClassName("categories");
    var cols = document.getElementsByClassName("colors");
    var sizes = document.getElementsByClassName("sizes");
    var tags = document.getElementsByClassName("tags");

    for (var i = 0; i < cats.length; i++) cats[i].style.color = "#575757";

    for (var i = 0; i < cols.length; i++) cols[i].style.color = "#575757";

    for (var i = 0; i < sizes.length; i++) sizes[i].style.color = "#575757";

    for (var i = 0; i < tags.length; i++) {
      tags[i].style.color = "#575757";
      tags[i].style.backgroundColor = "#fff";
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef(null);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
     <div className="flex flex-col min-h-[100vh]">
      <ShopNavbar />
      <Helmet>
        <title>Shop - {titleName}</title>
      </Helmet>

      <div className="relative pt-8" style={{flexGrow:1}}>
        <div>
          {/* mobile sidebar */}
          <div
            className={`${
              isOpen ? "translate-x-0" : "-translate-x-full"
            } absolute top-0 left-0 h-full w-64 bg-gray-100 z-50 p-4 transition-all duration-300 ease-in-out`}
            ref={drawerRef}
          >
            <div className="px-3 block md:hidden">
              <form onSubmit={handleToSearch} className="relative w-full mb-5">
                <input
                  type="text"
                  name="value"
                  placeholder="Search Products..."
                  className="bg-gray-100 w-full pl-4 pr-9 py-2 rounded-full"
                />
                <button type="submit">
                  <BsSearch className=" text-xl absolute right-3 top-3 cursor-pointer" />
                </button>
              </form>

              <div className="flex flex-col mb-5">
                <input
                  type="range"
                  min="0"
                  max="100"
                  className="range range-xs range-info focus:border-none"
                  onChange={(e) => handleRange(e.target.value)}
                />
                <div className="flex items-center">
                  <button
                    onClick={() => handleToFilter({ type: "filter" })}
                    className="rounded-full btn btn-primary btn-sm w-[80px] my-3 text-white"
                  >
                    {t("Filter")}
                  </button>
                  <button
                    onClick={() => handleToFilter({ type: "reset" })}
                    className="rounded-full btn btn-primary ml-3 btn-sm w-[80px] my-3 text-white"
                  >
                    {t("Reset")}
                  </button>
                </div>
                <p className="text-lg">
                  <span className="font-bold">
                    {t("add_new_product_price")}:
                  </span>{" "}
                  0 ر.س {range || "All"} ر.س
                </p>
              </div>
              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("Filter by color")}
                </p>
                {colors.length > 0 ? (
                  colors.map((color, idx) => (
                    <div key={idx} className="form-check m-1 flex items-center">
                      <input
                        className={`form-check-input hidden`}
                        type="checkbox"
                        value={color.color_id}
                        id={color.color_name}
                        onChange={handleColorChange}
                      />
                      <label
                        className={`colors cursor-pointer hover:text-primary duration-150 ease-in form-check-label uppercase active:text-primary`}
                        htmlFor={color.color_name}
                        onClick={() => handleActive(idx, "color", "mobile")}
                      >
                        {selectLanguage === "ar" && color.color_name_ar
                          ? color.color_name_ar.length > 10
                            ? color.color_name_ar?.slice(0, 10)
                            : color.color_name_ar
                          : color.color_name.length > 10
                          ? color.color_name?.slice(0, 10)
                          : color.color_name}
                        {color?.color_name?.length > 10 && <span>...</span>}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400">No data found</p>
                )}
              </div>

              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("add_new_product_Category")}
                </p>
                {categories.length > 0 ? (
                  categories.map((cat, idx) => (
                    <div key={idx} className="form-check m-1 flex items-center">
                      <input
                        className="form-check-input hidden"
                        type="checkbox"
                        value={cat.value}
                        id={cat.name}
                        onChange={handleProductCategoryChange}
                      />
                      <label
                        className={`categories cursor-pointer hover:text-primary duration-150 ease-in form-check-label uppercase`}
                        htmlFor={cat.name}
                        onClick={() => handleActive(idx, "category", "mobile")}
                      >
                        {cat?.name?.length > 10
                          ? cat?.name?.slice(0, 10)
                          : cat?.name}
                        {cat?.name?.length > 10 && <span>...</span>}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400">No data found</p>
                )}
              </div>

              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("all_product_dataTable1")} {t("add_new_product_Sizes")}
                </p>
                {dbSizes.length > 0 ? (
                  dbSizes.map((size, idx) => (
                    <div key={idx} className="form-check m-1 flex items-center">
                      <input
                        className="form-check-input hidden"
                        type="checkbox"
                        value={size.value}
                        id={`size${size.value}`}
                        onChange={handleProductSizeChange}
                      />
                      <label
                        className={`sizes cursor-pointer hover:text-primary duration-150 ease-in  form-check-label uppercase `}
                        htmlFor={`size${size.value}`}
                        onClick={() => handleActive(idx, "size", "mobile")}
                      >
                        {size?.label?.length > 10
                          ? size?.label?.slice(0, 10)
                          : size?.label}
                        {size?.label?.length > 10 && <span>...</span>}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400 w-full">No data found</p>
                )}
              </div>

              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("add_new_product_Tags")}
                </p>
                <div className="flex items-center flex-wrap">
                  {dbTags.length > 0 ? (
                    dbTags.map((tag, idx) => (
                      <div
                        key={idx}
                        className="form-check m-1 flex items-center"
                      >
                        <input
                          className="form-check-input hidden"
                          type="checkbox"
                          value={tag.value}
                          id={`tags${tag.value}`}
                          onChange={handleProductTagsChange}
                        />
                        <label
                          className={`tags cursor-pointer duration-150 ease-in form-check-label uppercase border px-3 py-2 hover:bg-primary hover:text-white `}
                          htmlFor={`tags${tag.value}`}
                          onClick={() => handleActive(idx, "tag", "mobile")}
                        >
                          {tag?.label?.length > 10
                            ? tag?.label?.slice(0, 10)
                            : tag?.label}
                          {tag?.label?.length > 10 && <span>...</span>}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-400">No data found</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isOpen && (
            <div
              className="fixed top-0 left-0 w-full bg-black opacity-50 z-40"
              onClick={toggleDrawer}
            ></div>
          )}
        </div>

        <div className="Container mx-auto">
          <div className={`px-5 pt-4 block md:hidden`}>
            <p
              onClick={toggleDrawer}
              className="rounded-md btn btn-primary btn-sm text-white"
            >
              {t("Filter")}
            </p>
          </div>
          <div className="shop_container px-5 py-5 relative">
            {/* sidebar in desktop view */}
            <div className={`px-3 hidden md:block w-11/12`}>
              <form onSubmit={handleToSearch} className="relative w-full mb-8">
                <input
                  type="text"
                  name="value"
                  placeholder="Search Products..."
                  className="bg-gray-100 w-full px-4 py-3 rounded-full"
                />
                <button type="submit">
                  <BsSearch className=" text-xl absolute right-3 top-3 cursor-pointer" />
                </button>
              </form>

              <div className="flex flex-col mb-5">
                <p className="mb-3 text-xl text-black">
                  {t("Filter by price")}
                </p>
                <input
                  type="range"
                  min="0"
                  max="100"
                  className="h-[2px] mb-1 border-0 bg-black focus:border-none"
                  onChange={(e) => handleRange(e.target.value)}
                />
                {/* <p> 10 ر.س — 20 ر.س </p> */}
                <div className="flex items-center mt-2">
                  <button
                    onClick={() => handleToFilter({ type: "filter" })}
                    className={`rounded-full btn btn-primary btn-sm w-[80px] my-2 text-white ${
                      selectLanguage === "ar" ? "ml-3" : " ml-0"
                    }`}
                  >
                    {t("Filter")}
                  </button>
                  <button
                    onClick={() => handleToFilter({ type: "reset" })}
                    className="rounded-full btn btn-primary ml-3 btn-sm w-[80px] my-2 text-white"
                  >
                    {t("Reset")}
                  </button>
                </div>
                <p className="text-md">
                  <span className="font-semibold">
                    {t("add_new_product_price")}:
                  </span>{" "}
                  0 ر.س {range || "All"} - ر.س
                </p>
              </div>

              {/* this is filter by color  */}
              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("Filter by color")}
                </p>
                {colors.length > 0 ? (
                  colors.map((color, idx) => (
                    <div key={idx} className="form-check m-1 flex items-center">
                      <input
                        className={`form-check-input hidden`}
                        type="checkbox"
                        value={color.color_id}
                        id={color.color_name}
                        onChange={handleColorChange}
                      />
                      <label
                        className={`colors cursor-pointer hover:text-primary duration-150 ease-in form-check-label uppercase active:text-primary`}
                        htmlFor={color.color_name}
                        onClick={() => handleActive(idx, "color", "desktop")}
                      >
                        {selectLanguage === "ar" && color.color_name_ar
                          ? color.color_name_ar.length > 10
                            ? color.color_name_ar?.slice(0, 10)
                            : color.color_name_ar
                          : color.color_name.length > 10
                          ? color.color_name?.slice(0, 10)
                          : color.color_name}
                        {color?.color_name?.length > 10 && <span>...</span>}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400">No data found</p>
                )}
              </div>

              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("add_new_product_Category")}
                </p>
                {categories.length > 0 ? (
                  categories.map((cat, idx) => (
                    <div key={idx} className="form-check m-1 flex items-center">
                      <input
                        className="form-check-input hidden"
                        type="checkbox"
                        value={cat.value}
                        id={cat.name}
                        onChange={handleProductCategoryChange}
                      />
                      <label
                        className={`categories cursor-pointer hover:text-primary duration-150 ease-in form-check-label uppercase`}
                        htmlFor={cat.name}
                        onClick={() => handleActive(idx, "category", "desktop")}
                      >
                        {cat?.name?.length > 10
                          ? cat?.name?.slice(0, 10)
                          : cat?.name}
                        {cat?.name?.length > 10 && <span>...</span>}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400">No data found</p>
                )}
              </div>

              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("all_product_dataTable1")} {t("add_new_product_Sizes")}
                </p>
                {dbSizes.length > 0 ? (
                  dbSizes.map((size, idx) => (
                    <div key={idx} className="form-check m-1 flex items-center">
                      <input
                        className="form-check-input hidden"
                        type="checkbox"
                        value={size.value}
                        id={`size${size.value}`}
                        onChange={handleProductSizeChange}
                      />
                      <label
                        className={`sizes cursor-pointer hover:text-primary duration-150 ease-in  form-check-label uppercase `}
                        htmlFor={`size${size.value}`}
                        onClick={() => handleActive(idx, "size", "desktop")}
                      >
                        {size?.label?.length > 10
                          ? size?.label?.slice(0, 10)
                          : size?.label}
                        {size?.label?.length > 10 && <span>...</span>}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-400 w-full">No data found</p>
                )}
              </div>

              <div className="flex flex-col my-9">
                <p className="mb-3 text-xl text-black">
                  {t("add_new_product_Tags")}
                </p>
                <div className="flex items-center flex-wrap">
                  {dbTags.length > 0 ? (
                    dbTags.map((tag, idx) => (
                      <div
                        key={idx}
                        className="form-check m-1 flex items-center"
                      >
                        <input
                          className="form-check-input hidden"
                          type="checkbox"
                          value={tag.value}
                          id={`tags${tag.value}`}
                          onChange={handleProductTagsChange}
                        />
                        <label
                          className={`tags cursor-pointer duration-150 ease-in form-check-label uppercase border px-3 py-2 hover:bg-primary hover:text-white `}
                          htmlFor={`tags${tag.value}`}
                          onClick={() => handleActive(idx, "tag", "desktop")}
                        >
                          {tag?.label?.length > 10
                            ? tag?.label?.slice(0, 10)
                            : tag?.label}
                          {tag?.label?.length > 10 && <span>...</span>}
                        </label>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-400">No data found</p>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full">
              <div className="flex justify-start pt-2 pb-5 mb-4">
                <p>
                  {allProducts && allProducts.length > 0
                    ? selectLanguage === "ar"
                      ? `عرض الكل ${allProducts?.length} النتيجة `
                      : `Showing All ${allProducts?.length} Result`
                    : productHead
                    ? "Showing all 0 result"
                    : "Sample Products"}
                </p>
              </div>
              {!allProducts && (
                <div className="min-h-[50vh] flex justify-center items-center bg-white">
                  <p className="text-xl md:text-3xl font-semibold md:font-bold text-gray-600">
                    {selectLanguage === "ar"
                      ? "لا توجد منتجات تم العثور عليها"
                      : "No Product Found"}
                  </p>
                </div>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 justify-items-center md:justify-items-start">
                {allProducts?.map((item, idx) => (
                  <div
                    className="single_product relative cursor-pointer bg-gray-200 pb-4 shadow-sm rounded-sm hover:shadow-md hover:scale-[1.01] ease-linear duration-300 md:min-w-fit max-w-[360px] w-full align-self-center"
                    key={idx}
                  >
                    <div className="absolute top-0 right-0 p-3 bg-gray-200 hover:bg-black hover:text-primary heart ease-in-out transition-all duration-300">
                      <p>{item.product_price} ر.س</p>
                    </div>
                    <div className="w-full h-[320px]">
                      <Link
                        to={`${
                          customdomain === "aitech.com.sa"
                            ? `/${storeName}/product_details/${item.product_id}`
                            : `/product_details/${item.product_id}`
                        }`}
                        className="text-center text-gray-400"
                      >
                        <img
                          className="w-full h-full object-cover"
                          src={item.thumbnail_image}
                          alt={
                            selectLanguage == "ar" && item.product_name_ar
                              ? item.product_name_ar
                              : item.product_name
                          }
                        />
                      </Link>
                    </div>
                    <div className="">
                      <h2 className="card-title justify-center text-gray-400 text-[14px] my-3 mx-1">
                        <Link
                          to={`${
                            customdomain === "aitech.com.sa"
                              ? `/${storeName}/product_details/${item.product_id}`
                              : `/product_details/${item.product_id}`
                          }`}
                          className="text-gray-600 text-base capitalize text-center productCard"
                        >
                          {selectLanguage == "ar" && item.product_name_ar
                            ? item.product_name_ar
                            : item.product_name}
                        </Link>
                      </h2>
                      <div className="card-actions justify-center items-center flex-col lg:overflow-hidden lg:h-[20px]">
                        <div className="price text-center">
                          <p>{item.product_price} ر.س</p>
                          <Link
                            to={`${
                              customdomain === "aitech.com.sa"
                                ? `/${storeName}/product_details/${item.product_id}`
                                : `/product_details/${item.product_id}`
                            }`}
                            className="transition-all ease-in duration-300 text-blue-700 hover:text-blue-700 flex items-center"
                          >
                            <BsCart2
                              className={`inline mr-2 ${
                                selectLanguage == "ar" ? "ml-3" : "ml-0"
                              } `}
                            />
                            {selectLanguage == "ar"
                              ? "إضافة إلى السلة"
                              : "Add to cart"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* sample products */}
              {allProducts?.length === 0 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 justify-items-center md:justify-items-start">
                  {dummyProducts?.map((item, idx) => (
                    <div
                      className="single_product relative cursor-pointer bg-gray-200 pb-4 shadow-sm rounded-sm hover:shadow-md hover:scale-[1.01] ease-linear duration-300 md:min-w-fit max-w-[360px] w-full align-self-center"
                      key={idx}
                    >
                      <div className="absolute top-0 right-0 p-3 bg-gray-200 hover:bg-black hover:text-primary heart ease-in-out transition-all duration-300">
                        <p>{item.product_price} ر.س</p>
                      </div>
                      <div className="w-full h-[320px]">
                        <Link
                          to={`${
                            customdomain === "aitech.com.sa"
                              ? `/${storeName}/product_details/${item.product_id}`
                              : `/product_details/${item.product_id}`
                          }`}
                          className="text-center text-gray-400"
                        >
                          <img
                            className="w-full h-full object-cover"
                            src={item.thumbnail_image}
                            alt={
                              selectLanguage == "ar" && item.product_name_ar
                                ? item.product_name_ar
                                : item.product_name
                            }
                          />
                        </Link>
                      </div>
                      <div className="">
                        <h2 className="card-title justify-center text-gray-400 hover:text-blue-500 text-[14px] my-3">
                          <Link
                            to={`${
                              customdomain === "aitech.com.sa"
                                ? `/${storeName}/product_details/${item.product_id}`
                                : `/product_details/${item.product_id}`
                            }`}
                            className="text-gray-600 text-base capitalize"
                          >
                            {selectLanguage == "ar" && item.product_name_ar
                              ? item.product_name_ar
                              : item.product_name}
                          </Link>
                        </h2>
                        <div className="card-actions justify-center items-center flex-col lg:overflow-hidden lg:h-[20px]">
                          <div className="price text-center">
                            <p>{item.product_price} ر.س</p>
                            <Link
                              to={`${
                                customdomain === "aitech.com.sa"
                                  ? `/${storeName}/product_details/${item.product_id}`
                                  : `/product_details/${item.product_id}`
                              }`}
                              className="transition-all ease-in duration-300 text-blue-700 hover:text-blue-700 flex items-center"
                            >
                              <BsCart2
                                className={`inline mr-2 ${
                                  selectLanguage == "ar" ? "ml-3" : "ml-0"
                                } `}
                              />
                              {selectLanguage == "ar"
                                ? "إضافة إلى السلة"
                                : "Add to cart"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <StoreFooter />
      </div>
    </>
  );
};

export default Shop;
