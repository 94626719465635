import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Div from "../components/utils/Div";
import PolicyPage from "./PolicyPage";
import AgrementPage from "./AgrementPage";
import Footer2 from "../components/Footer/Footer2";
import Header from "../components/Header/Header";
import { domainProvider } from "../contexts/DomainContext";
import Whatsapp from "../components/utils/WhatsappIcon";
import axios from "axios";
import Aos from "aos";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const [ganarel, setGanarel] = useState([]);
  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  const { customdomain, titleName } = useContext(domainProvider);

  return (
    <>
      <Helmet>
        <title>
          {t("login_p_policy")} - {titleName}
        </title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header navigation={true} />
      <Div>
        <PolicyPage />
        <AgrementPage />
        <Whatsapp />
      </Div>
      <Footer2 />
    </>
  );
};

export default PrivacyPolicy;
