import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FilterComponent from "../../../components/utils/FilterComponent";
import { MdDeleteForever } from "react-icons/md";
import deleteDataById from "../../../hooks/admin/deleteDataById";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import getAllAdmin from "../../../hooks/getAllAdmin";

const AdminOrders = () => {
  const { t } = useTranslation();
  const [selectedData, setSelectedData] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  // let filteredItems=[];
  // if(data && data.length>0){
  //      filteredItems = data.filter(
  //         item => item.store_id.includes(filterText.toLowerCase()));
  // }

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center gap-2 flex-wrap my-5">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          setFilterText={setFilterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    const getAllPlans = async () => {
      let res = await getAllAdmin("admin/getallvendorplan");

      console.log(res)

      if (res.status_code === 200) {
        setData(res.data.reverse());
      }
    };
    getAllPlans();
  }, []);

  const deleteOrder = async (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("Yes")}`,
          onClick: async () => {
            setLoading(true);

            const res = await deleteDataById("admin/deletevendorplan", id);

            if (res.status_code === 200) {
              toast.success(`${t("data_deleted_successfully")}`);
              const updatedorder = data.filter((item) => item.id !== id);
              setData(updatedorder);
            } else {
              toast.error(`${t("delete_err")}`);
            }

            setLoading(false);
          },
        },
        {
          label: `${t("No")}`,
          onClick: () => {},
        },
      ],
    });
  };
  const columns = [
    {
      name: `${t("Order_Id")}`,
      selector: (row) => row.id,
    },
    {
      name: `${t("Vendor")}`,
      selector: (row) => (row.store_name ? row.store_name : ""),
    },

    {
      name: `${t("Plan")}`,
      selector: (row) => row.plan_name,
    },
    {
      name: `${t("Transaction_id")}`,
      selector: (row) => row.transaction_id,
    },

    {
      name: `${t("Status")}`,
      selector: (row) =>
        row.status === 1 ? (
          <span className="badge badge-success text-white text-xs">
            {t("Active")}
          </span>
        ) : (
          <span className="badge badge-error text-white text-xs">
            {t("Pending")}
          </span>
        ),
    },
    {
      name: "Options",
      sortable: true,
      cell: (row) => (
        <div className="flex gap-2">
          {/* <label className="p-2 flex items-center border border-blue-500 hover:bg-blue-500 rounded-full text-blue-500 hover:text-white cursor-pointer duration-300" title='Change Status'>
                    <AiFillEdit />
                </label> */}

          <span
            title={`${t("Delete")}`}
            onClick={() => deleteOrder(row.id)}
            className=" w-6 h-6 text-center text-2xl rounded-full text-red-500 border-none cursor-pointer"
          >
            <MdDeleteForever />
          </span>
        </div>
      ),
    },
  ];

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-title text-lg md:text-2xl pb-2">
          {t("All_Orders")}
        </h2>
        <Link
          to="/admin/create-order"
          className="py-2 in-pri2 text-sm px-4 bg-primary text-white rounded font-normal"
        >
          {t("Add_New_Order")}
        </Link>
      </div>
      <div>
        <div className="my-2"></div>

        <DataTable
          columns={columns}
          data={data}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          // selectableRows
          pagination
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
};

export default AdminOrders;
