import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../components/Footer/Footer";
import Footer2 from "../components/Footer/Footer2";
import Header from "../components/Header/Header";
import Pricing from "../components/HomeComponents/Pricing";
import { domainProvider } from "../contexts/DomainContext";
import Whatsapp from "../components/utils/WhatsappIcon";
import axios from "axios";
import Aos from "aos";

const PlansPricing = () => {
  const [ganarel, setGanarel] = useState([]);
  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  const { customdomain, titleName } = useContext(domainProvider);

  return (
    <div className="bg-[#F7F7F7]">
      <Helmet>
        <title>Plans & Pricing - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header navigation={true} />
      <Pricing />
      <Whatsapp />
      <Footer2 />
    </div>
  );
};

export default PlansPricing;
