import React from "react";
import { Navigate } from "react-router-dom";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import useAdmin from "../../../hooks/useAdmin";

const RequireAdmin = (props) => {
  const [user, userLoading] = useAdmin();

  if (userLoading) {
    return <LoadingSpinner />;
  }
  if (!user) {
    return <Navigate to="/admin/login" />;
  } else {
    if (user?.status) {
      return props.children;
    } else {
      return <Navigate to="/admin/login" />;
    }
  }
};

export default RequireAdmin;
