import { useState } from "react";
import { toast } from "react-toastify";
import useUser from "./useUser";
import axios from "../components/utils/Interceptors";

const postData = async (url, data, isFormData = false) => {
  if (!isFormData) {
    data = JSON.stringify(data);
  }

  const user = JSON.parse(localStorage.getItem("user"));

  const baseUrl = process.env.REACT_APP_APIURL;

  const res = await axios.post(`${baseUrl}${url}`, data, {
    headers: {
      "Content-type": "Application/json",
      Authorization: `Bearer ${user.token}`,
    },
  });

  const response = await res.data;

  if (response.status_code === 200) {
    if (response.data[0]) {
      return response.data[0];
    } else if (response.data) {
      return response;
    }
  } else {
    return response;
  }
};

export default postData;
