import React, { createContext } from 'react'
import { useTranslation } from 'react-i18next';

export const translateProvider = createContext()
const LangContext = ({ children }) => {
    const { t } = useTranslation()
    return (
        <translateProvider.Provider value={t}>{children}</translateProvider.Provider>
    )
}

export default LangContext