import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowCircleLeft } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import getDataById from "../../../hooks/getDataById";
import updateData from "../../../hooks/updateData";
import { useTranslation } from "react-i18next";

const EditCustomer = () => {
  const { t } = useTranslation();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const getByid = async () => {
      const response = await getDataById("vendor/getbyidcustomer", id);
      if (response.status_code === 200 && response.data) {
        handleData(response.data);
      }
      setLoading(false);
    };
    getByid();
  }, []);

  const handleData = (customer) => {
    setValue("id", customer.cust_id);
    setValue("cust_fullname", customer.cust_fullname);
    setValue("cust_phone", customer.cust_phone);
    setValue("cust_email", customer.cust_email);
  };

  const onSubmit = async (data) => {
    const response = await updateData("vendor/updatecustomer", data);
    if (response.status_code == 200) {
      toast.success("Updated!");
      navigate("/vendor/customers");
    } else {
      toast.error(response.message);
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex">
        <h2 className="text-title text-lg md:text-2xl pb-2 mr-3">
          {t("Edit")}
        </h2>
        <Link to={"/vendor/customers"}>
          <button className="btn btn-primary btn-xs rounded text-white mb-5 flex justify-center items-center gap-x-2">
            <FaArrowCircleLeft size={15} />
            {t("add_new_product_back")}
          </button>
        </Link>
      </div>
      <div className="mt-4">
        <div className="flex flex-col gap-4 md:px-12 bg-white border rounded shadow p-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-control grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 ">
              <div>
                <label className="label">
                  <span className="label-text">{t("Full_Name")}</span>
                </label>
                <input
                  {...register("cust_fullname", {
                    pattern: /^[a-zA-Z ,.'-]+$/i,
                  })}
                  type="text"
                  placeholder={`${t("Full_Name")}`}
                  className="input input-bordered input-sm w-full rounded-none"
                />
                {errors.cust_fullname?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Name must only contain alphabet letters
                  </span>
                )}
              </div>

              <div>
                <label className="label">
                  <span className="label-text">{t("l_phone")}</span>
                </label>
                <input
                  {...register("cust_phone", { maxLength: 12 })}
                  type="number"
                  placeholder={`${t("num")}`}
                  className="input input-bordered input-sm w-full rounded-none"
                />
                {errors.cust_phone?.type === "maxLength" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Contact number can't exceed more than 12 letters
                  </span>
                )}
              </div>
              <div>
                <label className="label">
                  <span className="label-text">{t("form_email")}</span>
                </label>
                <input
                  {...register("cust_email")}
                  type="email"
                  placeholder="demo@gmail.com"
                  className="input input-bordered input-sm w-full rounded-none"
                />
              </div>
              <div>
                <label className="label">
                  <span className="label-text">{t("l_p")}</span>
                </label>
                <input
                  {...register("cust_password")}
                  type="text"
                  className="input input-bordered input-sm w-full rounded-none"
                />
              </div>
            </div>

            <button className="btn btn-sm btn-primary rounded text-white mt-5">
              {t("Update_Customer")}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditCustomer;
