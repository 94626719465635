import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Rating } from "react-simple-star-rating";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";

const Reviews = () => {
  const { t } = useTranslation();
  const [reviews, setReviews] = useState([]);
  const selectLanguage = i18next.language;
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchReviews = async () => {
      const baseUrl = process.env.REACT_APP_APIURL;

      const res = await axios.get(`${baseUrl}/vendor/getallreview`, {
        headers: {
          // 'Content-type': 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
      });
      const response = await res.data;

      if (response.status_code === 200) {
        if (response.data) {
          setReviews(response.data);
        }
      }
    };

    fetchReviews();
  }, []);

  const columns = [
    {
      name: `${t("form_name")}`,
      selector: (row) => row.cust_fullname,
    },
    {
      name: `${t("Rating")}`,
      selector: (row) => (
        <Rating readonly={true} initialValue={row.rating} size={14} />
      ),
    },
    {
      name: `${t("Review")}`,
      selector: (row) =>
        selectLanguage === "ar" && row.review_ar ? row.review_ar : row.review,
    },
  ];

  return (
    <div>
      <span className="text-xl font-medium">{t("Review_all")}</span>
      <div className="data-table mt-5">
        <div>
          <DataTable
            columns={columns}
            data={reviews}
            subHeader
            pagination
            noDataComponent={
              <h4 className="py-8 text-gray-400 text-center">
                {t("No records")}
              </h4>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
