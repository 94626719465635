import React from "react";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";
import "react-photo-view/dist/react-photo-view.css";
import { Carousel } from "react-carousel-minimal";


const ProductSlider = ({ images }) => {

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  const data = images && images.map((image, idx) => {
    return { image: image.image_url };
  });

  return (
    <>
      {data && data.length > 0 ? (
        <Carousel
          data={data}
          time={2000}
          width="850px"
          height="500px"
          captionStyle={captionStyle}
          radius="4px"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="40px"
          slideBackgroundColor="darkgrey"
          slideImageFit="cover"
          thumbnails={true}
          thumbnailWidth="100px"
          style={{
            textAlign: "center",
            maxWidth: "850px",
            maxHeight: "500px",
            margin: " auto",
          }}
          
        />
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-full">
          <p className="text-lg">No images found</p>
        </div>
      )}
    </>
  );
};

export default ProductSlider;
