import React from "react";
import { useState } from "react";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import interceptors from "../../../components/utils/Interceptors";
import axios from "axios";
import i18next from "i18next";

const UploadFileModal = () => {
  const [galleryimages, setGalleryimages] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxNumber = 1;
  const user = JSON.parse(localStorage.getItem("user"));
  const admin = JSON.parse(localStorage.getItem("admin"));
  const selectLanguage = i18next.language

  const uploadFiles = async () => {
    setLoading(true);

    const formData = new FormData();

    for (let i of galleryimages) {
      formData.append("files", i.file);
    }
    let api;
    var res;
    if (admin?.admin_id) {
      api = `${process.env.REACT_APP_APIURL}/admin/uploadtemplate`;
      res = await axios.post(api, formData, {
        headers: {
          Authorization: `Bearer ${admin.token}`,
        },
      });
    } else {
      api = `${process.env.REACT_APP_APIURL}/vendor/uploadfileons3`;

      res = await interceptors.post(api, formData, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
    }

    const response = await res.data;
    if (response?.status_code === 200) {
      toast.success("Successfully Added!");
      galleryimages.length = 0;
      window.location.reload();
    } else {
      toast.error("Something went Wrong");
    }

    setLoading(false);
  };

  const onChangeGalleryImages = (imageList, addUpdateIndex) => {
    // data for submit
    setGalleryimages(imageList);
  };

  if (loading) {
    return LoadingSpinner;
  }

  return (
    <div>
      <input type="checkbox" id="upload-file-modal" className="modal-toggle" />
      <div className="modal">
        <div className={`modal-box w-11/12 md:max-w-2xl ${selectLanguage === "ar" ? "md:right-12" : "md:left-10"} relative rounded`}>
          <label
            htmlFor="upload-file-modal"
            className="btn btn-sm btn-circle btn-primary text-white absolute right-2 top-2"
          >
            ✕
          </label>
          <div className="p-2">
            <div className="tab-menu">
              <ImageUploading
                multiple
                value={galleryimages}
                onChange={onChangeGalleryImages}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,

                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    <p
                      className="text-md cursor-pointer py-10 border px-3 text-center mt-5"
                      style={isDragging ? { color: "red" } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      Click or Drop here to Upload Image
                    </p>

                    {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                    <div className="flex gap-2">
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item max-w-[5rem]">
                          <div className="image-item__btn-wrapper">
                            {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                            <button
                              className="text-red-600"
                              onClick={() => onImageRemove(index)}
                            >
                              X
                            </button>
                          </div>
                          <img
                            className="w-20 h-20 object-contain"
                            src={image["data_url"]}
                            alt=""
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </ImageUploading>
            </div>

            {galleryimages.length > 0 && (
              <div className="text-center py-4">
                <button
                  onClick={uploadFiles}
                  className={`btn btn-sm btn-primary text-white rounded ${
                    loading ? "loading" : ""
                  }`}
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFileModal;
