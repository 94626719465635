import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import "../StoreTemplate/StoreStyle/StoreStyle.css";
import { storeInformation } from "../../contexts/StoreInformationContext";
import { userProvider } from "../../contexts/UserContext";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "axios";

const ContactUs = () => {
  const { isVendor } = useContext(userProvider);
  const { storeDetails } = useContext(storeInformation);
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
  } = useForm();

  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : window.location.href.split("/")[3];

  // custom domain
  const { customdomain, titleName } = useContext(domainProvider);

  const onSubmit = async (data) => {
    var res;
    if (customdomain === "aitech.com.sa") {
      res = await axios.post(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/createquery/${storeName}`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      res = await axios.post(
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/createquery`,
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
            domain_name: customdomain,
          },
        }
      );
    }

    const response = await res.data;
    if (response.status_code === 200) {
      toast.success(response.message);
      reset();
      return;
    }
    toast.error(response.err);
  };
  return (
    <>
      <ShopNavbar />
      <Helmet>
        <title>Contact us - {titleName}</title>
      </Helmet>
      <div className="mb-10">
        <iframe
          width="100%"
          height="500"
          title="shopMap"
          src="https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=saudi%20arabia+(saudi%20arabia)&amp;ie=UTF8&amp;t=&amp;z=9&amp;iwloc=B&amp;output=embed"
        ></iframe>
      </div>
      <div className="Container my-16">
        <div className="contactWrapper px-8">
          <div className="w-4/5">
            <div>
              <h1 className="text-3xl mb-2 text-[#8F8F8F] storeContactText">
                {selectLanguage === "ar" ? "معلومات الاتصال" : "Contact Info."}
              </h1>
              <p className="text-[#8F8F8F] storeContactText">
                {selectLanguage === "ar"
                  ? "بعض المعلومات التي قد ترغب في معرفتها"
                  : "Some information that you may want to know"}
              </p>
            </div>
            <div className="mt-10 pb-5 border-b border-dashed">
              <p className="text-xs text-[#8F8F8F] font-bold mb-3 storeContactText">
                {selectLanguage === "ar" ? "رقم الهاتف" : "PHONE NUMBER"}
              </p>
              <p className="text-xl text-[#2B2B2B] storeContactText">
                +{storeDetails?.data?.storeDetails?.store_phone}
              </p>
            </div>
            <div className="mt-10 pb-5 border-b border-dashed">
              <p className="text-xs text-[#8F8F8F] font-bold mb-3 storeContactText">
                {selectLanguage === "ar" ? "العنوان" : "ADDRESS"}
              </p>
              <p className="text-xl font-normal text-[#2B2B2B] storeContactText">
                {storeDetails?.data?.storeDetails?.store_address}
              </p>
              <p className="text-xl text-[#2B2B2B] storeContactText">
                {storeDetails?.data?.storeDetails?.store_state}
              </p>
            </div>
            <div className="mt-10 pb-5 border-b border-dashed">
              <p className="text-xs text-[#8F8F8F] font-bold mb-3 storeContactText">
                {selectLanguage === "ar" ? "البريد الإلكتروني" : "EMAIL"}
              </p>
              <p className="text-xl font-normal text-[#2B2B2B] storeContactText">
                {storeDetails?.data?.storeDetails?.store_email}
              </p>
            </div>
          </div>

          <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="text-3xl mb-2 text-[#8F8F8F] storeContactText">
              {selectLanguage === "ar" ? "اترك رسالتك" : "Leave Your Message"}
            </h1>
            <p className="mb-3 text-[#8F8F8F] storeContactText">
              {selectLanguage === "ar"
                ? "لا تتردد في الاتصال بنا باستخدام النموذج أدناه."
                : "Feel free to contact with us by using the form below"}
            </p>
            <div className="flex items-center">
              <div
                className={`form-control w-full ${
                  selectLanguage === "ar" ? "ml-10" : "mr-10"
                } `}
              >
                <label className="label">
                  <span className="label-text text-[#8F8F8F] storeContactText">
                    {t("form_name")}
                  </span>
                </label>
                <input
                  {...register("customer_name", { required: true })}
                  type="text"
                  placeholder={t("form_name_place")}
                  className="input input-sm input-bordered p-5 focus:border-primary focus:outline-none rounded-none w-full "
                />
                <label className="label">
                  {errors.firstName?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {selectLanguage === "ar" ? "" : ""}
                      {t("form_name_req")}
                    </span>
                  )}
                </label>
              </div>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text text-[#8F8F8F] storeContactText">
                    {t("form_email")}
                  </span>
                </label>
                <input
                  {...register("customer_email", { required: true })}
                  type="text"
                  placeholder={t("form_email_place")}
                  className="input input-sm input-bordered focus:border-primary p-5 focus:outline-none rounded-none w-full "
                />
                <label className="label">
                  {errors.firstName?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("form_email_req")}
                    </span>
                  )}
                </label>
              </div>
            </div>

            <div
              className={`form-control w-full  ${
                selectLanguage === "ar" ? "ml-10" : "mr-10"
              } `}
            >
              <label className="label">
                <span className="label-text text-[#8F8F8F] storeContactText">
                  {t("form_message")}
                </span>
              </label>
              <textarea
                {...register("query", { required: true })}
                type="text"
                placeholder={t("form_message_place")}
                className="input input-sm input-bordered p-5 focus:border-primary focus:outline-none rounded-none w-full resize-y min-h-[120px] h-[240px] max-h-[240px]"
              />
              <label className="label">
                {errors.firstName?.type === "required" && (
                  <span className="label-text-alt text-red-500 font-Roboto">
                    Message Is Required
                  </span>
                )}
              </label>
            </div>

            {/* message in arabic */}
            <div
              className={`form-control w-full  ${
                selectLanguage === "ar" ? "ml-10" : "mr-10"
              } `}
            >
              <label className="label">
                <span className="label-text text-[#8F8F8F] storeContactText">
                  {t("form_message")} {t("in Arabic")}
                </span>
              </label>
              <textarea
                {...register("query_ar")}
                type="text"
                placeholder={t("form_message_place")}
                className="input input-sm input-bordered p-5 focus:border-primary focus:outline-none rounded-none w-full resize-y min-h-[120px] h-[240px] max-h-[240px]"
              />
            </div>

            <button className="rounded-3xl bg-primary py-3 my-4 px-5 text-white">
              {t("btn_send")}
            </button>
          </form>
        </div>
      </div>
      <StoreFooter />
    </>
  );
};

export default ContactUs;
