import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import create1 from "../../assets/guide/create-1.jpg";
import create2 from "../../assets/guide/create-2.jpg";
import step2 from "../../assets/guide/step2.png";
import step3 from "../../assets/guide/step3.jpg";
import step5 from "../../assets/guide/step5.png";
import create1Ar from "../../assets/guide/create-1-ar.jpg";
import create2Ar from "../../assets/guide/create-2-ar.jpg";
import step2Ar from "../../assets/guide/step2-ar.jpg";
import step3Ar from "../../assets/guide/step3-ar.jpg";
import step5Ar from "../../assets/guide/step5-ar.jpg";

const CreateStore = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
          {selectLanguage === "ar"
            ? "إنشاء متجر عبر الإنترنت"
            : "Create Online Store"}
        </h1>
        <p className="text-lg text-gray-600 my-3">
          {selectLanguage === "ar"
            ? "لإنشاء متجر عبر الإنترنت، تفضل بزيارة موقعنا على الويب "
            : "To Create Online Store visit our Website :"}
          <a
            href="https://aitech.com.sa/"
            className="underline text-indigo-700 font-semibold text-base"
            target="_blank"
          >
            https://aitech.com.sa/
          </a>
        </p>
        <p className="text-lg text-gray-600 my-4 flex flex-row items-center gap-x-2">
          {" "}
          {selectLanguage === "ar" ? "ثم انقر على " : "Click On :"}
          <button className="bg-primary rounded-md uppercase font-semibold py-2 px-5 flex justify-center items-center text-white text-sm font-Roboto">
            {t("Create_free_Store")}
          </button>
        </p>
        <img
          src={selectLanguage === "ar" ? create1Ar : create1}
          className="w-[90%] m-auto border-2"
        />
        <h3 className="mt-8 mb-4 text-[#236260]">
          {t("Following are the steps")} :{" "}
        </h3>
        <ol
          type="1"
          className={`${selectLanguage == "ar" ? "pr-4" : "pl-4"} list-decimal`}
        >
          <li className="list-item text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "	خطوات إنشاء حسابك باستخدام اسم المتجر واسم المالك وعنوان البريد الإلكتروني ورقم الهاتف الجوال."
              : "Steps to create your Account using store name, owner name, gmail address and mobile number."}
            <img
              src={selectLanguage === "ar" ? step2Ar : step2}
              className="w-full md:w-[60%] m-auto my-2"
            />
          </li>
          <li className="list-item text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "بعد التسجيل، ستتم إعادة توجيهك إلى صفحة الإعلام : "
              : "After Registering You will be redirected to Notification Page :"}
            <img
              src={selectLanguage === "ar" ? step3Ar : step3}
              className="w-full md:w-[60%] m-auto my-2"
            />
          </li>
          <li className="list-item text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "تحقق من بريدك الإلكتروني وانقر فوق الارتباط لتسجيل الدخول مباشرة إلى متجرك. "
              : " Check your e-mail and click on the link to directly log-in to your store."}
            <img
              src={selectLanguage === "ar" ? create2Ar : create2}
              className="w-full md:w-[60%] m-auto my-2"
            />
          </li>
          <li className="list-item text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "سجل الدخول إلى حسابك الجديد لتسجيل الدخول من خلال معرف Gmail الخاص بك."
              : "Login to your new account to Sign in through your registered Email ID."}
            <img
              src={selectLanguage === "ar" ? step5Ar : step5}
              className="w-full md:w-[60%] m-auto my-2"
            />
          </li>
        </ol>
      </div>
    </>
  );
};

export default CreateStore;
