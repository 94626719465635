import React from "react";
import step6 from "../../assets/guide/step6.png";
import step7 from "../../assets/guide/step7.png";
import step8 from "../../assets/guide/step8.png";
import step9 from "../../assets/guide/step9.png";
import step10 from "../../assets/guide/step10.png";
import step11 from "../../assets/guide/step11.png";
import step12 from "../../assets/guide/step12.png";
import step13 from "../../assets/guide/step13.png";
import step14 from "../../assets/guide/step14.png";
import step15 from "../../assets/guide/step15.png";
import step16 from "../../assets/guide/step16.png";
import step17 from "../../assets/guide/step17.png";
import step18 from "../../assets/guide/step18.png";
import step19 from "../../assets/guide/step19.png";
import product1 from "../../assets/guide/product1.png";
import product2 from "../../assets/guide/product2.png";
import media1 from "../../assets/guide/guideDB-5-1.png";
import media2 from "../../assets/guide/guideDB-5-2.jpg";
import package1 from "../../assets/guide/package1.png";
import package2 from "../../assets/guide/package2.jpg";
import step6Ar from "../../assets/guide/step6-ar.jpg";
import step7Ar from "../../assets/guide/step7-ar.jpg";
import step8Ar from "../../assets/guide/step8-ar.jpg";
import step9Ar from "../../assets/guide/step9-ar.jpg";
import step10Ar from "../../assets/guide/step10-ar.jpg";
import media1Ar from "../../assets/guide/media1-ar.jpg";
import media2Ar from "../../assets/guide/media2-ar.jpg";
import step17Ar from "../../assets/guide/step17-ar.jpg";
import step18Ar from "../../assets/guide/step18-ar.jpg";
import step19Ar from "../../assets/guide/step19-ar.jpg";
import product1Ar from "../../assets/guide/product1-ar.jpg";
import product2Ar from "../../assets/guide/product2-ar.jpg";
import package1Ar from "../../assets/guide/package1-ar.jpg";
import step11Ar from "../../assets/guide/step11-ar.jpg";
import step12Ar from "../../assets/guide/step12-ar.jpg";
import step14Ar from "../../assets/guide/step14-ar.jpg";
import step15Ar from "../../assets/guide/step15-ar.jpg";
import step16Ar from "../../assets/guide/step16-ar.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const GuideDashboard = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  return (
    <>
      <div className="">
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
          {t("Dashboard")}
        </h1>
        <p className="text-lg text-gray-600 my-3">
          <h4 className="my-1">
            {t("Step")} 1 :{" "}
            {selectLanguage === "ar"
              ? " تسجيل الدخول إلى لوحة المعلومات"
              : "Login in to your dashboard "}{" "}
          </h4>
          {selectLanguage === "ar"
            ? "بعد تسجيل الدخول إلى حسابك ستتم إعادة توجيهك إلى لوحة المعلومات"
            : "After Logging in to your account you will be redirected to dashboard."}
          <img
            src={selectLanguage === "ar" ? step6Ar : step6}
            className="w-full md:w-[60%] m-auto my-2"
          />
          <ul className="list-disc">
            <li className="my-3">
              {selectLanguage === "ar"
                ? "إضافة تفاصيل إلى حساب المتجر مثل معلومات المتجر، وتمكين طرق الشحن، وإضافة طريقة الدفع، وإضافة منتج، واستكشاف الحزم"
                : "Add details to your store account like store information and enable shipping methods , add payment method ,add your product and explore packages."}
              <img
                src={selectLanguage === "ar" ? step7Ar : step7}
                className="w-full md:w-[90%] m-auto my-2"
              />
            </li>
            <li className="my-3">
              {selectLanguage === "ar"
                ? "أضف معلومات المتجر انقر للانتقال إلى الزر."
                : " Add your store information by clicking on Click to navigate button. "}
              <img
                src={selectLanguage === "ar" ? step8Ar : step8}
                className="w-full md:w-[90%] m-auto my-2"
              />
              <span className="text-base font-normal">
                {selectLanguage === "ar"
                  ? "بعد النقر على الزر "
                  : "After Clicking on"}
                <b>
                  {" "}
                  {selectLanguage === "ar"
                    ? "إضافة معلومات المتجر"
                    : "  Add Your Store Information Button  "}
                </b>
                {selectLanguage === "ar"
                  ? "لك سيتم إعادة توجيهه إلى صفحة الإعدادات: "
                  : "you will be redirected to Settings Page:"}
                <br />
                {selectLanguage === "ar"
                  ? "يمكنك هنا ملء اسم المتجر والوصف والشعار"
                  : "Here you can fill your store name, description and Logo."}
              </span>
              <img
                src={selectLanguage === "ar" ? step9Ar : step9}
                className="w-full md:w-[60%] m-auto my-2"
              />
            </li>
            <li className="my-3">
              <b>
                {selectLanguage === "ar"
                  ? "تعيين تفاصيل الموقع :"
                  : "Set your Location details :"}{" "}
              </b>
              <span className="text-base font-normal">
                {selectLanguage === "ar"
                  ? "تفاصيل موقع المتجر عن طريق إدخال المنطقة والمدينة والبلد والرمز البريدي والبريد الإلكتروني ورقم الهاتف. "
                  : "Add your Store Location details by entering your Region, City, Country, Postal Code, Email and Phone Number."}
              </span>
              <img
                src={selectLanguage === "ar" ? step10Ar : step10}
                className="w-full md:w-[60%] m-auto my-2"
              />
            </li>
            <li className="my-3">
              {selectLanguage === "ar"
                ? "في صفحة الإعدادات انقر على الزر الاجتماعي"
                : " In the settings page click on the 'social' button."}

              <img
                src={selectLanguage === "ar" ? media1Ar : media1}
                className="w-full md:w-[60%] m-auto my-2"
              />
            </li>
            <li className="my-3">
              {selectLanguage === "ar"
                ? 'انقر فوق الزر "إضافة جديد" ثم أدخل عنوان URL للوسائط الاجتماعية الخاصة ب Facebook أو Instagram أو LinkedIn أو YouTube. ومن ثم حفظ.'
                : "Click on 'ADD NEW' then enter social media URL of any like Facebook or Instagram, LinkedIn, YouTube. And then save."}
              <img
                src={selectLanguage === "ar" ? media2Ar : media2}
                className="w-full md:w-[60%] m-auto my-2"
              />
            </li>
          </ul>
        </p>

        {/* step2 */}
        <p className="text-lg text-gray-600 my-3">
          <h4 className="my-1">
            {t("Step")} 2 :
            {selectLanguage === "ar"
              ? "انقر على زر تمكين طرق الشحن"
              : " Click on the Enable Shipping Methods Button "}
            <img
              src={selectLanguage === "ar" ? step11Ar : step11}
              className="w-full md:w-[90%] m-auto my-2"
            />
          </h4>
          <ul className="list-disc">
            <li className="my-3">
              {selectLanguage === "ar"
                ? "في طرق الشحن: "
                : "In the Shipping Methods :"}
              <br />
              <p className="my-2">
                <span className="font-semibold">{t("Step")} 1 - </span>{" "}
                <span className="text-base font-normal">
                  {selectLanguage === "ar"
                    ? "انقر على زر إنشاء حساب الشحن "
                    : " Click on Create Shipping Method Button "}
                </span>
              </p>
              <p className="my-2">
                <span className="font-semibold">{t("Step")} 2 - </span>{" "}
                <span className="text-base font-normal">
                  {selectLanguage === "ar"
                    ? "انقر فوق إنشاء مستودع"
                    : " Click on create Warehouse "}
                </span>
              </p>
              <p className="my-2">
                <span className="font-semibold">{t("Step")} 3 - </span>
                <span className="text-base font-normal">
                  {selectLanguage === "ar"
                    ? "انقر على تمكين أسلوب الشحن"
                    : " Click on Enable Shipping Method "}
                </span>
              </p>
              <img
                src={selectLanguage === "ar" ? step12Ar : step12}
                className="w-full md:w-[70%] m-auto my-2"
              />
              <span className="text-base font-normal my-1">
                {selectLanguage === "ar"
                  ? "بعد النقر فوق الزر إنشاء حساب الشحن، ستتلقى رسالة بريد إلكتروني من شركة الشحن Torod."
                  : "After clicking the create shipping method button, you will receive 'Email from Shipping company TOROD' "}
              </span>
              <img src={step13} className="w-full md:w-[60%] m-auto my-2" />
              <span className="text-base font-normal my-1">
                {selectLanguage === "ar"
                  ? "بعد ذلك، تحتاج إلى إدخال تفاصيل تسجيل الدخول إلى Torod في طرق الشحن:"
                  : "You need to Enter the 'Login details' of the 'Torod' zin the 'Shipping methods' "}
              </span>
              <img
                src={selectLanguage === "ar" ? step14Ar : step14}
                className="w-full md:w-[60%] m-auto my-2"
              />
              <img
                src={selectLanguage === "ar" ? step15Ar : step15}
                className="w-full md:w-[60%] m-auto my-2"
              />
              <span className="text-base font-normal my-1">
                {selectLanguage === "ar"
                  ? "بعد تسجيل الدخول، سيتم تسجيل دخولك إلى أساليب الشحن المتوفرة"
                  : "After Logged in, you will be logged in into available shipping methods."}
              </span>
              <img
                src={selectLanguage === "ar" ? step16Ar : step16}
                className="w-full md:w-[60%] m-auto my-2"
              />
            </li>
          </ul>
        </p>

        {/* step3 */}
        <p className="text-lg text-gray-600 my-3">
          <h4 className="my-1">
            {t("Step")} 3 :{" "}
            {selectLanguage === "ar" ? "طريقة الدفع" : "Payment Method : "}{" "}
          </h4>
          <ul className="list-disc">
            <li className="my-3">
              {selectLanguage === "ar"
                ? 'انقر فوق الزر "إضافة طريقة دفع" لتمكين أساليب الدفع'
                : "Click on Add Payment Method Buton to enable Payment Methods"}
              <img
                src={selectLanguage === "ar" ? step17Ar : step17}
                className="w-full md:w-[70%] m-auto my-2"
              />
            </li>
            <li className="my-3">
              {selectLanguage === "ar"
                ? "انقر فوق الزر إضافة إلى بوابة دفع"
                : "Click On Add Payment Gateway Button"}
              <img
                src={selectLanguage === "ar" ? step18Ar : step18}
                className="w-full md:w-[70%] m-auto my-2"
              />
            </li>
            <li className="my-3">
              {selectLanguage === "ar"
                ? "إضافة مفتاح مواسير قابل للنشر ومفتاح سري للاتصال ببوابة الدفع"
                : "Add Moyaser Publishable Key and Secret Key to Connect Payment Gateway"}
              <img
                src={selectLanguage === "ar" ? step19Ar : step19}
                className="w-full md:w-[70%] m-auto my-2"
              />
            </li>
          </ul>
        </p>

        {/* step4 */}
        <p className="text-lg text-gray-600 my-3">
          <h4 className="my-1">
            {t("Step")} 4 :
            {selectLanguage === "ar"
              ? " أضف منتجك الأول : "
              : " Add your first product : "}{" "}
          </h4>
          <ul className="list-disc">
            <li className="my-3">
              {selectLanguage === "ar"
                ? "انقر على هذا الزر لإضافة المنتج الأول"
                : " Click on This Button to add your First Product"}
              <img
                src={selectLanguage === "ar" ? product1Ar : product1}
                className="w-full md:w-[70%] m-auto my-2"
              />
            </li>
            <img
              src={selectLanguage === "ar" ? product2Ar : product2}
              className="w-full md:w-[70%] m-auto my-3"
            />
          </ul>
        </p>

        {/* step5 */}
        <p className="text-lg text-gray-600 my-3">
          {" "}
          <h4 className="my-1">
            {t("Step")} 5 :{" "}
            {selectLanguage === "ar"
              ? "انقر على زر أستكشاف الحزم"
              : "Click on Explore Packages Button :"}{" "}
            <img
              src={selectLanguage === "ar" ? package1Ar : package1}
              className="w-full md:w-[90%] m-auto my-2"
            />
          </h4>
          <ul className="list-disc">
            <li className="my-3">
              {selectLanguage === "ar"
                ? "سيتم إعادة توجيهك إلى صفحة الحزم، يمكنك تحديد الحزمة المطلوبة"
                : "You will be redirected to Packages Page , you can select your desired package"}
              <img src={package2} className="w-full md:w-[70%] m-auto my-2" />
            </li>
          </ul>
        </p>
      </div>
    </>
  );
};

export default GuideDashboard;
