import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { toast } from "react-toastify";
import ApiLoadingSpinner from "../../../components/utils/ApiLoadingSpinner";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";
// import axios from "axios";

const VendorProfile = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [password, SetPassword] = useState("");
  const [btnAction, SetBtnAction] = useState(false);
  const [eye, seteye] = useState(true);
  const [passwordFi, setpasswordFi] = useState("password");
  const [images, setImages] = useState([]);
  const typePassword = (value) => {
    SetPassword(value);
    SetBtnAction(true);
    if (value.length < 1) {
      SetBtnAction(false);
    }
  };

  const Eye = () => {
    if (passwordFi === "password") {
      setpasswordFi("text");
      seteye(false);
    } else {
      setpasswordFi("password");
      seteye(true);
    }
  };

  const checkingPassword = (value) => {
    setErr(true);
    if (value === password) {
      setErr(false);
      SetBtnAction(false);
      setSuccess(true);
    } else {
      setSuccess(false);
      if (value.length < 1) {
        setErr(false);
      }
    }
  };

  useEffect(() => {
    const vendorData = async () => {
      if (user) {
        setValue("vendor_name", user.vendor_name);
        setValue("vendor_contact", user.vendor_contact);
        setValue("vendor_email", user.vendor_email);
      }
    };
    vendorData();
  }, [user]);

  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const file = images[0]?.file;
    const VendorData = new FormData();
    if (file) {
      VendorData.append("profile_image", file);
    }
    if (password) {
      if (password.length > 7) {
        VendorData.append("vendor_password", password);
      } else {
        setLoading(false);
        return toast.error("Password not correct");
      }
    }

    VendorData.append("id", user.vendor_id);
    VendorData.append("vendor_name", data.vendor_name);
    VendorData.append("vendor_contact", data.vendor_contact);
    VendorData.append("vendor_email", data.vendor_email);

    const res = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/updateprofile`,
      VendorData,
      {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200) {
      const newVendorData = response.data;
      newVendorData.token = user.token;
      localStorage.setItem("user", JSON.stringify(newVendorData));
      toast.success("Updated Profile!");
      // window.location.reload();
      setLoading(false);
    } else {
      toast.error("Both password Field Should Match");
      setLoading(false);
    }
  };

  const { t } = useTranslation();
  const selectLanguage = i18next.language;

  if (loading) {
    return <ApiLoadingSpinner />;
  }

  return (
    <div className="mb-20">
      <div className="max-w-xl mx-auto border bg-white mt-5">
        <div className="card-title border-b p-4">
          <h3 className="text-base font-medium">{t("Profile_Info")}</h3>
        </div>
        <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 px-4">
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorName">
                    {t("Name")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    {...register("vendor_name", { required: true })}
                    type="text"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={t("Name")}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorEmail">
                    {t("form_email")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    {...register("vendor_email", { required: true })}
                    type="email"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={t("form_email")}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorPhone">
                    {t("form_phone")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    {...register("vendor_contact", { required: true })}
                    type="number"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="+12345679"
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]">
                    {t("New")} {t("l_p")}
                  </label>
                </div>
                <div className="col-span-8 relative">
                  <input
                    onChange={(e) => typePassword(e.target.value)}
                    type={passwordFi}
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={`${t("New")} ${t("l_p")}`}
                    name="new-password"
                  />
                  <i
                    title="hide/unhide"
                    onClick={Eye}
                    className={`fa ${
                      eye ? "fa-eye-slash" : "fa-eye"
                    } cursor-pointer absolute ${
                      selectLanguage == "ar" ? "left-2" : "right-2"
                    } top-3`}
                  ></i>
                  <small className="text-[10px]">
                    {selectLanguage == "ar"
                      ? "اتركها فارغة إذا كنت لا ترغب في تغيير كلمة المرور"
                      : "Leave empty if you don't want to change password"}
                  </small>
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label
                    className="text-[13px]"
                    htmlFor="vendorConfirmPassword"
                  >
                    {t("l_c_p_p")}
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    onChange={(e) => checkingPassword(e.target.value)}
                    type={passwordFi}
                    name="confirm-password"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder={t("l_c_p_p")}
                  />

                  <small className="text-[10px]">
                    {selectLanguage == "ar"
                      ? "اتركها فارغة إذا كنت لا ترغب في تغيير كلمة المرور"
                      : "Leave empty if you don't want to change password"}
                  </small>
                  {err && (
                    <>
                      <br />
                      <small className="text-[12px] text-red-500">
                        {t("Password Not Match")}
                      </small>
                    </>
                  )}
                  {success && (
                    <>
                      <br />
                      <small className="text-[12px] text-green-500">
                        {selectLanguage == "ar"
                          ? "تم مطابقة كلمة المرور"
                          : "Password Matched"}
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="vendorPhoto">
                    {t("Photo")}
                  </label>
                </div>
                <div className="col-span-8">
                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <p
                          className="border text-sm cursor-pointer py-2 px-3"
                          style={isDragging ? { color: "red" } : undefined}
                          onClick={onImageUpload}
                          {...dragProps}
                        >
                          {selectLanguage == "ar"
                            ? "انقر أو اسحب هنا لتحميل الصورة"
                            : "Click or Drop here to Upload Image"}
                        </p>
                        &nbsp;
                        {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                        {images.length > 0
                          ? imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <div className="image-item__btn-wrapper">
                                  {/* <button onClick={() => onImageUpdate(index)}>Update</button> */}
                                  <button
                                    className="text-red-600"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                                <img
                                  className="w-20 h-20"
                                  src={image["data_url"]}
                                  alt=""
                                />
                              </div>
                            ))
                          : user?.profile_image && (
                              <div className="image-item">
                                <img
                                  className="w-20 h-20"
                                  src={user?.profile_image}
                                  alt=""
                                />
                              </div>
                            )}
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mx-auto pb-5">
            <button
              className={`py-1 px-3 text-white rounded cursor-pointer ${
                btnAction ? "bg-slate-500" : "bg-primary"
              } loading`}
              type="submit"
              title="Update Button"
              disabled={btnAction}
            >
              {t("Save")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VendorProfile;
