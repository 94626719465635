import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import updateData from '../../../hooks/updateData';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

// const Partners = ({ shipping, setShippingInfo, shippings }) => {
const Partners = ({ partner, setPartnerInfo, courierPartners }) => {
    const { t } = useTranslation()
    let selectLanguage = i18next.language;
    const [loading, setLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'))

    const updateshipping = async (id, event) => {
        event.preventDefault();

        // const data = shippings.find(item => item.setting_id === id);
        // const updData = { key_id: data.key_id, key_secret: data.key_secret, is_enabled: data.is_enabled, id: data.setting_id, torod_region, torod_city, torod_district }
        // const res = await updateData("vendor/updateshippingsetting", updData);
        // if (res.status_code === 200) {
        //     toast.success(res.message);
        // }
    }


    if (loading) {
        return <LoadingSpinner />
    }


    return (
        <div className=" border h-52 p-4 shadow rounded bg-white flex flex-col items-center">
            <img className="w-36 h-12 object-contain" src={partner.carrier_logo} alt="" />

            <h2 className="text-lg py-2 font-semibold">{selectLanguage === "ar" ? partner.title_arabic : partner.title}</h2>

            <div className="self-start w-full">
                {/* onSubmit={(e) => updateshipping(partner.id, e)} */}
                <div>
                    <ul className='mt-2'>
                        <li className=''>
                            <span title='Approximate Delivery Time' className='cursor-pointer'>* ADT : {selectLanguage === "ar" ? partner.approximate_delivery_time_arabic : partner.approximate_delivery_time}</span>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col gap-y-2">
                    <div className="grid grid-cols-4 gap-x-1 mt-3">
                        <label className="label cursor-pointer">
                            Active :
                        </label>
                        <input type="checkbox" onClick={(e) => setPartnerInfo(partner.id, partner.title, partner.title_arabic, Number(e.target.checked))} defaultChecked={partner?.is_enabled} className="toggle toggle-primary col-span-3 mt-2" />

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Partners;