import React, { useState } from "react";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import StoreFooter from "../../StoreTemplate/StoreComponents/StoreFooter";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import axios from "../../../components/utils/CustomerInterceptor";

const ChangePassword = () => {
  const [newPass, setNewPass] = useState();
  const [err, setErr] = useState(false);
  const customerInfo = JSON.parse(localStorage.getItem("customer"));
  const { t } = useTranslation();

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleToUpdatePassword = async (data) => {
    const res = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/store/customer/updateprofile`,
      JSON.stringify(data),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + customerInfo.token,
        },
      }
    );
    const response = await res.data;
    if (response.status_code === 200) {
      toast.success(
        "Password Update Successful, Please remind your new password"
      );
      reset();
      return;
    }
    toast.error(response.err);
  };

  const matchPass = (value) => {
    if (value !== "") {
      if (value === newPass) {
        setErr(false);
      } else {
        setErr(true);
      }
    }
  };
  return (
    <>
      <Helmet>
        <title>Change Password - Dashboard</title>
      </Helmet>
      <div className="flex flex-col min-h-[100vh]">
        <h2 className="text-title text-lg md:text-2xl pt-3">
          {t("Change_Password")}
        </h2>
        <div className="my-5 flex-1" style={{ flexGrow: 1 }}>
          <div className="p-4 border border-gray-200 bg-white shadow-sm w-full lg:max-w-md">
            <h2 className="text-title text-md md:text-xl pb-2 uppercase">
              {t("Update_Info")}
            </h2>
            <form
              onSubmit={handleSubmit(handleToUpdatePassword)}
              className="flex flex-col gap-4"
            >
              <div className="form-control w-full relative">
                <label className="label">
                  <span className="label-text">
                    {t("New")} {t("l_p")}{" "}
                    <span className="text-red-600">*</span>
                  </span>
                </label>
                <input
                  {...register("cust_password", { required: true })}
                  onChange={(e) => setNewPass(e.target.value)}
                  type={password}
                  placeholder={t("l_p")}
                  className="input input-bordered focus:outline-none focus:border-gray-300 rounded-none w-full h-[40px]"
                />
                <i
                  onClick={Eye}
                  className={`fa ${
                    eye ? "fa-eye-slash" : "fa-eye"
                  } cursor-pointer absolute right-2 top-12`}
                ></i>
                <label className="label">
                  {errors.password?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("l_p_r")}
                    </span>
                  )}
                </label>
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {t("Confirm")} {t("New")} {t("l_p")}
                    <span className="text-red-600">*</span>
                  </span>
                </label>
                <input
                  type={password}
                  onChange={(e) => matchPass(e.target.value)}
                  name="cust_Confirm_New_password"
                  placeholder={t("l_p")}
                  className="input input-bordered w-full rounded-none"
                />
              </div>
              {err && (
                <p className="text-red-500 mt-[-8px]">
                  {t("Password Not Match")}
                </p>
              )}
              <button
                type="submit"
                disabled={err}
                className="btn btn-primary w-full mt-5 text-white"
              >
                {t("Change_Password")}
              </button>
            </form>
          </div>
        </div>
        <StoreFooter />
      </div>
    </>
  );
};

export default ChangePassword;
