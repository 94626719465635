import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { AiFillEdit } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/utils/FilterComponent";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteDataById from "../../../hooks/deleteDataById";
import getDataById from "../../../hooks/getDataById";
import postData from "../../../hooks/postData";
import updateData from "../../../hooks/updateData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "../../../components/utils/Interceptors";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  maxHeight: "calc(100vh - 5em)",
};

const Size = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const selectLanguage = i18next.language;
  const navigate = useNavigate();
  const [sizes, setSizes] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = sizes.filter(
    (item) =>
      item.size_name &&
      item.size_name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);
    const getAllSizes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallsize`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setSizes(response.data);
      } else {
        setSizes([]);
      }
      setLoading(false);
    };
    getAllSizes();
  }, [refresh]);

  const columns = [
    {
      name: `${t("vd_pc_C_ID")}`,
      selector: (row, i) => i + 1,
      sortable: true,
    },

    {
      name: `${t("vd_ps_Size")}`,
      selector: (row) =>
        selectLanguage == "ar" && row.size_name_ar
          ? row.size_name_ar
          : row.size_name,
      sortable: true,
    },
    {
      name: `${t("vd_pc_C_Options")}`,
      sortable: true,
      cell: (item) => (
        <div className="flex gap-2">
          <label
            onClick={() => selectItemToEdit(item.size_id)}
            htmlFor="my-modal-4"
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:text-white cursor-pointer duration-300"
            title={`${t("Edit")}`}
          >
            <AiFillEdit />
          </label>

          <span
            onClick={() => deleteItem(item.size_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];

  // handlers
  const onSubmit = async (data) => {
    setLoading(true);
    const response = await postData("/vendor/createsize", data);

    if (response !== 0) {
      if (filteredItems) {
        filteredItems.push(response);
      } else {
        filteredItems = [response];
      }

      setSizes([...sizes, response]);
      setRefresh(!refresh);
    }
    setLoading(false);
    reset();
  };

  const deleteItem = async (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("vendor/deletesize", id);

            if (response.status_code === 200) {
              const newSizeList = sizes.filter((item) => item.size_id !== id);
              setSizes(newSizeList);
              filteredItems = filteredItems.filter(
                (item) => item.size_id !== id
              );
            }

            setLoading(false);
            setRefresh(!refresh);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };

  const [showEdit, setShowEdit] = useState(false);
  const selectItemToEdit = async (id) => {
    const response = await getDataById("vendor/getbyidsize", id);
    if (response.data) {
      setSelectedItem(response.data);
      setShowEdit(true);
    } else {
    }
  };

  // validations in edit size form
  const [editErr, setEditErr] = useState("");
  useEffect(() => {
    const regexName = /^(?=.{1,10}$)[a-zA-Z ,.'-]+$/i;

    if (regexName.test(selectedItem.size_name)) {
      setEditErr("");
    } else {
      setEditErr(
        "Size name must only contain alphabet letters with a maximum of 10 characters"
      );
    }
  }, [selectedItem]);

  const [editArabicErr, setEditArabicErr] = useState("");
  useEffect(() => {
    const regexName = /^[\u0600-\u06FF ,.'-]+$/i;

    if (regexName.test(selectedItem.size_name_ar)) {
      setEditArabicErr("");
    } else {
      setEditArabicErr("Size name must only contain arabic letters");
    }
  }, [selectedItem]);

  const submitEditForm = async (e) => {
    e.preventDefault();

    const data = {
      id: selectedItem.size_id,
      size_name: selectedItem.size_name,
      size_name_ar: selectedItem.size_name_ar,
    };

    if (editErr || editArabicErr) {
      console.log("no");
      // return ;
    } else {
      const response = await updateData("vendor/updatesize", data);
      if (response.status_code === 200) {
        const updatedList = sizes.map((c) => {
          if (c.size_id === selectedItem.size_id) {
            return response.data;
          }
          return c;
        });

        setSizes(updatedList);

        filteredItems = filteredItems.map((c) => {
          if (c.size_id === selectedItem.size_id) {
            return response.data;
          }
          return c;
        });

        setSelectedItem("");
        setShowEdit(false);
        toast.success("Updated Successfully");
      } else {
        toast.error(response?.error?.message);
      }
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl uppercase">
          {t("vd_ps_Sizes")}
        </h2>
        <button
          onClick={handleGoBack}
          className="mt-1 btn btn-primary btn-xs text-white flex justify-center items-center gap-x-2"
        >
          <FaArrowCircleLeft size={15} />
          {t("add_new_product_back")}
        </button>
      </div>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div>
            <form
              className="flex flex-col gap-4 mt-5"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("vd_ps_Size_n")}</span>
                </label>
                <input
                  {...register("size_name", {
                    required: true,
                    pattern: /^(?=.{1,10}$)[a-zA-Z ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.size_name?.type === "required" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Size name is required
                  </span>
                )}
                {errors.size_name?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Size name must only contain alphabet letters with a maximum
                    of 10 characters
                  </span>
                )}
              </div>
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("size_arabic")}</span>
                </label>
                <input
                  {...register("size_name_ar", {
                    pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.size_name_ar?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Size name must only contain arabic letters
                  </span>
                )}
              </div>
              <button className="btn btn-primary max-w-lg rounded-none text-white">
                {t("vd_pc_btn")}
              </button>
            </form>
          </div>
          <div className="col-span-2 md:p-5">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                // selectableRows
                pagination
                noDataComponent={
                  <p className="py-8 text-gray-400 text-center">
                    {t("No records")}
                  </p>
                }
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        open={showEdit}
        onClose={() => setShowEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="my-4"
      >
        <Box sx={style} className="w-11/12 max-w-[32rem] overflow-y-scroll">
          {selectedItem && (
            <div>
              <h3 className="text-lg font-bold">{t("vd_ps_e_tag")}</h3>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <form className="flex flex-col gap-4" onSubmit={submitEditForm}>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("vd_ps_Size")}</span>
                    </label>
                    <input
                      value={selectedItem?.size_name}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          size_name: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                      required
                    />
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editErr}
                    </span>
                  </div>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("size_arabic")}</span>
                    </label>
                    <input
                      value={selectedItem?.size_name_ar}
                      onChange={(e) =>
                        setSelectedItem({
                          ...selectedItem,
                          size_name_ar: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                    />
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editArabicErr}
                    </span>
                  </div>
                  <button
                    type="submit"
                    className={`btn btn-primary max-w-lg rounded-none text-white ${
                      loading && "loading"
                    }`}
                  >
                    {t("vd_pc_btn2")}
                  </button>
                </form>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Size;
