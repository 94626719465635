import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Footer2 from "../components/Footer/Footer2";
import Header from "../components/Header/Header";
import Div from "../components/utils/Div";
import SectionTitle from "../components/utils/SectionTitle";
import Aos from "aos";
import ContactUs from "../components/HomeComponents/ContactUs";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { domainProvider } from "../contexts/DomainContext";
import axios from "../../src/components/utils/Interceptors";
import Whatsapp from "../components/utils/WhatsappIcon";

const Help = () => {
  const customer = JSON.parse(localStorage.getItem("customer"));
  const [ganarel, setGanarel] = useState([]);
  const { t } = useTranslation();
  let selectLanguage = i18next.language;

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {
        headers: {
          Authorization: `Bearer ${customer ? customer.token : ""}`,
        },
      })
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  const { titleName } = useContext(domainProvider);
  return (
    <>
      <Helmet>
        <title>Help page - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header navigation={true} />
      <div className=" min-h-[100vh] flex flex-col">
        <div className="py-9 mx-auto max-w-[1280px] px-5 md:px-10 container">
          <SectionTitle page="help" />
        </div>
        <div className="md:w-9/12 mx-auto mb-8" style={{ flexGrow: 1 }}>
          <div
            tabIndex={0}
            className="collapse py-3 collapse-plus border-b border-base-300 bg-base-100 "
          >
            <div className="collapse-title text-xl font-bold text-[#236260]">
              {selectLanguage === "ar"
                ? "كيف تبدأ مع AI Tech ؟"
                : "How to start with Aitech ?"}
              {/* {t("help_head1")} */}
            </div>
            <div className="collapse-content text-[15px] text-black">
              <p>
                {selectLanguage === "ar"
                  ? "يمكنك إنشاء متجر جديد وفي قسم إعداد المتجر يمكنك إختيار طريقة الشحن وتحديد عنوان المتجر والتمكين والمزيد... يمكنك إنشاء متجر جديد وفي قسم إعداد المتجر يمكنك إختيار طريقة الشحن وتحديد عنوان المتجر والتمكين وأكثر من ذلك..."
                  : "you can create a new store and in store setting section you can choose shipping method and select your store address and enable and many more..."}
              </p>
              {/* {t("help_content1")} </p> */}
            </div>
          </div>
          <div
            tabIndex={0}
            className="collapse py-3 collapse-plus border-b border-base-300 bg-base-100"
          >
            <div className="collapse-title text-xl font-bold text-[#236260]">
              {selectLanguage === "ar"
                ? "لم يتم العثور على الاجابه؟ الرجاء إرسال بريد إلكتروني."
                : "Not found Ans? Please send A email."}
              {/* {t("help_head2")} */}
            </div>
            <div className="collapse-content">
              <ContactUs ganarel={ganarel} />
            </div>
          </div>
        </div>
        <Whatsapp />
        <Footer2 />
      </div>
    </>
  );
};

export default Help;
