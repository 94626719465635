import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useContext } from "react";
import { userProvider } from "../../contexts/UserContext";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import ShoppingPng3 from "../../assets/Woman_Shopping.png";
import Footer2 from "../../components/Footer/Footer2";
import Header from "../../components/Header/Header";
import Div from "../../components/utils/Div";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "axios";
import Aos from "aos";

const Login = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const [contactLogin, setContactLogin] = useState(false);
  const { refetchUser } = useContext(userProvider);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");

  const Eye = () => {
    if (password == "password") {
      setpassword("text");
      seteye(false);
    } else {
      setpassword("password");
      seteye(true);
    }
  };

  useEffect(() => {
    var user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (
      user && user.token
        ? user.token
        : user && user.refreshToken
        ? user.refreshToken
        : ""
    ) {
      navigate("/vendor/main-flow");
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    if (customdomain === "aitech.com.sa") {
      await axios
        .post(
          `${process.env.REACT_APP_APIURL}/vendor/login`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const result = res.data;
          if (result.status_code === 200) {
            const {
              vendor_id,
              vendor_name,
              vendor_email,
              vendor_contact,
              profile_image,
            } = result.data;
            const token = result.accessToken;
            const refreshToken = result.refreshToken;
            const user = {
              vendor_id,
              vendor_name,
              vendor_email,
              vendor_contact,
              profile_image,
              token,
              refreshToken,
              storename: result.storeDetails.store_name
            };
            localStorage.setItem("user", JSON.stringify(user));
            navigate("/vendor/main-flow");
            refetchUser();
            window.location.reload();
          } else {
            toast.error(result.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          const error = err?.response?.data?.message;
          toast.error(error);
        });
    } else {
      await axios
        .post(
          `${process.env.REACT_APP_APIURL}/vendor/login`,
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
              domain_name: customdomain,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          const result = res.data;
          if (result.status_code === 200) {
            const {
              vendor_id,
              vendor_name,
              vendor_email,
              vendor_contact,
              profile_image,
            } = result.data;
            const token = result.accessToken;
            const refreshToken = result.refreshToken;
            const user = {
              vendor_id,
              vendor_name,
              vendor_email,
              vendor_contact,
              profile_image,
              token,
              refreshToken,
              storename: result.storeDetails.store_name
            };
            localStorage.setItem("user", JSON.stringify(user));

            navigate("/vendor/main-flow");
            refetchUser();
            window.location.reload();
          } else {
            toast.error(result.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          const error = err?.response?.data?.message;
          toast.error(error);
        });
    }
  };
  const [ganarel, setGanarel] = useState([]);
  const { customdomain, titleName } = useContext(domainProvider);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign In - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header />
      <Div
        className=""
        id="privacyPolicy"
        style={{ direction: `${selectLanguage === "ar" ? "rtl" : "ltr"}` }}
      >
        <div className=" grid grid-cols-1 md:grid-cols-3 place-content-center mx-auto mt-7">
          <div
            className={`flex-1 mx-auto ${
              selectLanguage === "ar" ? "md:border-l-4" : "md:border-r-4"
            } border-gray-200  px-2 w-full`}
          >
            <form
              className="flex flex-col gap-y-2 py-5 justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="text-center text-2xl md:text-3xl">
                {selectLanguage == "ar" ? "تسجيل دخول المتجر" : "Store Login"}
              </h2>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {" "}
                    {contactLogin ? `${t("l_phone")}` : `${t("l_email")}`}
                  </span>
                  <span
                    onClick={() => setContactLogin(!contactLogin)}
                    className="text-xs cursor-pointer text-primary"
                  >
                    {t("v_l_f1")}{" "}
                    {contactLogin ? `${t("l_email")}` : `${t("l_phone")}`} ?
                  </span>
                </label>
                {contactLogin ? (
                  <input
                    {...register("username", { required: true })}
                    type="number"
                    placeholder={`${t("l_phone_p")}`}
                    className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                    required
                  />
                ) : (
                  <input
                    {...register("username", { required: true })}
                    type="email"
                    placeholder={`${t("l_phone_m")}`}
                    className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                    required
                  />
                )}
              </div>

              <div className="form-control w-full relative">
                <label className="label">
                  <span className="label-text">{t("l_p")}</span>
                </label>
                <input
                  {...register("password", { required: true })}
                  type={password}
                  placeholder={`${t("l_p_p")}`}
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                />
                <i
                  onClick={Eye}
                  className={`fa ${
                    eye ? "fa-eye-slash" : "fa-eye"
                  } cursor-pointer absolute ${
                    selectLanguage === "ar" ? "left-2" : "right-2"
                  }  top-11`}
                ></i>
                <label className="label">
                  {errors.password?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      {t("l_p_r")}
                    </span>
                  )}
                </label>
              </div>

              <div className="form-control w-full">
                <button
                  type="submit"
                  className={`btn py-2 cursor-pointer btn-primary text-white w-full rounded-none ${
                    loading && "loading"
                  }`}
                >
                  {t("v_l_f")}
                </button>
              </div>

              <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                  <input
                    id="remember"
                    type="checkbox"
                    className="checkbox checkbox-xs rounded-none"
                  />

                  <label htmlFor="remember" className="label-text">
                    {t("l_p_remember")}
                  </label>
                </div>
                <div>
                  <label className="label-text text-primary">
                    <Link to="/vendor/forget-pass">{t("l_p_forget")}</Link>
                  </label>
                </div>
              </div>
            </form>

            <div className="flex flex-col w-full">
              <div className="divider">{t("l_p_haveAccount")}</div>

              <Link
                className="border border-px border-primary py-2 flex justify-center items-center text-primary hover:bg-primary hover:!text-white duration-300 transition-all"
                to="/vendor/register"
              >
                {t("l_p_create")}
              </Link>
            </div>
          </div>

          <div className="hidden md:flex justify-end items-start col-span-2">
            <img
              src={ShoppingPng3}
              className=" max-w-md lg:max-w-xl text-center"
              alt=""
            />
          </div>
        </div>
      </Div>
      <Footer2 />
    </>
  );
};

export default Login;
