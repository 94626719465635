import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { toast } from "react-toastify";
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import axios from "../../../components/utils/Interceptors"

const Payout = () => {
    const { t } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [earnings, setEarnings] = useState([]);
    const user = JSON.parse(localStorage.getItem('user'))

    useEffect(() => {
        setLoading(true)
        const fetchearnings = async () => {
            const baseUrl = process.env.REACT_APP_APIURL;
            const res = await axios.get(`${baseUrl}/vendor/gettotalearnings`, {

                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const response = await res.data;

            if (response?.status_code === 200) {
                setEarnings(response.data);
            }
            else {
                return setEarnings([])
            }
        }
        fetchearnings();
        setLoading(false)
    }, [])

    const columns = [
        {
            name: `${t("order_table4")}`,
            selector: row => row.created_on,
            sortable: true,
        },
        {
            name: `${t("Total_Earnings")}`,
            selector: row => row.total,

        }
    ];

    const [filterText, setFilterText] = useState('');
    let filteredItems = earnings?.filter(
        item => item?.created_on?.includes(filterText.toLowerCase()),
    );

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <div>
                <span className="text-xl font-medium">{t("income_total")}</span>
                <div className="my-5 bg-white border rounded">

                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        noDataComponent={
                            <h4 className="py-8 text-gray-400 text-center">
                              {t("No records")}
                            </h4>
                          }
                    />
                </div>
            </div>

        </div>
    );
};

export default Payout;