import React from 'react';
import { Navigate } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import LoadingSpinner from './LoadingSpinner';

const PrivateRoute = ({ children }) => {

    const [user, userLoading] = useUser();

    if (userLoading) {
        return <LoadingSpinner />
    }
    if(user?.vendor_email){
        return children
    }else{
        return <Navigate to='/vendor/login' />
    }
};

export default PrivateRoute;