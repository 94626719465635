import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "../../../components/utils/CustomerInterceptor";

const OrderDetails = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const [products, setProducts] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const componentRef = useRef();
  const handleToPrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Order Details",
    onAfterPrint: () => toast("print Success"),
  });

  useEffect(() => {
    const getAllProductById = async () => {
      const userInfo = JSON.parse(localStorage.getItem("customer"));
      const userInfoVendor = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get(
        // `${process.env.REACT_APP_APIURL}/vendor/store/customer/getbyidorder/57`, {
        `${process.env.REACT_APP_APIURL}/vendor/store/customer/getbyidorder/${id}`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${userInfo?.token || userInfoVendor?.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        setProducts(response.product_details);
        setData(response.data);
      }
      setLoading(false);
    };
    getAllProductById();
  }, [id]);

  const handleBack = () => {
    window.history.go(-1);
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  
  return (
    <div className="">
      <div ref={componentRef} className="mt-5 w-11/12 mx-auto">
        <div className="text-center text-3xl md:text-5xl font-bold mb-12">
          {t("Order_Details")}
        </div>
        <div className="flex flex-col md:flex-row justify-between mx-auto">
          <div>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">
                {t("order_table4")} :
              </span>{" "}
              <span> {data?.updated_on.slice(0, 10)}</span>
            </p>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">
                {t("Payment_ID")} :{" "}
              </span>{" "}
              <span> {data?.transaction_id}</span>
            </p>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">{t("l_email")} :</span>{" "}
              <span> {data?.customer_email}</span>
            </p>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">{t("Contact")} :</span>{" "}
              <span> {data?.customer_contact}</span>
            </p>
          </div>
          <div>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">
                {t("Order_Number")} :
              </span>{" "}
              <span>{data?.torod_order_id}</span>
            </p>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">{t("Quantity")} :</span>{" "}
              <span> {products?.length}</span>
            </p>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">
                {t("Total_Price")} :
              </span>{" "}
              <span> {data?.order_price}</span>
            </p>
            <p className="flex justify-between mb-1">
              <span className="font-bold mr-2 md:mr-5">{t("Store_Id")} :</span>{" "}
              <span> {data?.store_id}</span>
            </p>
          </div>
        </div>
        <div className="py-10 px-6 my-8 rounded-md bg-white">
          {products?.map((product, idx) => (
            <div key={idx}>
              <div className="flex justify-between items-center my-3">
                <div className="avatar">
                  <div className="w-12 md:w-24 rounded-xl">
                    {!product?.thumbnail_image ? (
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRFW55TsH5WO6tvaq2XA1cmgrSD0Z9bZOfM9Q&usqp=CAU"
                        alt=""
                      />
                    ) : (
                      <img src={product?.thumbnail_image} alt="" />
                    )}
                  </div>
                </div>
                <div>
                  <p>
                    <span className="uppercase md:font-bold ">
                      {selectLanguage === "ar" && product.product_name_ar
                        ? product.product_name_ar
                        : product.product_name}
                    </span>
                  </p>
                  <p>
                    <span className="md:font-bold">Color: </span>{" "}
                    <span className="font-semibold">
                      {selectLanguage === "ar" && product.color_name_ar
                        ? product.color_name_ar
                        : product.color_name}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <span>Qty: </span> <span>{product.product_quantity}</span>
                  </p>
                </div>
                <div>
                  <p>
                    {" "}
                    <span>
                      Rs {product.product_quantity * product.product_price}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center my-6">
        <div>
          <button
            onClick={handleBack}
            className={`btn btn-primary text-white font-bold ${
              selectLanguage === "ar" ? "ml-3" : "mr-3"
            }`}
          >
            {t("add_new_product_back")}
          </button>
          <button
            onClick={handleToPrint}
            className="btn btn-primary text-white font-bold"
          >
            {t("Download_Receipt")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
