import React from "react";

const FilterComponent = ({
  filterText,
  setFilterText,
  onFilter,
  onClear,
  placeholder,
}) => (
  <>
    <input
      className="input input-bordered rounded-none input-sm"
      id="search"
      type="text"
      placeholder={placeholder || "Filter by Name"}
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />
    <button
      className="btn btn-sm btn-error rounded-none text-white"
      type="button"
      onClick={onClear}
    >
      X
    </button>
  </>
);
export default FilterComponent;
