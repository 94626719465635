import axios from "../components/utils/Interceptors";

const getAll = async (url) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const baseUrl = `${process.env.REACT_APP_APIURL}/${url}`;

  const res = await axios.get(`${baseUrl}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  });

  const response = await res.data;

  return response;
};

export default getAll;
