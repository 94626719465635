const deleteDataById = async (url, id) => {
  const user = JSON.parse(localStorage.getItem("admin"));

  const baseUrl = `${process.env.REACT_APP_APIURL}/${url}/${id}`;

  const res = await fetch(`${baseUrl}`, {
    method: "DELETE",

    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  });

  const response = await res.json();

  return response;
};

export default deleteDataById;
