import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import { Link } from "react-router-dom";
import FilterComponent from "../../../components/utils/FilterComponent";
import deleteItem from "../../../hooks/admin/deleteItem";
import { useTranslation } from "react-i18next";
import getAllAdmin from "../../../hooks/getAllAdmin";

const AllPlans = () => {
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  let filteredItems = plans.filter((item) =>
    item.plan_name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    const getAllPlans = async () => {
      const res = await getAllAdmin("admin/getallplan");

      if (res.status_code === 200) {
        setPlans(res.data);
      }
    };
    getAllPlans();
  }, []);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center gap-2 flex-wrap my-5">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: `${t("Name")}`,
      selector: (row) => row.plan_name,
    },
    {
      name: `${t("Price")}`,
      selector: (row) => row.plan_price,
    },

    {
      name: `${t("Product_Limit")}`,
      selector: (row) => row.product_limit,
    },
    {
      name: `${t("Validity")}`,
      selector: (row) => row.no_of_days + " Days",
    },

    {
      name: `${t("Options")}`,

      cell: (item) => (
        <div className="flex gap-2">
          <Link
            to={`/admin/plan/edit/${item.plan_id}`}
            className="p-2 flex items-center border border-success hover:bg-success rounded-full text-success hover:text-white cursor-pointer duration-300"
            title={`${t("Edit")}`}
          >
            <AiTwotoneEdit />
          </Link>
          <span
            onClick={() =>
              deleteItem(
                item.plan_id,
                plans,
                setPlans,
                filteredItems,
                "plan_id",
                "admin/deleteplan"
              )
            }
            className="p-2 flex items-center border border-primary hover:bg-primary rounded-full text-primary hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <AiFillDelete />
          </span>
        </div>
      ),
    },
  ];

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-title text-lg md:text-2xl pb-2">
          {t("All_Plans")}
        </h2>
        <Link
          to="/admin/plan/create"
          className="py-2 text-sm px-4 bg-primary text-white rounded font-normal buttonLink"
        >
          {t("Create_New_Plan")}
        </Link>
      </div>
      <div>
        <div className="my-2"></div>

        <DataTable
          columns={columns}
          data={filteredItems}
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          pagination
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
};

export default AllPlans;
