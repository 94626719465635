import axios from "../components/utils/Interceptors";

const getStoreDetails = async () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const baseUrl = `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`;
 
  const res = await axios.get(`${baseUrl}`, {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.token}`,
    },
  });

  const response = await res.data;
  
  return response;
};

export default getStoreDetails;
