import React from "react";
import listProduct1 from "../../../assets/guide/listProduct1.jpg";
import listProduct2 from "../../../assets/guide/listProduct2.jpg";
import listProduct1Ar from "../../../assets/guide/listProduct1-ar.jpg";
import listProduct2Ar from "../../../assets/guide/listProduct2-ar.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const ProductList = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
          {t("Product List")}
        </h1>
        <ul className="list-disc">
          <li className="text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "في قائمة المنتجات، ستجد جميع المنتجات الإجمالية."
              : "In the Product List you will find all your Total Products."}
          </li>
          <li className="text-lg text-gray-600 my-3">
            {selectLanguage === "ar"
              ? "بعد النقر فوق إضافة منتجات، يمكنك إضافة التفاصيل الخاصة بها."
              : `After clicking on Add New Product you can add their details.`}
            <img src={selectLanguage === "ar" ? listProduct1Ar: listProduct1} className="w-full md:w-[60%] m-auto my-2" />
          </li>
          <li className="text-lg text-gray-600 my-3">
            <b>{t("Import Product")} : </b>{" "}
            {selectLanguage === "ar"
              ? "يمكنك إستيراد منتجاتك في نموذج تنسيق ورقة Excel."
              : "You can Import Your Products in the given sample format of Excel sheet."}
            <img src={selectLanguage === "ar" ? listProduct2Ar: listProduct2} className="w-full md:w-[60%] m-auto my-2" />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProductList;
