import moment from "moment";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  AiFillAlert,
  AiFillCheckCircle,
  AiFillDelete,
  AiFillMinusCircle,
  AiTwotoneEdit,
} from "react-icons/ai";

import { Link } from "react-router-dom";
import ApiLoadingSpinner from "../../../components/utils/ApiLoadingSpinner";
import FilterComponent from "../../../components/utils/FilterComponent";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteItem from "../../../hooks/admin/deleteItem";
import updateData from "../../../hooks/admin/updateData";
import { useTranslation } from "react-i18next";
import getAllAdmin from "../../../hooks/getAllAdmin";
import { toast } from "react-toastify";

const Vendors = () => {
  const { t } = useTranslation();
  const [vendors, setVendors] = useState([]);
  const [selectedData, setSelectedData] = useState();
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = vendors.filter(
    (item) =>
      item.vendor_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.vendor_email.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);

    const getAllData = async () => {
      const res = await getAllAdmin("admin/getallvendor");
      if (res.status_code === 200) {
        setVendors(res.data);
      }
      setLoading(false);
    };
    getAllData();
  }, []);

  const columns = [
    {
      name: `${t("Vendor_Name")}`,
      selector: (row) => row.vendor_name,
      sortable: true,
    },

    {
      name: `${t("form_email")}`,
      selector: (row) => row.vendor_email,
      sortable: true,
    },

    {
      name: `${t("l_phone")}`,
      selector: (row) => row.vendor_contact,
      sortable: true,
    },

    {
      name: `${t("Joined_Date")}`,
      selector: (row) => row.created_on,
      sortable: true,
      format: (row) => moment(row.created_on).format("DD-MMM-YYYY"),
    },
    {
      name: `${t("all_product_dataTable6")}`,
      key: "action",
      text: "Action",
      className: "action",
      width: 100,
      align: "left",
      sortable: false,
      cell: (item) => {
        return (
          <>
            <div className="flex gap-2">
              <Link
                to={`/admin/vendor/edit/${item.vendor_id}`}
                className="p-2 flex items-center border border-success hover:bg-success rounded-full text-success hover:text-white cursor-pointer duration-300"
                title={`${t("Edit")}`}
              >
                <AiTwotoneEdit />
              </Link>
              <span
                onClick={() =>
                  deleteItem(
                    item.vendor_id,
                    vendors,
                    setVendors,
                    filteredItems,
                    "vendor_id",
                    "admin/deletevendor"
                  )
                }
                className="p-2 flex items-center border border-primary hover:bg-primary rounded-full text-primary hover:text-white cursor-pointer duration-300"
                title={`${t("Delete")}`}
              >
                <AiFillDelete />
              </span>
              {item.status === 0 ? (
                <span
                  onClick={() =>
                    changeVendorStatus(item.vendor_id, item.status)
                  }
                  className="p-2 flex items-center border border-green-700 hover:bg-green-700 rounded-full text-green-700 hover:text-white cursor-pointer duration-300"
                  title={`${t("unsuspend")}`}
                >
                  <AiFillCheckCircle />
                </span>
              ) : (
                <span
                  onClick={() =>
                    changeVendorStatus(item.vendor_id, item.status)
                  }
                  className="p-2 flex items-center border border-red-700 hover:bg-red-700 rounded-full text-red-700 hover:text-white cursor-pointer duration-300"
                  title={`${t("Suspend")}`}
                >
                  <AiFillMinusCircle />
                </span>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const changeVendorStatus = async (id, status) => {
    setLoading(true);
    const statusValue = status ? 0 : 1;
    const res = await updateData("admin/updatevendor", {
      vendor_id: id,
      status: statusValue,
    });

    if (res.status_code === 200) {
      toast.success(res.message)
      const newVendors = vendors.map((v) => {
        if (v.vendor_id === id) {
          return { ...v, status: statusValue };
        }
        return v;
      });

      setVendors(newVendors);
      filteredItems = filteredItems.map((v) => {
        if (v.vendor_id === id) {
          return { ...v, status: statusValue };
        }
        return v;
      });

      setLoading(false);
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex items-center gap-2 flex-wrap my-2">
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          placeholder={`${t("Filter_by_name")}`}
        />
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (state) => {
    setSelectedData(state.selectedRows);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h2 className="text-title text-lg md:text-2xl py-2">{t("Vendors")}</h2>

      <div className="dashboard-box">
        <DataTable
          columns={columns}
          data={filteredItems}
          // selectableRows
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          onSelectedRowsChange={handleChange}
        />
      </div>
    </>
  );
};

export default Vendors;
