import React from "react";
import Pagination from "../../../components/utils/Pagination";
import UploadFileModal from "./UploadFileModal";

const SelectableMedia = ({
  currentItems,
  itemsPerPage,
  deleteItem,
  files,
  paginate,
  currentPage,
  setThumbnailImage,
  thumbnail_image,
  gallery_images,
  setGalleryImages,
  gallerySelect,
}) => {
  const selectImage = (imgUrl) => {
    if (gallerySelect) {
      const exist = gallery_images.find((item) => item === imgUrl);
      if (exist) {
        const newGalleryImages = gallery_images.filter(
          (item) => item !== imgUrl
        );
        setGalleryImages(newGalleryImages);
      } else {
        setGalleryImages([...gallery_images, imgUrl]);
      }
    } else {
      setThumbnailImage(imgUrl);
    }
  };
  return (
    <div>
      <div className="flex justify-between items-center">
        <h2 className="text-title text-sm md:text-base font-medium roboto">
          All Uploaded Files
        </h2>
        {/* <label htmlFor="upload-file-modal" className="py-2 px-3 font-medium text-white rounded bg-primary modal-button cursor-pointer">
                        Upload New Files
                    </label> */}
      </div>

      <div className="my-5 bg-white border rounded">
        <div className="header p-4 border-b md:px-8">
          <h2 className="text-title text-sm md:text-base font-medium roboto">
            All Files
          </h2>
        </div>

        <div className="body uploaded-files p-4">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3">
            {currentItems.map((file, i) => (
              <div
                key={i}
                onClick={() => selectImage(file.url)}
                className={`single p-2 border rounded cursor-pointer ${
                  thumbnail_image === file.url && "border-primary"
                } ${gallery_images?.includes(file.url) && "border-primary"}`}
              >
                <figure className="bg-gray-200 border-b relative">
                  <img
                    className="object-contain w-40 h-32 mx-auto text-center"
                    src={file.url}
                    alt=""
                  />
                </figure>

                <div className="title pt-2">
                  <h2 className="text-xs md:text-sm font-semibold">
                    <span className="truncate text-ellipsis">
                      {file.key.split(".")[0].slice(13, 20)}... .
                      {file.key.split(".")[1]}
                    </span>
                  </h2>
                  <small className="text-gray-400 text-[10px]">745kb</small>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={files.length}
        paginate={paginate}
        currentPage={currentPage}
      />

      {/* upload file modal */}
      <UploadFileModal />
    </div>
  );
};

export default SelectableMedia;
