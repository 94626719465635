import React from "react";
import { useForm } from "react-hook-form";

const PayoutSettings = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  return (
    <div className="mb-20">
      <h2 className="font-medium">Money Withdraw Settings</h2>
      <div className="max-w-xl mx-auto border bg-white mt-5">
        <div className="card-title border-b p-4">
          <h3 className="text-base font-medium">Payment Settings</h3>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="py-5 px-4">
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="cashPayment">
                    Cash payment
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="checkbox"
                    className="toggle toggle-sm toggle-primary"
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="bankPayment">
                    Bank payment
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="checkbox"
                    className="toggle toggle-sm toggle-primary"
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="couponCode">
                    Bank Name
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="text"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="Bank Name"
                    {...register("bankName")}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="bankAccountName">
                    Bank Account Name
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="number"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="Account Name"
                    {...register("bankAccountName")}
                  />
                </div>
              </div>
            </div>
            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="bankAccountNo">
                    Bank Account Number
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="number"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="Account Number"
                    {...register("bankAccountNo")}
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-5">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-2">
                <div className="col-span-4">
                  <label className="text-[13px]" htmlFor="bankRoutingNo">
                    Bank Routing Number
                  </label>
                </div>
                <div className="col-span-8">
                  <input
                    type="number"
                    className="w-full h-[40px] input input-bordered rounded"
                    placeholder="Routing Number"
                    {...register("bankRoutingNo")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="text-center mx-auto pb-5">
            <input
              className="py-1 px-3 bg-primary text-white rounded cursor-pointer"
              type="submit"
              value="Update Withdrawal Settings"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default PayoutSettings;
