import React, { useContext } from "react";
import van from "../../../assets/icons/van.png";
import customerServiceIcon from "../../../assets/icons/customer-service.png";
import cashIcon from "../../../assets/icons/money.png";
import "./HeroSection.css";
import slider1 from "../../../assets/slider/slider1.jpg";
import slider2 from "../../../assets/slider/slider2.jpg";
import slider1Ar from "../../../assets/slider/slider1Ar.jpg";
import slider2Ar from "../../../assets/slider/slider2Ar.jpg";
import image1 from "../../../assets/slider/image1.jpg";
import image2 from "../../../assets/slider/image2.jpg";

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { domainProvider } from "../../../contexts/DomainContext";

const ShopHeroSection = ({ storeName, slider }) => {
  const selectLanguage = i18next.language;
  const data = [
    {
      attribute_image: `${selectLanguage === "ar" ? slider1Ar : slider1}`,
    },
    {
      attribute_image: `${selectLanguage === "ar" ? slider2Ar : slider2}`,
    },
    // {
    //   attribute_image: slider1,
    // },
  ];

  const { customdomain } = useContext(domainProvider);

  return (
    <>
      <div className="">
        <div className="h-full w-full">
          {slider && slider.length > 0 ? (
            <Swiper
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              slidesPerView={1}
              spaceBetween={30}
              pagination={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              {slider.map((sil, idx) => (
                <SwiperSlide key={idx}>
                  {/* <figure className="w-full h-full">
                    <img
                      className="object-cover"
                      src={sil.attribute_image}
                      alt="blazer"
                    />
                  </figure> */}
                  <div
                    style={{ background: `url(${sil.attribute_image})` }}
                    className="w-full flex flex-col-reverse lg:flex-row items-center h-full"
                  >
                    <div
                      className={`absolute bottom-24 w-full z-10`}
                    >
                      {/* <p className="uppercase font-[Playfair Display] text-3xl lg:text-4xl leading-[40px] lg:leading-[60px] text-black sliderText font-semibold lg:font-normal text-left">
                        {selectLanguage === "ar" &&
                        sil?.attribute_description_ar
                          ? sil.attribute_description_ar
                          : sil?.attribute_description
                          ? sil.attribute_description
                          : "Explore amazing fashionable wear"}
                      </p> */}
                      <button className="uppercase rounded">
                        <Link
                          to={`${
                            customdomain === "aitech.com.sa"
                              ? `/${storeName}/shop`
                              : `/shop`
                          }`}
                        >
                          <span className="bg-[#591BA4] transition-all hover:bg-[#4a2676] text-white p-2 sm:p-3 text-sm sm:text-base lg:text-lg px-2 sm:px-6 rounded-full">
                            {selectLanguage === "ar" ? "تسوق الآن" : "Shop now"}
                          </span>
                        </Link>
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              slidesPerView={1}
              spaceBetween={30}
              pagination={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <Slider1 />
              </SwiperSlide>

              <SwiperSlide>
                <Slider2 />
              </SwiperSlide>
              {/* {list.map((item, idx) => (
                <SwiperSlide key={idx}>
                  <img
                      className="object-cover"
                      src={sil.attribute_image}
                      alt="blazer"
                    /> 
                </SwiperSlide>
              ))} */}
            </Swiper>
          )}
        </div>

        {/* <div className="Container mt-8">
          <div className="px-5 grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-10">
            <div className="flex items-center py-3 lg:border-r-2 border-gray-800">
              <div className="w-[50px] h-[50px] mr-5">
                <img
                  className="w-full h-full hover:animate-pulse"
                  src={van}
                  alt="van"
                />
              </div>
              <div className={` ${selectLanguage === "ar" ? "mr-4" : "mr-0"}`}>
                <h3 className="text-xl text-gray-800 font-domain font-bold">
                  {selectLanguage === "ar" ? "الشحن الأول" : "First Shipping"}
                </h3>
                <p className="text-gray-600 text-sm my-1">
                  {selectLanguage === "ar"
                    ? "الشحن الأول منخفض التكلفة"
                    : "Low cost first shipping"}
                </p>
              </div>
            </div>
            <div className="flex items-center py-3 lg:border-r-2 border-gray-800">
              <div className="w-[50px] h-[50px] mr-5">
                <img
                  className="w-full h-full hover:animate-pulse"
                  src={customerServiceIcon}
                  alt="van"
                />
              </div>
              <div className={` ${selectLanguage === "ar" ? "mr-4" : "mr-0"}`}>
                <h3 className="text-xl text-gray-800 font-domain font-bold">
                  {" "}
                  {selectLanguage === "ar" ? "دعم العملاء" : "Customer Support"}
                </h3>
                <p className="text-gray-600 text-sm my-1">
                  {selectLanguage === "ar"
                    ? "دعم عملاء 24*7"
                    : "24*7 customer support"}
                </p>
              </div>
            </div>
            <div className="flex items-center py-3">
              <div className="w-[50px] h-[50px] mr-5">
                <img
                  className="w-full h-full hover:animate-pulse"
                  src={cashIcon}
                  alt="van"
                />
              </div>
              <div className={` ${selectLanguage === "ar" ? "mr-4" : "mr-0"}`}>
                <h3 className="text-xl text-gray-800 font-domain font-bold">
                  {selectLanguage === "ar"
                    ? "النقد عند التسليم"
                    : "Cash On Delivery"}
                </h3>
                <p className="text-gray-600 text-sm my-1">
                  {selectLanguage === "ar"
                    ? "النقدية في خدمة التسليم المتوفرة"
                    : "Cash on delivery service available"}
                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default ShopHeroSection;

function Slider1() {
  const selectLanguage = i18next.language;
  return (
    <div className="w-full bg-white flex flex-col-reverse lg:flex-row items-center justify-center h-96">
      <div className="absolute border-2 border-[#591BA4] lg:static flex flex-col justify-center items-center w-11/12 lg:w-1/2 lg:min-w-1/2 h-80 lg:h-[240px] lg:ml-6 lg:max-w-1/2 2xl:max-w-[40%] z-10 ">
        <p className="uppercase font-[Playfair Display] text-3xl lg:text-4xl text-center leading-[60px] text-black sliderText font-semibold lg:font-normal">
          {selectLanguage === "ar" ? "استكشاف مذهلة" : "Explore amazing"}
          <br /> {selectLanguage === "ar" ? "ملابس عصرية" : "fashionable wear"}
        </p>
        <div className="uppercase my-8">
          <span className="bg-[#591BA4] text-white p-2 sm:p-3 text-sm sm:text-base lg:text-lg border-2 border-black px-2 sm:px-6">
            {selectLanguage === "ar" ? "تسوق الآن" : "Shop now"}
          </span>
          <span
            className={`p-2 sm:p-3 text-sm sm:text-base lg:text-lg border-2 ${
              selectLanguage === "ar" ? "border-r-0" : "border-l-0"
            } border-black px-2 sm:px-6`}
          >
            {selectLanguage === "ar"
              ? "أعلى مبيعات هذا الأسبوع"
              : "Top sale on this week"}
          </span>
        </div>
      </div>
      <img
        src={image1}
        className="w-full lg:w-2/5 sliderImg opacity-80 lg:opacity-100
   z-5"
      />
    </div>
  );
}

function Slider2() {
  const selectLanguage = i18next.language;
  return (
    <div className="w-full bg-white h-96">
      {selectLanguage != "ar" ? (
        <p
          className={`uppercase font-[Playfair Display] text-xl sm:text-2xl lg:text-3xl text-left leading-[60px] sliderText font-semibold absolute bottom-[-50px] text-[#591BA4] ${
            selectLanguage === "ar" ? "mr-2 sm:mr-8 right-0" : "ml-2 sm:ml-8"
          } w-full`}
        >
          {selectLanguage === "ar"
            ? "أعلى المبيعات في هذا الأسبوع"
            : "Top Sales On This Week"}
          <span className="border w-4/5 absolute bottom-7 sm:bottom-4 ml-3 border-[#591BA4]" />
        </p>
      ) : (
        <div className="w-full absolute bottom-[-50px]">
          <div className="flex flex-row-reverse items-center">
            <div className="border lg:w-11/12 w-[70%] border-[#591BA4]" />
            <p
              className={`uppercase font-[Playfair Display] text-xl sm:text-2xl lg:text-3xl sliderText font-semibold text-[#591BA4] ${
                selectLanguage === "ar" ? "mr-2 sm:mr-8" : "ml-2 sm:ml-8"
              } text-right`}
            >
              {selectLanguage === "ar"
                ? "أعلى المبيعات في هذا الأسبوع"
                : "Top Sales On This Week"}
            </p>
          </div>
        </div>
      )}
      <img
        src={image2}
        className="w-full
   z-5"
      />
    </div>
  );
}
