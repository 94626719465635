import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-select-search/style.css";
import ImageUploading from "react-images-uploading";
import Select from "react-select";
import { useEffect } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import i18next from "i18next";
import getDataById from "../../../hooks/getDataById";
import axios from "../../../components/utils/Interceptors";

const Editproduct = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [galleryimages, setGalleryimages] = useState([]);
  const [getImages, setGetImages] = useState();
  const [tags, setTags] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [attribute, setAttribute] = useState({
    colors: [],
    sizes: [],
    tags: [],
  });

  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [allTags, setallTags] = useState([]);
  const [categories, setCategories] = useState([]);

  const { t } = useTranslation();
  const selectLanguage = i18next.language;

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setLoading(true);

    const getAllCategories = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcategory`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const categoryData = response?.data.map((category) => {
          const obj = {
            label:
              selectLanguage == "ar" && category.category_name_ar
                ? category.category_name_ar
                : category.category_name,
            value: category.category_id,
          };
          return obj;
        });

        setCategories(categoryData);
      } else {
        setCategories([]);
      }
    };

    const getSingleProduct = async () => {
      const response = await getDataById(
        "vendor/getbyidproduct",
        location?.state?.id
      );
      if (response.status_code === 200) {
        setProduct(response.data.product_details[0]);
        setGetImages(response.data.images);
        const prevTags = response.data.tags.map(
          ({ tag_id, tag_name_ar, tag_name }) => ({
            label:
              selectLanguage == "ar" && tag_name_ar ? tag_name_ar : tag_name,
            value: tag_id,
          })
        );
        setTags(prevTags);

        const prevColors = response.data.colors.map(
          ({ color_id, color_name, color_name_ar }) => ({
            label:
              selectLanguage == "ar" && color_name_ar
                ? color_name_ar
                : color_name,
            value: color_id,
          })
        );
        setSelectedColors(prevColors);

        const prevSizes = response.data.sizes.map(
          ({ size_id, size_name_ar, size_name }) => ({
            label:
              selectLanguage == "ar" && size_name_ar ? size_name_ar : size_name,
            value: size_id,
          })
        );
        setSelectedSizes(prevSizes);
      } else {
        toast.error("something went wrong");
        navigate("/vendor/products");
      }

      setLoading(false);
    };

    const getAllTags = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getalltag`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const tagData = response.data.map((item) => {
          const obj = {
            label:
              selectLanguage == "ar" && item.tag_name_ar
                ? item.tag_name_ar
                : item.tag_name,
            value: item.tag_id,
          };
          return obj;
        });

        setallTags(tagData);
      }
    };

    const getAllColors = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallcolor`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const colorData = response.data.map((color) => {
          const label = `<div className="items-center" style="
            display: flex;
        ">${
          selectLanguage == "ar" && color.color_name_ar
            ? color.color_name_ar
            : color.color_name
        }  <span className="ml-2 p-2 w-4 rounded-full border border-b" style="background-color:${
            color.color_code
          };width: 2px;height: 2px;"></span> </div>`;

          const obj = { label: label, value: color.color_id };
          return obj;
        });

        setColors(colorData);
      }
    };

    const getAllSizes = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallsize`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const sizeData = response.data.map((size) => {
          const obj = {
            label:
              selectLanguage == "ar" && size.size_name_ar
                ? size.size_name_ar
                : size.size_name,
            value: size.size_id,
          };
          return obj;
        });

        setSizes(sizeData);
      }
    };
    getAllTags();
    getAllColors();
    getAllSizes();

    getSingleProduct();
    getAllCategories();
  }, [selectLanguage]);

  useEffect(() => {
    if (product) {
      setValue("product_name", product.product_name);
      setValue("product_name_ar", product.product_name_ar);
      setValue("product_price", product.product_price);
      setValue("product_sku", product.product_sku);
      setValue("product_stock", product.product_stock);
      setValue("product_weight", product.product_weight);
      setValue("product_height", product.product_height);
      setValue("product_length", product.product_length);
      setValue("product_width", product.product_width);
      setValue("product_video_provider", product.product_video_provider);
      setValue("product_video_link", product.product_video_link);
      setValue("product_status", product.status);
      setValue("product_category", product.product_category);
      setValue(
        "tags",
        tags.map((t) => t.value)
      );
      setValue(
        "colors",
        selectedColors.map((c) => c.value)
      );
      setValue(
        "sizes",
        selectedSizes.map((s) => s.value)
      );
    }
  }, [product]);

  const setColorAttribute = (val) => {
    const colors = val.map((item) => item.value);

    setAttribute({ ...attribute, colors: colors });
  };
  const setSizeAttribute = (val) => {
    const sizes = val.map((item) => item.value);

    setAttribute({ ...attribute, sizes: sizes });
  };
  const setTagAttribute = (val) => {
    const tags = val.map((item) => item.value);

    setAttribute({ ...attribute, tags });
  };

  const onSubmit = async (data) => {
    if (attribute.colors.length > 0) {
      data["colors"] = JSON.stringify(attribute.colors);
    } else {
      data["colors"] = JSON.stringify(data.colors);
    }

    if (attribute.sizes.length > 0) {
      data["sizes"] = JSON.stringify(attribute.sizes);
    } else {
      data["sizes"] = JSON.stringify(data.sizes);
    }

    if (attribute.tags.length > 0) {
      data["tags"] = JSON.stringify(attribute.tags);
    } else {
      data["tags"] = JSON.stringify(data.tags);
    }

    // setLoading(true);
    data["product_id"] = product.product_id;
    data["product_description"] = product.product_description;
    data["product_description_ar"] = product.product_description_ar;
    if (images[0]) {
      data["thumbnail_image"] = images[0].file;
    }
    const formData = new FormData();

    for (let key in data) {
      formData.append(key, data[key]);
    }

    for (let i of galleryimages) {
      formData.append("gallery_images", i.file);
    }

    const res = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/updateproduct`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    const response = await res.data;

    if (response.status_code === 200) {
      setProduct(response.data);
      toast.success("Updated Successfully");
      setLoading(false);
      navigate("/vendor/products");
    } else {
      toast.error("something went wrong");
    }
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
  };

  const onChangeGalleryImages = (imageList, addUpdateIndex) => {
    // data for submit
    setGalleryimages(imageList);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl pb-2 uppercase">Edit</h2>
        <Link to={"/vendor/products"}>
          <button className="btn btn-primary btn-xs text-white mb-5 flex justify-center items-center gap-x-2 mt-1">
            <FaArrowCircleLeft size={15} />
            Go Back
          </button>
        </Link>
      </div>

      <div className="mt-2 mb-16">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="md:col-span-2">
              <div className="border bg-white">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_info")}
                  </h3>
                </div>
                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="name">
                          {t("add_new_product_name")}{" "}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_name")}`}
                          {...register("product_name", { required: true })}
                        />
                        {errors.name?.type === "required" && (
                          <small className="text-[12px] text-error">
                            {t("add_new_product_name_req")}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* product name in arabic */}
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="name">
                          {t("add_new_product_name")} {t("in Arabic")}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={` ${t("add_new_product_name")} ${t(
                            "in Arabic"
                          )}`}
                          {...register("product_name_ar")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="price">
                          {t("add_new_product_price")}{" "}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_price_place")}`}
                          {...register("product_price", { required: true })}
                        />
                        {errors.price?.type === "required" && (
                          <small className="text-[12px] text-error">
                            {t("add_new_product_price_req")}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="sku">
                          {t("add_new_product_SKU")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          type="text"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_SKU_plc")}`}
                          {...register("product_sku")}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="stock">
                          {t("add_new_product_stock")}{" "}
                          <span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-8">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder={`${t("add_new_product_price_place")}`}
                          {...register("product_stock", { required: true })}
                        />
                        {errors.stock?.type === "required" && (
                          <small className="text-[12px] text-error">
                            {t("add_new_product_stock_req")}
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Product Description */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium capitalize">
                    {t("Description")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="description">
                          {t("Description")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control h-60">
                          <ReactQuill
                            className="h-48"
                            theme="snow"
                            value={product?.product_description}
                            name="product_description"
                            onChange={(val) =>
                              setProduct({
                                ...product,
                                product_description: val,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* descriptioin in arabic */}
                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="description">
                          {t("Description")} {t("in Arabic")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control h-60">
                          <ReactQuill
                            className="h-48"
                            theme="snow"
                            value={product?.product_description_ar}
                            name="product_description_ar"
                            onChange={(val) =>
                              setProduct({
                                ...product,
                                product_description_ar: val,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Product Attributes */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_atrtibutes")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-x-5 mb-2">
                      <div className="col-span-3 h-[40px]">
                        <label className="text-[13px]" htmlFor="colors">
                          {t("add_new_product_Colors")}
                        </label>
                      </div>

                      <div className="col-span-8">
                        {colors.length > 0 ? (
                          <Select
                            isMulti
                            {...register("colors")}
                            defaultValue={selectedColors}
                            onChange={(val) => setColorAttribute(val)}
                            options={colors}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            formatOptionLabel={function (data) {
                              return (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data.label,
                                  }}
                                />
                              );
                            }}
                          />
                        ) : (
                          "Loading"
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12 gap-x-5 mb-2">
                      <div className="col-span-3 h-[40px]">
                        <label className="text-[13px]" htmlFor="sizes">
                          {t("add_new_product_Sizes")}
                        </label>
                      </div>

                      <div className="col-span-8">
                        <Select
                          isMulti
                          {...register("sizes")}
                          defaultValue={selectedSizes}
                          onChange={(val) => setSizeAttribute(val)}
                          options={sizes}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Shipping Information */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_Information")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="weight">
                          {t("add_new_product_Weight")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_weight")}
                            />
                            <span className="bg-gray-200">gm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label
                          className="text-[13px] capitalize"
                          htmlFor="product_height"
                        >
                          {t("add_new_product_Height")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_height")}
                            />
                            <span className="bg-gray-200">cm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label
                          className="text-[13px] capitalize"
                          htmlFor="length"
                        >
                          {t("add_new_product_length")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_length")}
                            />
                            <span className="bg-gray-200">cm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label
                          className="text-[13px] capitalize"
                          htmlFor="width"
                        >
                          {t("add_new_product_width")}
                        </label>
                      </div>
                      <div className="col-span-8">
                        <div className="form-control">
                          <label className="input-group rounded">
                            <input
                              type="number"
                              className="w-full h-[40px] input input-bordered rounded"
                              placeholder="0"
                              {...register("product_width")}
                            />
                            <span className="bg-gray-200">cm</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-1">
              {/* product stats */}
              <div className="border bg-white">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_Status")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 gap-x-5 mb-2">
                      <div className="h-[40px]">
                        <select
                          defaultValue="1"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="0"
                          {...register("status")}
                        >
                          <option value="1">
                            {t("add_new_product_Status_2")}
                          </option>
                          <option value="0">
                            {t("add_new_product_Status_3")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* product Category */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_Category")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 gap-x-5 mb-2">
                      <div className="h-[40px]">
                        <select
                          defaultValue="1"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="Select Category"
                          {...register("product_category")}
                        >
                          {categories.map((cat, idx) => (
                            <option
                              key={idx}
                              selected={cat.label === product.category_name}
                              value={cat.value}
                            >
                              {cat.label}{" "}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* product Tags */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4 flex w-full justify-between">
                  <h3 className="text-base font-medium capitalize">
                    {t("add_new_product_Tags")}
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 gap-x-5 mb-2">
                      {tags && (
                        <Select
                          {...register("tags")}
                          defaultValue={tags}
                          name="tags"
                          onChange={(val) => setTagAttribute(val)}
                          isClearable
                          isMulti
                          options={allTags}
                        />
                      )}
                      <em>press enter or comma to add new tag</em>
                    </div>
                  </div>
                </div>
              </div>

              {/* product Thumbnail Images */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_T_img")}{" "}
                    <span className="text-2xs text-gray-400"> ( 312x364 )</span>
                  </h3>
                </div>
                <div className="py-5 px-5">
                  <div className="col-span-8">
                    <ImageUploading
                      value={images}
                      onChange={onChange}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <p
                            className="text-xs cursor-pointer py-2 px-3"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {t("add_new_product_T_img_info")}
                          </p>
                          &nbsp;
                          {images.length > 0
                            ? imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      className="text-red-600"
                                      onClick={() => onImageRemove(index)}
                                    >
                                      X
                                    </button>
                                  </div>
                                  <img
                                    className="w-20 h-20"
                                    src={image["data_url"]}
                                    alt=""
                                  />
                                </div>
                              ))
                            : product?.thumbnail_image && (
                                <div className="image-item">
                                  <img
                                    className="w-20 h-20"
                                    src={product?.thumbnail_image}
                                    alt=""
                                  />
                                </div>
                              )}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <small className="text-2xs text-gray-400">
                    {t("add_new_product_T_img_info2")}
                  </small>
                </div>
              </div>

              {/* Gallery Images */}
              <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_G_img")}{" "}
                    <span className="text-2xs text-gray-400"> ( 600x600 )</span>
                  </h3>
                </div>

                <div className="py-5 px-5">
                  <div className="col-span-8">
                    <ImageUploading
                      multiple
                      maxNumber={3}
                      value={galleryimages}
                      onChange={onChangeGalleryImages}
                      dataURLKey="data_url"
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,

                        isDragging,
                        dragProps,
                      }) => (
                        <div className="upload__image-wrapper">
                          <p
                            className="text-xs cursor-pointer py-2 px-3"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                          >
                            {t("add_new_product_T_img_info")}
                          </p>
                          &nbsp;
                          <div className="flex gap-2">
                            {imageList.map((image, index) => (
                              <div
                                key={index}
                                className="image-item max-w-[5rem]"
                              >
                                <div className="image-item__btn-wrapper">
                                  <button
                                    className="text-red-600"
                                    onClick={() => onImageRemove(index)}
                                  >
                                    X
                                  </button>
                                </div>
                                <img
                                  className="w-20 h-20 object-contain"
                                  src={image["data_url"]}
                                  alt=""
                                />
                              </div>
                            ))}
                            {getImages &&
                              getImages?.map((img, i) => (
                                <div key={i} className="image-item">
                                  <img
                                    className="w-20 h-20 object-contain"
                                    src={img?.image_url}
                                    alt=""
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                  <small className="text-2xs text-gray-400">
                    {t("add_new_product_G_img_info2")}
                  </small>
                </div>
              </div>

              {/* product Videos */}
              {/* <div className="border bg-white my-5">
                <div className="card-title border-b p-4">
                  <h3 className="text-base font-medium">
                    {t("add_new_product_video")}
                  </h3>
                </div>
                <div className="py-5 px-5">
                  <div className="col-span-8">
                    <label htmlFor="videoType">
                      {t("add_new_product_video_p")}
                    </label>
                    <select
                      defaultValue={product.product_video_provider}
                      className="w-full h-[40px] input input-bordered rounded mt-2"
                      {...register("product_video_provider")}
                    >
                      <option value="youtube">
                        {t("add_new_product_video_Youtube")}
                      </option>
                      <option value="dailymotion">
                        {t("add_new_product_video_Dailymotion")}
                      </option>
                    </select>
                  </div>
                  <div className="col-span-8 mt-5">
                    <label htmlFor="video_link">
                      {t("add_new_product_video_link")}
                    </label>
                    <input
                      {...register("product_video_link")}
                      type="url"
                      className="w-full border border-gray-300 input-bordered rounded mt-2 py-2 px-3"
                    />
                    <small className="text-2xs text-gray-400">
                      Use proper embed link without extra parameter (copy the
                      src of iframe tag). Don't use short share link/embeded
                      iframe code.
                    </small>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <input
            className="py-1 px-3 bg-primary text-white rounded cursor-pointer"
            type="submit"
            value="Update Product"
          />
        </form>
      </div>
    </>
  );
};

export default Editproduct;
