import axios from "axios";

async function checkStore(storename) {
  const res = await axios.get(
    `${process.env.REACT_APP_APIURL}/vendor/store/findStoreByName/${storename}`
  );

  const response = res.data;
  return response;
}

export default checkStore;
