import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Button = ({ link, bgColor, children }) => {
    const { t } = useTranslation();
    return (
        <div title={t("HNav_store")}>
            <Link to={link} className={`${bgColor} hover:bg-transparent border border-primary rounded-md uppercase font-semibold py-2 px-5 flex justify-center items-center text-white in-pri w-fit text-sm font-Roboto`}>{children}</Link>
        </div>
    );
};

export default Button;