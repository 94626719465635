import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import FilterComponent from "../../../components/utils/FilterComponent";
import { FiEye } from "react-icons/fi";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useEffect } from "react";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import { confirmAlert } from "react-confirm-alert";
import deleteDataById from "../../../hooks/deleteDataById";
import ApiLoadingSpinner from "../../../components/utils/ApiLoadingSpinner";
import updateData from "../../../hooks/updateData";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";

const AllProducts = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentPlan, setCurrentPlan] = useState([]);
  const [dashboardData, setdashboardData] = useState(null);
  const [apiLoading, setapiLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  let filteredItems = products.filter(
    (item) =>
      item.product_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.category_name.toLowerCase().includes(filterText.toLowerCase())
  );
  const selectLanguage = i18next.language;

  const columns = [
    {
      name: `${t("all_product_dataTable1")}`,
      selector: (row) =>
        selectLanguage == "ar" && row?.product_name_ar
          ? row?.product_name_ar
          : row?.product_name,
      cell: (row) => (
        <div className="flex items-center">
          <img
            className="w-12 h-20 mx-2 object-contain"
            src={row.thumbnail_image_url}
            alt=""
          />
          <span className="text-xs truncate max-w-[100px]">
            {selectLanguage == "ar" && row?.product_name_ar
              ? row?.product_name_ar
              : row?.product_name}
          </span>{" "}
        </div>
      ),
      sortable: true,
    },
    {
      name: `${t("all_product_dataTable2")}`,
      selector: (row) => row?.product_price,
      cell: (row) => (
        <>
          <div className="items-center flex gap-x-1">
            <span className="font-semibold">{row?.product_price} ر.س</span>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: `${t("all_product_dataTable3")}`,
      selector: (row) => (
        <span className="py-1 px-3 rounded bg-gray-300 text-black roboto font-medium text-[.75rem]">
          {" "}
          {row?.category_name}
        </span>
      ),
    },
    {
      name: `${t("all_product_dataTable4")}`,
      selector: (row) => row.status,
      cell: (row) => (
        <div className="form-control">
          <label className="label cursor-pointer">
            <input
              readOnly
              onClick={() => changeStatus(row?.product_id, row.status)}
              checked={row.status}
              type="checkbox"
              className="toggle toggle-primary"
            />
          </label>
        </div>
      ),
      sortable: true,
    },
    {
      name: `${t("Description")}`,
      selector: (row) =>
        selectLanguage === "ar" && row?.product_description_ar
          ? row.product_description_ar
          : row.product_description,
      cell: (row) => (
        <>
          <div className="items-center flex gap-x-1">
            <p
              style={{ height: "70px", width: "100px", overflow: "auto" }}
              dangerouslySetInnerHTML={{
                __html:
                  selectLanguage === "ar" && row?.product_description_ar
                    ? row.product_description_ar
                    : row.product_description,
              }}
            ></p>
          </div>
        </>
      ),
      sortable: true,
    },
    {
      name: `${t("all_product_dataTable6")}`,
      sortable: true,
      cell: (item) => (
        <div className="flex gap-2">
          <Link
            to={`/vendor/product/${item.product_id}`}
            className="p-2 flex items-center border border-primary hover:bg-primary rounded-full text-primary hover:!text-white cursor-pointer duration-300"
            title="View"
          >
            <FiEye />
          </Link>
          <span
            onClick={() => goToEditPage(item.product_id)}
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:text-white cursor-pointer duration-300"
            title="Edit"
          >
            <AiOutlineEdit />
          </span>
          <span
            onClick={() => deleteItem(item.product_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title="Delete"
          >
            <AiOutlineDelete />
          </span>
        </div>
      ),
    },
  ];

  useEffect(() => {
    const getAllProducts = async () => {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallproduct`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200 && response.data) {
        setProducts(response.data);
      }
      setLoading(false);
    };

    const fetchCurrentPlan = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getcurrentplan`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        if (response.data.length > 0) {
          setCurrentPlan(response.data[0]);
        }
      }
    };
    const fetchDashboardDetails = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getdashboarddetails`,
        {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code === 200) {
        setdashboardData(response);
      }
    };
    fetchDashboardDetails();
    fetchCurrentPlan();
    getAllProducts();
  }, [refresh]);

  const goToEditPage = (id) => {
    navigate("/vendor/product/edit", { state: { id: id } });
  };

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState(null);

  const onSubmit = async (data) => {
    if (!file) {
      return;
    }
    const formdata = new FormData();
    formdata.append("product_excel_file", file[0]);

    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/uploadproductexcel`,
      formdata,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );

    const response = await res.data;

    if (response?.status_code === 200) {
      toast.success("Imported Successfully");
      setOpenModal(false);
      setRefresh(!refresh);
      reset();
    } else {
      toast.error(response.err ? response.err : response.message);
    }
  };

  const handleClear = () => {};

  const deleteItem = async (id) => {
    confirmAlert({
      title: "Click Yes to Delete",
      message: "Are you sure to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setLoading(true);
            const response = await deleteDataById("vendor/deleteproduct", id);

            if (response.status_code === 200) {
              const newdataList = products.filter(
                (item) => item.product_id !== id
              );
              setProducts(newdataList);
              filteredItems = filteredItems.filter(
                (item) => item.product_id !== id
              );
            }
            setRefresh(!refresh);
            setLoading(false);
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const changeStatus = async (id, status) => {
    setapiLoading(true);

    const data = {
      id,
      status: !status,
    };

    try {
      const response = await updateData("vendor/changeproductstatus", data);
      if (response.status_code === 200) {
        toast.success("Updated!");
      }
    } catch (error) {
      toast.error(error);
    }

    const newProducts = products.map((item) => {
      if (id === item.product_id) {
        return {
          ...item,
          status: !item.status,
        };
      } else {
        return item;
      }
    });
    setProducts(newProducts);
    setapiLoading(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {apiLoading && <ApiLoadingSpinner />}
      <div className="flex items-center justify-between">
        <h2 className="text-title text-sm md:text-base font-medium pb-2 roboto">
          {t("all_product_title")}
        </h2>
        <div className="flex gap-2">
          <label
            htmlFor="import-modal"
            className="bg-primary text-white py-2 px-3 rounded cursor-pointer"
            onClick={() => setOpenModal(true)}
          >
            {t("all_product_Import")}
          </label>

          <Link
            to="/vendor/add-product"
            className="py-2 text-sm px-4  bg-primary text-white in-pri2 rounded font-normal"
          >
            {t("all_product_add")}
          </Link>
        </div>
      </div>

      <div className="py-5 w-full grid grid-cols-1 gap-2">
        <p className="alert bg-[#61c7c1] text-black text-[15px] mb-3 rounded justify-start gap-2 py-3">
          {t("all_product_limit")}
          <span className="font-bold">
            {dashboardData?.products?.count} / {currentPlan?.product_limit}
          </span>
        </p>
      </div>
      {/* products */}
      <div className="pb-10">
        <div className="bg-white border rounded-none">
          <div className="table-header p-4 card-header flex flex-wrap gap-y-2">
            <div className="mr-auto">
              <h2 className="text-title text-sm md:text-base font-medium pb-2 roboto">
                {t("all_product_title")}
              </h2>
            </div>

            <FilterComponent
              setFilterText={setFilterText}
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
              placeholder={`${t("all_product_search")}`}
            />
          </div>

          <div className="data-table mt-5">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // selectableRows
                pagination
                noDataComponent={
                  <h4 className="py-8 text-gray-400 text-center">
                    {t("No records")}
                  </h4>
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* modal for import */}
      {openModal && (
        <>
          <input type="checkbox" id="import-modal" className="modal-toggle" />
          <label htmlFor="import-modal" className="modal cursor-pointer">
            <label className="modal-box relative" htmlFor="">
              <h3 className="text-lg font-bold">{t("all_product_pro_imp")}</h3>
              <form
                className="p-2"
                onSubmit={handleSubmit(onSubmit)}
                encType="multipart/form-data"
              >
                <input
                  type="file"
                  name="product_excel_file"
                  onChange={(e) => setFile(e.target.files)}
                  className="input w-full h-[40px]"
                  required
                  accept=".xls, .xlsx"
                />
                <p className="text-xs text-slate-500 mb-4 mt-0">
                  Upload an excel file in the same format as shown in sample
                  file
                </p>
                <div className="flex justify-between">
                  <Link
                    to="/sample.xlsx"
                    target="_blank"
                    download
                    className="text-primary"
                  >
                    {t("all_product_down")}
                  </Link>

                  <input
                    className="btn btn-sm btn-primary text-white rounded"
                    type="submit"
                    value="Import"
                  />
                </div>
              </form>
            </label>
          </label>
        </>
      )}
    </>
  );
};

export default AllProducts;
