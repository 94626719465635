import React, { useContext, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Header from "../../components/Header/Header";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../contexts/DomainContext";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
import Aos from "aos";

const VendorResetPass = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { register, reset, handleSubmit } = useForm();

  if (!token) {
    navigate("/");
  }

  useEffect(() => {
    var user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (user?.vendor_id) {
      navigate("/vendor/dashboard");
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    if (data.password1 !== data.password2) {
      toast.error("Both Field Should Be Same");
      setLoading(false);
      return;
    }
    const formData = {
      password: data.password1,
    };
    const res = await fetch(
      `${process.env.REACT_APP_APIURL}/vendor/update_password`,
      {
        method: "PATCH",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = await res.json();
    if (
      response.code === 200 ||
      response.message === "password reset successfully"
    ) {
      toast.success(response.message);
      reset();
      navigate("/vendor/login");
    } else {
      toast.error(response.message);
    }

    setLoading(false);
  };

  const selectLanguage = i18next.language;
  const { t } = useTranslation();

  const [ganarel, setGanarel] = useState([]);
  const { customdomain, titleName } = useContext(domainProvider);

  useEffect(() => {
    Aos.init();
    Aos.refresh();
    axios
      .get(`${process.env.REACT_APP_APIURL}/admin/getgeneraldetails`, {})
      .then((res) => {
        setGanarel(res.data.data[0]);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Forget Password - {titleName}</title>
        {ganarel?.favicon && <link rel="icon" href={ganarel.favicon} />}
      </Helmet>
      <Header />
      <div className=" min-h-[100vh] flex flex-col">
        <section className="px-2 md:px-5 lg:px-20 py-5" style={{ flexGrow: 1 }}>
          <div className="container place-content-center mx-auto">
            <div className="registration-form max-w-md mx-auto  px-2 md:px-8">
              <form
                className="flex flex-col gap-y-2 bg-white rounded-2xl p-5 border border-gray-200 shadow-xl py-10 justify-center w-full"
                onSubmit={handleSubmit(onSubmit)}
              >
                <h2 className="text-center text-xl md:text-2xl mb-10">
                  {t("Reset")} {t("New")} {t("l_p")}
                </h2>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      {t("New")} {t("l_p")}
                    </span>
                  </label>
                  <input
                    {...register("password1", { required: true })}
                    type="password"
                    placeholder={t("l_p")}
                    className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                    required
                  />
                  {/* <i className={`fa ${eye1 ? "fa-eye-slash" : "fa-eye"} cursor-pointer absolute ${selectLanguage === "ar" ? "left-2" : "right-2"}  top-11`}></i> */}
                  {/* <span className="label-text-alt text-red-500 font-Roboto">Password must be atleast 1 uppercase</span> */}
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{t("l_c_p_p")}</span>
                  </label>

                  <input
                    {...register("password2", { required: true })}
                    type="password"
                    placeholder={t("l_c_p_p")}
                    className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                    required
                  />
                </div>

                <div className="form-control w-full">
                  <button
                    type="submit"
                    className={`btn cursor-pointer mt-4 btn-primary text-white w-full  rounded-none ${
                      loading && "loading"
                    }`}
                  >
                    {t("vd_pc_btn2")}
                  </button>

                  {/* <input className="py-2 cursor-pointer btn-primary text-white w-full  rounded-none" type="submit" value="Login" /> */}
                </div>
              </form>

              <div className="flex flex-col w-full">
                <Link to="/vendor/login" className="divider text-primary">
                  {selectLanguage === "ar"
                    ? "العودة إلى تسجيل الدخول"
                    : "Back to Login"}
                </Link>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default VendorResetPass;
