import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import { userProvider } from "../../contexts/UserContext";
import { toast } from "react-toastify";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { domainProvider } from "../../contexts/DomainContext";
import { storeInformation } from "../../contexts/StoreInformationContext";

const TrackOrder = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  const { isVendor } = useContext(userProvider);
  const userInfo = JSON.parse(localStorage.getItem("customer"));
  const navigate = useNavigate();
  const { customdomain, titleName } = useContext(domainProvider);
  const { storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : window.location.href.split("/")[3];
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const onSubmit = (data) => {
    if (!userInfo) {
      toast.info("Please login before track order");
      return;
    }
    if (data.tracking_id) {
      if (customdomain === "aitech.com.sa") {
        navigate(`/${storeName}/tracking_page`, {
          state: { id: data.tracking_id, token: userInfo?.token },
        });
      } else {
        navigate(`/tracking_page`, {
          state: { id: data.tracking_id, token: userInfo?.token },
        });
      }
    }
  };

  return (
    <>
      <div className="flex flex-col min-h-[100vh]">
        <ShopNavbar />
        <Helmet>
          <title>Track Order - {titleName}</title>
        </Helmet>
        <div
          className="container mx-auto px-8 xl:px-16 max-w-[1280px]"
          style={{ flexGrow: 1 }}
        >
          <div className="border-2 border-dashed p-10 my-20 bg-[#FCFCFC]">
            <p>
              {selectLanguage === "ar"
                ? "لتتبع طلبك، يرجى إدخال رقم التتبع الخاص بك في المربع أدناه ثم اضغط على زر 'تتبع'. لقد تم تقديم هذا لك في إيصالك وفي رسالة التأكيد الإلكترونية التي كان من المفترض أن تتلقاها."
                : "To track your order please enter your Tracking ID in the box below and press the 'Track' button. This was given to you on your receipt and in the confirmation email you should have received."}
            </p>
            <form onSubmit={handleSubmit(onSubmit)} className="my-10">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-20 mb-10">
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      {selectLanguage === "ar" ? "رقم الشحنه" : "Tracking Id"}
                    </span>
                  </label>
                  <input
                    {...register("tracking_id", { required: true })}
                    type="text"
                    placeholder={t("track_placeholder")}
                    className="input input-sm input-bordered p-5 focus:border-primary focus:outline-none rounded-none w-full "
                  />
                  <label className="label">
                    {errors.firstName?.type === "required" && (
                      <span className="label-text-alt text-red-500 font-Roboto">
                        {selectLanguage === "ar"
                          ? "معرف التعقب مطلوب"
                          : "Tracking Id Required"}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <button className="rounded-3xl bg-primary py-3 px-8 text-white">
                {selectLanguage === "ar" ? "تتبع" : "Track"}
              </button>
            </form>
          </div>
        </div>
        <StoreFooter />
      </div>
    </>
  );
};

export default TrackOrder;
