import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { AiFillEdit } from "react-icons/ai";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import FilterComponent from "../../../components/utils/FilterComponent";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import deleteDataById from "../../../hooks/deleteDataById";
import getDataById from "../../../hooks/getDataById";
import postData from "../../../hooks/postData";
import updateData from "../../../hooks/updateData";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "../../../components/utils/Interceptors";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  maxHeight: "calc(100vh - 5em)",
};

const Tags = () => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedTag, setSelectedTag] = useState({});
  const user = JSON.parse(localStorage.getItem("user"));
  const [tags, setTags] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const navigate = useNavigate();
  let filteredItems = tags?.filter(
    (item) =>
      item.tag_name &&
      item.tag_name.toLowerCase().includes(filterText.toLowerCase())
  );

  useEffect(() => {
    setLoading(true);

    const getAlltags = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getalltag`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setTags(response.data);
      } else {
        setTags([]);
      }

      setLoading(false);
    };
    getAlltags();
  }, [refresh]);

  const columns = [
    {
      name: `${t("vd_pc_C_ID")}`,
      selector: (row, i) => i + 1,
      sortable: true,
    },
    {
      name: `${t("vd_pt_tag")}`,
      selector: (row) =>
        selectLanguage == "ar" && row.tag_name_ar
          ? row.tag_name_ar
          : row.tag_name,
      sortable: true,
    },
    {
      name: `${t("Description")}`,
      selector: (row) =>
        selectLanguage == "ar" && row.tag_description_ar
          ? row.tag_description_ar
          : row.tag_description,
      sortable: true,
    },
    {
      name: `${t("vd_pc_C_Options")}`,
      sortable: true,
      cell: (tag) => (
        <div className="flex gap-2">
          <label
            onClick={() => selectTagToEdit(tag.tag_id)}
            htmlFor="my-modal-4"
            className="p-2 flex items-center border border-secondary hover:bg-secondary rounded-full text-secondary hover:text-white cursor-pointer duration-300"
            title={`${t("Edit")}`}
          >
            <AiFillEdit />
          </label>

          <span
            onClick={() => deleteTag(tag.tag_id)}
            className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300"
            title={`${t("Delete")}`}
          >
            <TiDelete />
          </span>
        </div>
      ),
    },
  ];

  // handlers
  const onSubmit = async (data) => {
    setLoading(true);
    const response = await postData("/vendor/createtag", data);
    if (response !== 0) {
      if (filteredItems) {
        filteredItems.push(response);
      } else {
        filteredItems = [response];
      }
      setTags([...tags, response]);
      setRefresh(!refresh);
    }
    setLoading(false);
    reset();
  };

  const deleteTag = async (id) => {
    confirmAlert({
      title: `${t("delete_alert_title")}`,
      message: `${t("delete_alert_des")}`,
      buttons: [
        {
          label: `${t("yes")}`,
          onClick: async () => {
            setLoading(true);

            const response = await deleteDataById("vendor/deletetag", id);

            if (response.status_code === 200) {
              const newTagsList = tags.filter((item) => item.tag_id !== id);
              setTags(newTagsList);
              filteredItems = filteredItems.filter(
                (item) => item.tag_id !== id
              );
            }

            setLoading(false);
            setRefresh(!refresh);
          },
        },
        {
          label: `${t("no")}`,
          onClick: () => {},
        },
      ],
    });
  };
  const [showEdit, setShowEdit] = useState(false);
  const selectTagToEdit = async (id) => {
    const response = await getDataById("vendor/getbyidtag", id);
    if (response.data) {
      setSelectedTag(response.data);
      setShowEdit(true)
    } else {
    }
  };

  // validations
  const [editNameErr, setEditNameErr] = useState("");
  const [editDescErr, setEditDescErr] = useState("");
  const [editNameArabErr, setEditNameArabErr] = useState("");
  const [editDescArabErr, setEditDescArabErr] = useState("");
  const selectLanguage = i18next.language;
  useEffect(() => {
    const regexName = /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i;
    const arabic = /^[\u0600-\u06FF ,.'-]+$/i;

    if (!regexName.test(selectedTag.tag_name)) {
      setEditNameErr(
        "Tag name must only contain alphabet letters with a maximum of 30 characters"
      );
    } else if (
      selectedTag.tag_name_ar &&
      !arabic.test(selectedTag.tag_name_ar)
    ) {
      setEditNameArabErr("Tag name must only contain arabic letters");
    } else {
      setEditNameErr("");
      setEditNameArabErr("");
    }
  }, [selectedTag]);

  useEffect(() => {
    const regexDesc = /^[a-zA-Z ,.'-]+$/i;
    const arabic = /^[\u0600-\u06FF ,.'-]+$/i;
    if (
      selectedTag.tag_description &&
      !regexDesc.test(selectedTag.tag_description)
    ) {
      setEditDescErr("Description must only contain alphabet letters");
    } else if (
      selectedTag.tag_description_ar &&
      !arabic.test(selectedTag.tag_description_ar)
    ) {
      setEditDescArabErr("Description must only contain arabic letters");
    } else {
      setEditDescErr("");
      setEditDescArabErr("");
    }
  }, [selectedTag]);

  const submitEditForm = async (e) => {
    e.preventDefault();

    const data = {
      id: selectedTag.tag_id,
      tag_name: selectedTag.tag_name,
      tag_description: selectedTag.tag_description,
      tag_name_ar: selectedTag.tag_name_ar,
      tag_description_ar: selectedTag.tag_description_ar,
    };

    if (editNameErr || editDescErr || editNameArabErr || editDescArabErr) {
      console.log("err");
    } else {
      const response = await updateData("vendor/updatetag", data);

      if (response.status_code === 200) {
        const updatedList = tags.map((c) => {
          if (c.tag_id === selectedTag.tag_id) {
            return response.data;
          }
          return c;
        });

        setTags(updatedList);

        filteredItems = filteredItems.map((c) => {
          if (c.tag_id === selectedTag.tag_id) {
            return response.data;
          }
          return c;
        });

        setSelectedTag("");
        setShowEdit(false)
        toast.success("Updated Successfully");
      } else {
        toast.error(response?.error?.message);
      }
    }
  };

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <>
      <div className="flex gap-x-4">
        <h2 className="text-title text-lg md:text-2xl uppercase">
          {t("vd_pt_Tags")}
        </h2>
        <button
          onClick={handleGoBack}
          className="btn btn-primary btn-xs text-white flex justify-center items-center gap-x-2 mt-1"
        >
          <FaArrowCircleLeft size={15} />
          {t("add_new_product_back")}
        </button>
      </div>
      <div className="p-4 border border-gray-200 bg-white shadow-sm col-span-2 mt-5">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
          <div>
            <form
              className="flex flex-col gap-4"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("vd_pt_Tags_n")}</span>
                </label>
                <input
                  {...register("tag_name", {
                    required: true,
                    pattern: /^(?=.{1,30}$)[a-zA-Z ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.tag_name?.type === "required" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Tag name is required
                  </span>
                )}
                {errors.tag_name?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Tag name must only contain alphabet letters with a maximum
                    of 30 characters
                  </span>
                )}
              </div>

              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">
                    {t("vd_pt_Tags_n")} {t("in Arabic")}
                  </span>
                </label>
                <input
                  {...register("tag_name_ar", {
                    pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                  })}
                  type="text"
                  className="input input-bordered w-full max-w-lg rounded-none shadow"
                />
                {errors.tag_name_ar?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Tag name must only contain arabic letters
                  </span>
                )}
              </div>

              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">{t("Description")}</span>
                </label>
                <textarea
                  {...register("tag_description", {
                    pattern: /^[a-zA-Z ,.'-]+$/i,
                  })}
                  className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                ></textarea>
                {errors.tag_description?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Description must only contain alphabet letters
                  </span>
                )}
              </div>

              <div className="form-control w-full max-w-lg">
                <label className="label">
                  <span className="label-text">
                    {t("Description")} {t("in Arabic")}
                  </span>
                </label>
                <textarea
                  {...register("tag_description_ar", {
                    pattern: /^[\u0600-\u06FF ,.'-]+$/i,
                  })}
                  className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                ></textarea>
                {errors.tag_description_ar?.type === "pattern" && (
                  <span className="label-text-alt text-red-500 font-Roboto my-1">
                    Tag description must only contain arabic letters
                  </span>
                )}
              </div>

              <button className="btn btn-primary max-w-lg rounded-none text-white">
                {t("vd_pc_btn")}
              </button>
            </form>
          </div>
          <div className="col-span-2 md:p-5">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <DataTable
                columns={columns}
                data={filteredItems}
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                pagination
                noDataComponent={
                  <p className="py-8 text-gray-400 text-center">
                    {t("No records")}
                  </p>
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* edit modal */}
      <Modal
        open={showEdit}
        onClose={() => setShowEdit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="my-4"
      >
        <Box sx={style} className="w-11/12 max-w-[32rem] overflow-y-scroll">
          {selectedTag && (
            <div>
              <h3 className="text-lg font-bold">{t("vd_pt_e_tag")}</h3>

              {loading ? (
                <LoadingSpinner />
              ) : (
                <form className="flex flex-col gap-4" onSubmit={submitEditForm}>
                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("vd_pt_tag")}</span>
                    </label>
                    <input
                      value={selectedTag?.tag_name}
                      onChange={(e) =>
                        setSelectedTag({
                          ...selectedTag,
                          tag_name: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                      required
                    />
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editNameErr}
                    </span>
                  </div>

                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">
                        {t("vd_pt_Tags_n")} {t("in Arabic")}
                      </span>
                    </label>
                    <input
                      value={selectedTag?.tag_name_ar}
                      onChange={(e) =>
                        setSelectedTag({
                          ...selectedTag,
                          tag_name_ar: e.target.value,
                        })
                      }
                      type="text"
                      className="input input-bordered w-full max-w-lg rounded-none shadow"
                    />
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editNameArabErr}
                    </span>
                  </div>

                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">{t("Description")}</span>
                    </label>
                    <textarea
                      value={selectedTag?.tag_description}
                      onChange={(e) =>
                        setSelectedTag({
                          ...selectedTag,
                          tag_description: e.target.value,
                        })
                      }
                      className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                    ></textarea>
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editDescErr}
                    </span>
                  </div>

                  <div className="form-control w-full max-w-lg">
                    <label className="label">
                      <span className="label-text">
                        {t("Description")} {t("in Arabic")}
                      </span>
                    </label>
                    <textarea
                      value={selectedTag?.tag_description_ar}
                      onChange={(e) =>
                        setSelectedTag({
                          ...selectedTag,
                          tag_description_ar: e.target.value,
                        })
                      }
                      className="textarea textarea-bordered w-full max-w-lg rounded-none shadow"
                    ></textarea>
                    <span className="label-text-alt text-red-500 font-Roboto my-1">
                      {editDescArabErr}
                    </span>
                  </div>

                  <button
                    type="submit"
                    className={`btn btn-primary max-w-lg rounded-none text-white ${
                      loading && "loading"
                    }`}
                  >
                    {t("vd_pc_btn2")}
                  </button>
                </form>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default Tags;
