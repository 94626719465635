import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getDataById from "../../../hooks/getDataById";
import { FaArrowCircleLeft } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import i18next from "i18next";
import axios from "../../../components/utils/Interceptors";

const EditCustomerQuery = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const { state } = useLocation();
  const navigate = useNavigate();
  const { register, setValue, handleSubmit } = useForm();
  const selectLanguage = i18next.language;

  useEffect(() => {
    setLoading(true);
    const getQueries = async () => {
      const res = await getDataById("vendor/getbyidcustomerquery", state);
      if (res.status_code === 200) {
        setValue("customer_name", res.data?.customer_name);
        setValue("customer_email", res.data?.customer_email);
        setValue("id", res.data?.id);
        setValue("query", res.data?.query);
        setValue("query_ar", res.data?.query_ar);
        setValue("resolution", res.data?.resolution);
        setValue("resolution_ar", res.data?.resolution_ar);
      }
      setLoading(false);
    };
    getQueries();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const info = {
      id: data.id,
      resolution: data.resolution,
      resolution_ar: data.resolution_ar,
    };

    const res = await axios.patch(
      `${process.env.REACT_APP_APIURL}/vendor/updatecustomerquery`,
      JSON.stringify(info),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.data;
    if (response?.status_code === 200) {
      toast.success(response.message);
      navigate("/vendor/product-queries");
    } else {
      toast.error(response.message);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="mt-2 mb-16">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="max-w-3xl mx-auto">
          <div className="flex gap-x-4 bg-white border p-4">
            <h2 className="text-title text-lg md:text-xl uppercase">
              {selectLanguage === "ar"
                ? "تحديث استفسار العميل"
                : "Update Customer Query"}
            </h2>
            <Link to={"/vendor/product-queries"}>
              <button className="btn btn-primary btn-xs rounded text-white mb-5 flex justify-center items-center gap-x-2">
                <FaArrowCircleLeft size={15} />
                {t("add_new_product_back")}
              </button>
            </Link>
          </div>
          <div className="md:col-span-2 mx-auto">
            <div className="border bg-white">
              <div className="py-5 px-5">
                <div className="form-group mb-5">
                  <div className="grid md:grid-cols-2 gap-y-3">
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("query_cust_name")}{" "}
                        <span className="text-error">*</span>
                      </label>
                    </div>
                    <div>
                      <input
                        className="w-full h-[40px] input input-bordered rounded"
                        readOnly
                        placeholder={`${t("query_cust_name")}`}
                        {...register("customer_name")}
                      />
                    </div>
                    <div className="hidden">
                      <label className="text-[13px]" htmlFor="planName">
                        {" "}
                        {t("Query Id")} <span className="text-error">*</span>
                      </label>
                    </div>
                    <div className="hidden">
                      <input
                        className="w-full h-[40px] input input-bordered rounded"
                        readOnly
                        placeholder="query id"
                        {...register("id")}
                      />
                    </div>

                    {/* coupon_type */}
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("query_cust_email")}{" "}
                        <span className="text-error">*</span>{" "}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("query_cust_email")}`}
                        {...register("customer_email")}
                      />
                    </div>

                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("Query")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("Query")}`}
                        {...register("query")}
                      />
                    </div>

                    {/* query in arabic */}
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("Query")} {t("in Arabic")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        readOnly
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("Query")}`}
                        {...register("query_ar")}
                      />
                    </div>

                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("Resolution")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("Resolution")}`}
                        {...register("resolution")}
                      />
                    </div>

                    {/* resolution in arabic */}
                    <div>
                      <label className="text-[13px]" htmlFor="planName">
                        {t("Resolution")} {t("in Arabic")}
                      </label>
                    </div>
                    <div>
                      <input
                        type="text"
                        className="w-full h-[40px] input input-bordered rounded"
                        placeholder={`${t("Resolution")}`}
                        {...register("resolution_ar")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input
              className="py-1 mt-2 px-3 bg-primary text-white rounded cursor-pointer"
              type="submit"
              value={`${t("vd_pc_btn2")}`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditCustomerQuery;
