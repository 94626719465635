import React from "react";
import addProduct1 from "../../../assets/guide/addProduct1.jpg";
import addProduct2 from "../../../assets/guide/addProduct2.jpg";
import addProduct3 from "../../../assets/guide/addProduct3.jpg";
import addProduct1Ar from "../../../assets/guide/addProduct1-ar.jpg";
import addProduct2Ar from "../../../assets/guide/addProduct2-ar.jpg";
import addProduct3Ar from "../../../assets/guide/addProduct3-ar.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const GuideAddProduct = () => {
  const selectLanguage = i18next.language;
  const { t } = useTranslation();
  return (
    <>
      <div>
        <h1 className="capitalize text-[30px] font-semibold text-[#236260] mt-[-4px] font-poppin">
          {t("Add_Product")}
        </h1>
        <h3>
          <p className="text-lg text-gray-600">
            {selectLanguage === "ar"
              ? " بعد النقر فوق إضافة منتج جديد، يمكنك إضافة التفاصيل الخاصة بهم."
              : "After clicking on add new product you can add their details."}
          </p>
          <img
            src={selectLanguage === "ar" ? addProduct1Ar : addProduct1}
            className="w-full md:w-[60%] m-auto my-2"
          />
        </h3>
        <ul className="list-disc">
          <li className="text-lg text-gray-600 my-3">
            <b>{t("add_new_product_name")} : </b>
            {selectLanguage === "ar"
              ? "امنح منتجك اسما"
              : "Give your Product a Name"}
          </li>
          <li className="text-lg text-gray-600 my-3">
            <b>{t("add_new_product_price")} : </b>
            {selectLanguage === "ar"
              ? "حدد سعر المنتج (بالريال)"
              : "Give your product Price (Riyals)."}
          </li>
          <li className="text-lg text-gray-600 my-3">
            <b>SKU : </b>{" "}
            {selectLanguage === "ar"
              ? "إنها وحدة الاحتفاظ بالأسهم - إنها رمز فريد يحدد المنتج. (على سبيل المثال، لدينا الرمز الشريطي في المنتجات)"
              : "It is a Stock Keeping Unit- It is a unique code which defines the product. (Ex. We have barcode in products)."}
          </li>
          <li className="text-lg text-gray-600 my-3">
            <b>{t("add_new_product_stock")} : </b>
            {selectLanguage === "ar"
              ? "أدخل عدد المنتجات "
              : "Enter the Number of Products."}
            <li className="text-lg text-gray-600 my-3">
              <b>{t("Status")} : </b>
              {selectLanguage === "ar"
                ? 'يمكنك الاختيار من بين خيارين "مسودات" أو "منشور". (في حالة وجود مسودات، لن يتم عرضها في موقع الويب الخاص بك، فقط إذا كانت المنتجات منشورة، فستظهر على موقع الويب).'
                : "You can choose from two options ‘Drafts’ or ‘Published’. (in case of drafts it will not be shown in your website, only if the products are on published it will reflect on website)."}
            </li>
            <li className="text-lg text-gray-600 my-3">
              <b>{t("vd_pc_Category")} : </b>
              {selectLanguage === "ar"
                ? "أختر فئة المنتج من قائمة الفئات. يمكنك إضافة فئة جديدة."
                : "Choose your product category from your Category List. You can add New Category."}
              <img
                src={selectLanguage === "ar" ? addProduct2Ar : addProduct2}
                className="w-full md:w-[60%] m-auto my-2"
              />
            </li>
            <li className="text-lg text-gray-600 my-3">
              <b>{t("Description")} : </b>{" "}
              {selectLanguage === "ar"
                ? "قدم وصفا للمنتج."
                : "Give your product description"}
            </li>
            <li className="text-lg text-gray-600 my-3">
              <b>{t("vd_pt_Tags")} : </b>
              {selectLanguage === "ar"
                ? 'أختر علامة المنتج من قائمة العلامات. يمكنك النقر فوق إضافة علامة جديدة.يمكنك هنا إضافة اسم العلامة لأحد المنتجات ووصف العلامة وفقا للمنتج. بعد النقر فوق "حفظ" ستظهر النتيجة.'
                : "Choose your product Tag from your Tag List. You can Click on add New Tag.here you can add the Tag Name for a Product and Tag description according to the product. After that click on the Save and it will show the result."}
            </li>
            <li className="text-lg text-gray-600 my-3">
              <b>{t("add_new_product_T_img")} : </b>{" "}
              {selectLanguage === "ar"
                ? "أختر صورة المنتج وتحميلها هنا. يجب أن يكون حجم الصورة (312*364) بكسل."
                : "Choose your product image and upload here. Your Image size should be (312*364) pixel."}
            </li>
            <li className="text-lg text-gray-600 my-3">
              <b>{t("add_new_product_G_img")} : </b>
              {selectLanguage === "ar"
                ? "أختر صور المعرض وقم بتحميلها هنا. يجب أن يكون حجم الصورة (600*600) بكسل."
                : "Choose your Gallery Images and upload here. Your Image size should be (600*600) pixel."}
              <img
                src={selectLanguage === "ar" ? addProduct3Ar : addProduct3}
                className="w-full md:w-[60%] m-auto my-2"
              />
            </li>
            <li className="text-lg text-gray-600 my-3">
              <b>{t("add_new_product_Colors")} : </b>
              {selectLanguage === "ar"
                ? "أذكر الألوان المختلفة الموجودة في هذا المنتج."
                : "Mention what are the different colors this product has."}
            </li>
            <li className="text-lg text-gray-600 my-3">
              <b>{t("add_new_product_Sizes")} : </b>{" "}
              {selectLanguage === "ar"
                ? "أذكر الأحجام المختلفة للمنتجات."
                : "Mention different sizes of products."}
            </li>
          </li>
          <li className="text-lg text-gray-600 my-3">
            <b>{t("add_new_product_Information")} : </b>
            {selectLanguage === "ar"
              ? "أدخل معلومات الشحن الخاصة بك."
              : "Enter Your Shipping Information."}
          </li>
        </ul>
      </div>
    </>
  );
};

export default GuideAddProduct;
