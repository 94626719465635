import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { GrCompliance } from 'react-icons/gr';
import { useNavigate } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import deleteDataById from '../../../hooks/admin/deleteDataById';
import LoadingSpinner from '../../../components/utils/LoadingSpinner';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const GeneralQuery = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const [queries, setQueries] = useState([]);
    const user = JSON.parse(localStorage.getItem('admin'))
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        const fetchQueries = async () => {

            const baseUrl = process.env.REACT_APP_APIURL;

            const res = await fetch(`${baseUrl}/admin/getallgeneralquery`, {

                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
            });
            const response = await res.json();

            if (response.status_code === 200) {
                setQueries(response.data);
            }
        }
        fetchQueries();
    }, [])

    const selectItemToEdit = async (id) => {
        navigate('/admin/update_query',
            {
                state: id
            }
        )
    }

    const deleteItem = async (id) => {
       
        confirmAlert({
            title: `${t("delete_alert_title")}`,
            message: `${t("delete_alert_des")}`,
            buttons: [
                {
                    label: `${t("yes")}`,
                    onClick: async () => {
                        setLoading(true);

                        const response = await deleteDataById('admin/deletegeneralquery', id);

                        if (response.status_code === 200) {
                            const newDataList = queries.filter(item => item.id !== id);
                            setQueries(newDataList);
                        } else {
                            toast.error(response?.message || response?.err)
                        }

                        setLoading(false);
                    }
                },
                {
                    label: `${t("no")}`,
                    onClick: () => { }
                }
            ]
        });

    }

    const columns = [
        {
            name: `${t("Name")}`,
            selector: row => row.name,

        },
        {
            name: `${t("l_email")}`,
            selector: row => row.email,

        },
        {
            name: `${t("Cus_phone")}`,
            selector: row => row.phone,

        },
        {
            name: `${t("Query")}`,
            selector: row => row.query,

        },
        {
            name: `${t("all_product_dataTable6")}`,
            cell: (row) => (<div className="flex gap-2">
                {
                    !row.resolution ?
                        <>
                            <span className='text-primary font-semibold'>{t("Pending")}</span>
                        </>
                        :
                        <>
                            <span>
                                {row.resolved_on.slice(0, 10)}
                            </span>
                        </>
                }
            </div>),
        },
        {
            name: `${t("Options")}`,
            sortable: true,
            cell: (row) => (<div className="flex gap-2">
                {
                    !row.resolution ?
                        <>
                            <span onClick={() => selectItemToEdit(row.id)} className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300" title={t("Edit")}>
                                <AiOutlineEdit />
                            </span>
                            <span onClick={() => deleteItem(row.id)} className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300" title={t("Delete")}>
                                <AiOutlineDelete />
                            </span>
                        </>
                        :
                        <>
                            <span onClick={() => selectItemToEdit(row.id)} className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300" title={t("Edit")}>
                                <GrCompliance />
                            </span>
                            <span onClick={() => deleteItem(row.id)} className="p-2 flex items-center border border-error hover:bg-error rounded-full text-error hover:text-white cursor-pointer duration-300" title={t("Delete")}>
                                <AiOutlineDelete />
                            </span>
                        </>
                }
            </div>),
        },
    ]

    if (loading) {
        return <LoadingSpinner />
    }

    return (
        <div>
            <Helmet>
                <title>{t('Admin')} - {t("Admin_query")}</title>
            </Helmet>
            <h1 className="text-md font-medium">{t("Query_all")}</h1>
            <div className="my-5 bg-white border rounded">

                <DataTable
                    columns={columns}
                    data={queries}
                    pagination
                />
            </div>
        </div>
    );
};

export default GeneralQuery;