import React from "react";
import { useForm } from "react-hook-form";
import { AiOutlineDollarCircle, AiOutlinePlus } from "react-icons/ai";

const payOutRequests = [
  {
    id: 1,
    date: "30-01-2022",
    amount: 200.0,
    status: "Completed",
    message: "message here",
  },
  {
    id: 2,
    date: "30-01-2022",
    amount: 200.0,
    status: "Completed",
    message: "message here",
  },
];

const PayoutRequest = () => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  return (
    <>
      <div className="flex items-center justify-between">
        <h2 className="text-title text-sm md:text-base font-medium roboto">
          Payout Requests
        </h2>
      </div>

      <div className="py-2 mb-12">
        <div className="">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <div className="bg-secondary p-5  w-full md:w-[400px] rounded ml-auto">
              <div className="flex flex-col items-center justify-center gap-2">
                <span className="p-2 text-white bg-orange-500 rounded-full flex justify-center items-center">
                  <AiOutlineDollarCircle size={30} />
                </span>
                <h2 className="text-white font-medium text-xl md:text-2xl">
                  20,455 $
                </h2>
                <p className="text-gray-50 text-sm">Pending Balance</p>
              </div>
            </div>
            <div className="bg-secondary p-5 text-white w-full md:w-[400px] rounded mr-auto cursor-pointer hover:shadow-xl duration-300 transition-all">
              <label htmlFor="my-modal" className="modal-button">
                <div className="flex flex-col items-center cursor-pointer justify-center gap-2 h-full">
                  <span className="p-2 text-primary bg-white rounded-full flex justify-center items-center">
                    <AiOutlinePlus size={36} />
                  </span>

                  <h2 className="text-white font-medium text-lg md:text-xl">
                    Send Payout Request
                  </h2>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div className="border rounded my-8 bg-white">
          <div className="border-b p-4">
            <h2 className="text-md font-medium">Payout requests history</h2>
          </div>
          <div className="body p-4">
            <div className="data-table">
              <div className="overflow-x-auto rounded-none">
                <table className="table w-full rounded-none text-[13px]">
                  <tr className="rounded-none border-b">
                    <th>#</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Message</th>
                  </tr>

                  <tbody>
                    {payOutRequests.map((item) => (
                      <tr>
                        <th>1</th>
                        <td>
                          <a className="flex items-center" href="#">
                            <span className="text-xs truncate max-w-[100px]">
                              {item.date}
                            </span>{" "}
                          </a>
                        </td>
                        <td>{item.amount}$</td>
                        <td>
                          <span className="py-1 px-3 rounded bg-gray-300 text-black roboto font-medium text-[.75rem]">
                            {" "}
                            {item.status}
                          </span>
                        </td>
                        <td>{item.message}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* modal for payout requests */}
      <input type="checkbox" id="my-modal" className="modal-toggle" />
      <label htmlFor="my-modal" className="modal cursor-pointer">
        <label className="modal-box rounded relative" htmlFor="">
          <h3 className="text-lg font-bold border-b">
            Send a Withdraw Request
          </h3>
          <div className="py-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group mb-5">
                <div className="grid grid-cols-1 md:grid-cols-12">
                  <div className="col-span-3">
                    <label className="text-[13px]" htmlFor="amount">
                      Amount<span className="text-error">*</span>
                    </label>
                  </div>
                  <div className="col-span-9">
                    <input
                      type="number"
                      className="w-full h-[40px] input input-bordered rounded"
                      placeholder="Amount"
                      {...register("amount", { required: true })}
                    />

                    {errors.amount?.type === "required" && "Amount is required"}
                  </div>
                </div>
              </div>
              <div className="form-group mb-5">
                <div className="grid grid-cols-1 md:grid-cols-12">
                  <div className="col-span-3">
                    <label className="text-[13px]" htmlFor="message">
                      Message
                    </label>
                  </div>
                  <div className="col-span-9">
                    <textarea
                      className="w-full h-[40px] input input-bordered rounded"
                      placeholder="message"
                      {...register("message")}
                      rows="30"
                      cols="10"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div className="text-right">
                <input
                  type="submit"
                  value="Send"
                  className="btn btn-sm rounded-none btn-primary text-white"
                />
              </div>
            </form>
          </div>
        </label>
      </label>
    </>
  );
};

export default PayoutRequest;
