import React, { useEffect, useState } from "react";
import ShopCategories from "./StoreComponents/ShopCategories";
import ShopHeroSection from "./StoreComponents/ShopHeroSection";
import ShopNavbar from "./StoreComponents/ShopNavbar";
import StoreFooter from "./StoreComponents/StoreFooter";
import "./StoreStyle/StoreStyle.css";
import ScrollTopButton from "../../components/ScrollTopButton";
import { useContext } from "react";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import { storeInformation } from "../../contexts/StoreInformationContext";
import FeedBack from "./FeedBack";
import FeatherProduct from "./FeatherProduct";
import { Helmet } from "react-helmet-async";
import { domainProvider } from "../../contexts/DomainContext";
import axios from "axios";
import checkStore from "../../hooks/isStoreValid";
import { useNavigate } from "react-router-dom";

const StoreHome = () => {
  const [products, setProducts] = useState([]);
  const { loading, storeDetails } = useContext(storeInformation);
  const storeName = storeDetails?.data?.storeDetails?.store_name
    ? storeDetails?.data?.storeDetails?.store_name
    : window.location.href.split("/")[3];
  // custom domain
  const { customdomain, titleName } = useContext(domainProvider);
  const urlStoreName = window.location.href.split("/")[3];
  const navigate = useNavigate();

  // check for valid store
  useEffect(() => {
    async function store() {
      const response = await checkStore(urlStoreName);
      if (
        response.status_code === 400 &&
        response.message === "This store is not registered"
      ) {
        navigate("/");
      }
    }

    store();
  }, [urlStoreName]);

  useEffect(() => {
    const getAllProducts = async () => {
      var res;
      if (customdomain === "aitech.com.sa") {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts/${storeName}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/store/getallproducts`,
          {
            headers: {
              "Content-Type": "application/json",
              domain_name: customdomain,
            },
          }
        );
      }
      const response = await res.data;
      if (response.status_code === 200) {
        if (response?.data) setProducts(response.data);
        else if (response?.dummyData) setProducts(response.dummyData);
      }
    };
    getAllProducts();
  }, [storeName]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ScrollTopButton>
      <div>
        <Helmet>
          <title>Home - {titleName}</title>
          {/* <link rel="favicon" href={storeDetails?.data?.storeDetails?.store_favicon} /> */}
        </Helmet>
        <ShopNavbar />
        <ShopHeroSection
          storeName={storeDetails?.data?.storeDetails?.store_name}
          slider={storeDetails?.data?.slider}
        />
        <ShopCategories />
        {/* Featured products section  */}
        <FeatherProduct
          products={products}
          storeName={storeDetails?.data?.storeDetails?.store_name}
        />
        {/* feedback section */}
        {/* <FeedBack average={storeDetails?.data?.avg_rating[0]?.avg_rating} /> */}
        {/* flower slider */}
        {/* <TopProductsSlider
          type={"customer"}
          storeName={storeDetails?.data?.storeDetails?.store_name}
        /> */}
        <StoreFooter />
      </div>
    </ScrollTopButton>
  );
};

export default StoreHome;

// {isVendor &&
//   (customdomain === "aitech.com.sa" ||
//     orgDomain === "aitech.com.sa") && <ShopHeader />}
