import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/Header/Header";
import useAdmin from "../../../hooks/useAdmin";

const AdminLogin = () => {
  const [contactLogin, setContactLogin] = useState(false);

  const [user] = useAdmin();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
    }
    else {
      setpassword("password");
      seteye(true);
    }
  }

  const onSubmit = async (data) => {
    setLoading(true);
    const res = await fetch(`${process.env.REACT_APP_APIURL}/admin/login`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const result = await res.json();

    if (result.status_code === 200) {
      const {
        admin_id,
        admin_name,
        admin_email,
        admin_contact,
        profile_image,
        status,
      } = result.data;
      const { token } = result;
      const user = {
        admin_id,
        admin_name,
        admin_email,
        admin_contact,
        profile_image,
        status,
        token,
      };

      localStorage.setItem("admin", JSON.stringify(user));

      navigate("/admin/dashboard");
    } else {
      toast.error(result.message);
    }

    setLoading(false);
  };

  if (user && user.status) {
    navigate("/admin/dashboard");
  }
  return (
    <>
      <Helmet>
        <title>Admin Login - Aitech</title>
      </Helmet>
      <Header />
      <section className="px-2 md:px-5 lg:px-20 py-5">
        <div className="container max-w-lg p-5 mx-auto">
          <div className="login-form w-full mx-auto border shadow p-5 md:px-8">
            <form
              className="flex flex-col gap-y-2 py-5 justify-center w-full"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className="text-center text-xl md:text-2xl">Admin Login</h2>

              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">
                    {" "}
                    {contactLogin ? "Contact No" : "Email"}
                  </span>
                  <span
                    onClick={() => setContactLogin(!contactLogin)}
                    className="text-xs cursor-pointer text-primary"
                  >
                    Login with {contactLogin ? "Email" : "Contact number"} ?
                  </span>
                </label>
                {contactLogin ? (
                  <input
                    {...register("username", { required: true })}
                    type="number"
                    placeholder="Enter Contact No"
                    className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                    required
                  />
                ) : (
                  <input
                    {...register("username", { required: true })}
                    type="email"
                    placeholder="Enter email"
                    className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                    required
                  />
                )}
              </div>

              <div className="form-control w-full relative">
                <label className="label">
                  <span className="label-text">Password</span>
                </label>
                <input
                  {...register("password", { required: true })}
                  type={password}
                  placeholder="Enter Password"
                  className="input input-sm input-bordered py-4 focus:outline-none rounded-none w-full "
                />
                <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye"} cursor-pointer absolute right-2 top-12`}></i>
                <label className="label">
                  {errors.password?.type === "required" && (
                    <span className="label-text-alt text-red-500 font-Roboto">
                      Password is required
                    </span>
                  )}
                </label>
              </div>

              <div className="form-control w-full">
                <button
                  type="submit"
                  className={`btn py-2 cursor-pointer btn-primary text-white w-full  rounded-none ${loading && "loading"
                    }`}
                >
                  Login
                </button>
              </div>

              <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                  <input
                    id="remember"
                    type="checkbox"
                    className="checkbox checkbox-xs rounded-none"
                  />

                  <label htmlFor="remember" className="label-text">
                    Remember me
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AdminLogin;
