import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import getDataById from "../../../hooks/getDataById";
import updateData from "../../../hooks/updateData";
import axios from "../../../components/utils/Interceptors";

const EditStuff = () => {
  const { id } = useParams();
  const [staff, setStaff] = useState({});
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = JSON.parse(localStorage.getItem("user"));
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getSingleStaff = async () => {
      const response = await getDataById("vendor/getbyidstaff", id);

      if (response.status_code === 200 && response.data) {
        setStaff(response.data[0]);
        const { vendor_name, vendor_email, vendor_contact, role_id } =
          response.data[0];
        setValue("vendor_name", vendor_name);
        setValue("vendor_email", vendor_email);
        setValue("vendor_contact", vendor_contact);
        setValue("role_id", role_id);
        setLoading(false);
      }
    };
    const getAllRole = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getallrole`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        setRoles(response.data);
      }
    };

    getAllRole();

    getSingleStaff();
  }, [id]);

  const onSubmit = async (data) => {
    data["vendor_id"] = staff.vendor_id;
    const res = await updateData("vendor/updatestaff", data);
    if (res.status_code === 200) {
      toast.success("Data Updated Successfully");
    } else {
      toast.error("something went wrong");
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <>
      <h2 className="text-title text-lg md:text-2xl pb-2">Edit Stuff</h2>
      <div className="mb-20">
        <div className="max-w-xl mx-auto border bg-white">
          <div className="card-title border-b p-4">
            <h3 className="text-base font-medium">Stuff information</h3>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="py-5 px-4">
              <div>
                <div className="my-5">
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="name">
                          Name<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="Name"
                          {...register("vendor_name", { required: true })}
                        />
                        {errors.couponCode?.type === "required" &&
                          "Name is required"}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="email">
                          Email<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          type="email"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="Email"
                          {...register("vendor_email")}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="Phone">
                          Phone<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <input
                          type="number"
                          className="w-full h-[40px] input input-bordered rounded"
                          placeholder="Phone"
                          {...register("vendor_contact")}
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-12">
                      <div className="col-span-3">
                        <label className="text-[13px]" htmlFor="role">
                          Role<span className="text-error">*</span>
                        </label>
                      </div>
                      <div className="col-span-9">
                        <select
                          className="w-full h-[40px] input input-bordered rounded"
                          {...register("role_id")}
                          required
                        >
                          <option value="">Select Role</option>
                          {loading
                            ? "Loading"
                            : roles.map((role, i) => (
                                <option key={i} value={role.role_id}>
                                  {role.role_name}
                                </option>
                              ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center mx-auto pb-5">
              <input
                className="py-1 px-3 bg-primary text-white rounded cursor-pointer"
                type="submit"
                value="Update"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditStuff;
